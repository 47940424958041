
import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import {
    Box,
    Typography,
    Modal,
    Tabs,
    Tab,
    Checkbox,
    ListItemButton,
    Divider,
    Collapse,
    FormControlLabel,
    Radio,
    RadioGroup,
    Chip,
    MenuItem,
    Select,
    Popover,
    FormControl,
    OutlinedInput,
    List,
    ListItem,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Backdrop,
    Alert,
    modalClasses,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import store from '../../../store/configureStore';
import GenerateProfilePic from '../../../components/Common/GenerateProfilePictureIntitals';
import ErrorTab from '../../../components/Common/ErrorTab';
import { useTranslation } from 'react-i18next';
import { StyledMenu } from "../Video/SearchFilter";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Sort, multipleChoice, singleChoice } from './Constants';
import API from '../utils/API';
import { fetchQuestions, fetchQuiz } from '../store/actions/admin';
import { bindActionCreators } from 'redux';
import Spinner from '../../../components/Common/Spinner';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function Tag(props) {
    const { label, onDelete, ...other } = props;
    return (
        <div {...other}>
            <span>{label}</span>
            <CloseIcon onClick={onDelete} />
        </div>
    );
}

Tag.propTypes = {
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};
const StyledTag = (props) => <Tag {...props} className="StyledTag" />;

function QuestionTableLayout(props) {
    const { t } = useTranslation()
    const [selectedCollection, setSelectedCollection] = React.useState([]);
    const [openIndex, setOpenIndex] = React.useState(null);
    const [value, setValue] = React.useState(0);
    const [levelCategory, setLevelCategory] = React.useState({
        easy: [],
        medium: [],
        hard: []
    });
    const Quiz_Not_Found = t("Quiz_Not_Found")
    const tabClassName = `admin-analytics-tab assesment-tabs ${document.body.dir === "ltr" ? "assess-right-padd" : "assess-left-padd"}`;
    const [dropdownAnchorEl, setDropdownAnchorEl] = React.useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const [openFilter, setFilterOpen] = React.useState(false);
    const [isAssignQuizOpen, setAssignQuizOpen] = React.useState(false);
    const isDropdownOpen = Boolean(dropdownAnchorEl);
    const isFilterOpen = Boolean(filterAnchorEl);
    const filterPopoverId = isFilterOpen ? 'filter-popover' : undefined;
    const [valueSlect, setSelectValue] = React.useState();
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [alert, setAlert] = React.useState({
        active: false,
        severity: '',
        message: ''
    });



    //Questions
    const [selectedQuestions, setSelectedQuestions] = React.useState([]);
    const [selectedQuestionsIds, setSelectedQuestionsIds] = React.useState([]);
    const [deleteQuestionDialog, setDeleteQuestionDialog] = React.useState(false);
    const [difficultyFilteredQuiz, setDifficultyFilteredQuiz] = React.useState([]);
    const [isCreateQuizOpen, setCreateQuizOpen] = React.useState(false);
    const [selectedQuestionToAssign, setSelectedQuestionToAssign] = React.useState([]);
    const [selectedQuizListToAssign, setSelectedQuizListToAssign] = React.useState([]);
    const [isEditQuestionOpen, setEditQuestionOpen] = React.useState(false);

    const [questionId, setQuestionId] = React.useState('');

    const [questionTitle, setQuestionTitle] = React.useState('');
    const [questionType, setQuestionType] = React.useState('');
    const [questionLevel, setQuestionLevel] = React.useState('');
    const [questionTags, setQuestionTags] = React.useState([]);
    const [questionOptions, setQuestionOptions] = React.useState([]);
    const [options, setOptions] = React.useState([])
    const [questionTypeFilter, setQuestionTypeFilter] = React.useState([])
    const [questionLevelFilter, setQuestionLevelFilter] = React.useState([])
    const [searchAssignQuestion, setSearchAssignQuestion] = React.useState('')
    const [questionsLayoutModel, setQuestionsLayoutModel] = React.useState({});
    const [sortFieldType, setSortFieldType] = React.useState('');
    const [sortFieldDate, setSortFieldDate] = React.useState('');
    const [localLoader, setLocalLoader] = React.useState(false);


    //Quiz
    const [searchInput, setSearchInput] = React.useState('');
    const [filteredQuizzes, setFilteredQuizzes] = React.useState(difficultyFilteredQuiz);
    const [quizTitle, setQuizTitle] = React.useState('')
    const [retake, setRetake] = React.useState(0)
    const [isRetakeAllowed, setIsRetakeAllowed] = React.useState(false)
    const [totalQuestions, setTotalQuestions] = React.useState(0)
    const [totalScores, setTotalScores] = React.useState(0)
    const [passingPercentage, setPassingPercentage] = React.useState(0)
    const [questionNumber, setQuestionNumber] = React.useState({});
    const [levelPercentage, setLevelPercentage] = React.useState({});
    const [questionPoint, setQuestionPoint] = React.useState({});

    //error
    const [quizTitleError, setQuizTitleError] = React.useState(false);
    const [quizTotalQuestionError, setQuizTotalQuestionError] = React.useState(false);
    const [quizLevelConfigError, setQuizLevelConfigError] = React.useState(false);
    const [quizPointError, setQuizPointError] = React.useState(false);
    const [quizLevelEmptyError, setQuizLevelEmptyError] = React.useState(false);
    const [levelPercentError, setLevelPercentError] = React.useState(false);
    const [checkConfigError, setCheckConfigError] = React.useState(false);
    const [questionTitleError, setQuestionTitleError] = React.useState(false);
    const [questionTypeError, setQuestionTypeError] = React.useState(false);
    const [questionOptionsForTypeError, setQuestionOptionsForTypeError] = React.useState(false)
    const [questionlevelError, setQuestionLevelError] = React.useState(false);
    const [questionTagsError, setQuestionTagsError] = React.useState(false);
    const [questionOptionsError, setQuestionOptionsError] = React.useState(false);
    const [singleChoiceError, setSingleChoiceError] = React.useState(false);
    const [multipleChoiceError, setMultipleChoiceError] = React.useState(false);
    const [questionOptionsMaxError, setQuestionOptionsMaxError] = React.useState(false);
    const [questionOptionValueError, setQuestionOptionValueError] = React.useState(false);
    const [retakes, setRetakes] = React.useState(0);
    const [retakeError, setRetakeError] = React.useState('');
    const [questionNumberErrors, setQuestionNumberErrors] = React.useState({});
    const [percentageErrors, setPercentageErrors] = React.useState({});
    const [questionPointErrors, setQuestionPointErrors] = React.useState({});

    //Modal Loader
    const [modelLoader, setModelLoader] = React.useState(false);
    const [questionListLoader, setQuestionListLoader] = React.useState(false)



    const handleMuiltipleChange = (event) => {
        setSelectedValues(event.target.value);
    };


    const location = useLocation();
    React.useEffect(() => {
        props.data.length ?
            setSelectedCollection(
                props.data.map(d => {
                    return {
                        select: false,
                        Id: d.QuestionId,
                        Title: d.Question,
                        Type: d.Type,
                        Level: d.Difficulty,
                        LevelId: d.QuestionLevelId,
                        // AnswerPoint: isNaN(d.Points) || d.Points == null ? 0 : d.Points,
                        CreatedOn: d.CreateOn,
                        Choice: d.Choice
                    }
                })
            )
            :
            setSelectedCollection([])
    }, [props.data]);

    React.useEffect(() => {
        const newCategory = {
            easy: [],
            medium: [],
            hard: []
        };
        selectedCollection.forEach((i) => {
            if (i.Level === "Easy") {
                newCategory.easy.push(i);
            } else if (i.Level === "Medium") {
                newCategory.medium.push(i);
            } else if (i.Level === "Complex") {
                newCategory.hard.push(i);
            }
        });
        setLevelCategory(newCategory);
    }, [selectedCollection]);
    const handleTabClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickOpenDropDown = (event) => {
        setDropdownAnchorEl(event.currentTarget);
        setFilterAnchorEl(null);
        setFilterOpen(false);
    };
    const handleClickOpenFilter = (event) => {
        setFilterAnchorEl(event.currentTarget);
        setDropdownAnchorEl(null);
        setFilterOpen(true);
    };
    const handleClose = () => {
        setDropdownAnchorEl(null);
        setAnchorEl(null);
        setFilterAnchorEl(null);
        setFilterOpen(false);
        setCreateQuizOpen(false);
        setDeleteQuestionDialog(false)
        setSelectedQuestionsIds([])
        setSelectedQuestions([])

        setAssignQuizOpen(false)
        setCreateQuizOpen(false)

        setLevelPercentError(false)
        setCheckConfigError(false)
        setQuizPointError(false)
        setQuizTotalQuestionError(false)
        setQuizTitleError(false)
        setQuizLevelConfigError(false)
        setQuizLevelEmptyError(false)

        setQuizTitle('')
        setQuestionPoint({})
        setQuestionNumber({})
        setLevelPercentage({})
        setTotalQuestions(0)
        setPassingPercentage(0)
        setTotalScores(0)
        setRetake(0)

        setAssignQuizOpen(false)
        setSelectedQuestionToAssign([])
        setSelectedQuizListToAssign([])
        setDifficultyFilteredQuiz([])

        setEditQuestionOpen(false)
        setModelLoader(false)
        setRetakes('')
        setSearchInput('');
        setSelectedValues([]);
    };

    const handleAssignQuestionToQuizzesModal = () => {
        setAssignQuizOpen(true);
        let selectedQuestion = [];
        if (selectedQuestionsIds.length > 0) {
        selectedQuestion = selectedQuestions.filter(question => selectedQuestionsIds.includes(question.Id));
        }
        setSelectedQuestionToAssign(selectedQuestion.length > 0 ? selectedQuestion : []);
        setModelLoader(true)
        API.fetchQuizWithFilter({
            difficultyId: parseInt(selectedQuestions.length > 0 ? selectedQuestions[0].LevelId : 0),
            title: "",
        }).then(res => res.status === 200 && setDifficultyFilteredQuiz(res.data)).then(() => setModelLoader(false))
    };

    const handleCreateQuizFromQuestionModal = () => {
        setCreateQuizOpen(true);
    };

    const QuizPopupStyle = {
        position: "absolute",
        top: "20vh",
        right: "0%",
        width: "38.95vw",
        height: "80vh",
        bgcolor: "background.paper",
        border: "0",
        boxShadow: 24,
        overflowY: "auto",
        padding: "0 0 6vw 0"
    };
    const handleCollapseClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleSelectQuestions = (event, questions) => {
        var { checked } = event.target
        if (checked) {
            setSelectedQuestions(prev => [...prev, questions])
            setSelectedQuestionsIds(prev => [...prev, questions.Id])
        }
        else {
            var questionIdsIndex = selectedQuestionsIds.indexOf(questions.Id)
            var questionIndex = selectedQuestions.indexOf(questions)
            selectedQuestionsIds.splice(questionIdsIndex, 1)
            selectedQuestions.splice(questionIndex, 1)
        }
    }

    const handleDeleteDialogs = () => {
        setDeleteQuestionDialog(true)
    }

    const handleDeleteQuestions = () => {
        setLocalLoader(true)
        Promise.all(selectedQuestionsIds.map(id => {
            return API.deleteQuestions(id)
        })).then(() => {
            setLocalLoader(false)
            handleClose()
            props.fetchQuestionAction({
                Pagination: {
                    PageNumber: 1,
                    PageSize: 20
                }
            })
            setAlert({
                active: true,
                severity: 'success',
                message: 'Question removed successfully!'
            })
        })
    }

    const handleNumberOfQuestion = (event) => {
        const { name, value } = event.target
        const intValue = parseInt(value.replace(/^0+/, "")) || 0;
        if (intValue < 0) {
            setQuestionNumberErrors((prevErrors) => ({
              ...prevErrors,
              [name]: true
            }));
          } else {
            setQuestionNumberErrors((prevErrors) => ({
              ...prevErrors,
              [name]: false
            }));
            setQuestionNumber((prevInfo) => {
              if (name === "0") {
                  return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
              }
              if (name === "1") {
                  return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
              }
              if (name === "2") {
                  return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
              }
          })
          }

        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setLevelPercentError(false)
        setQuizTotalQuestionError(false)
        setCheckConfigError(false)
        
    }

    const handlePercentage = (event) => {
        const { name, value } = event.target
        const intValue = parseInt(value.replace(/^0+/, "")) || 0;
        if (intValue < 0) {
            setPercentageErrors((prevErrors) => ({
              ...prevErrors,
              [name]: true
            }));
          } else {
            setPercentageErrors((prevErrors) => ({
              ...prevErrors,
              [name]: false
            }));
            setLevelPercentage((prevInfo) => {
              if (name === "0") {
                  return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
              }
              if (name === "1") {
                  return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
              }
              if (name === "2") {
                  return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
              }
          })
          }

        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setQuizTotalQuestionError(false)
        setLevelPercentError(false)
        setLevelPercentError(false)
        setCheckConfigError(false)
    }

    const handleQuestionPoints = (event) => {
        const { name, value } = event.target
        const intValue = parseInt(value.replace(/^0+/, "")) || 0;
        if (intValue < 0) {
          setQuestionPointErrors((prevErrors) => ({
            ...prevErrors,
            [name]: true
          }));
        } else {
          setQuestionPointErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false
          }));
          setQuestionPoint((prevInfo) => {
            if (name === "0") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
            if (name === "1") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
            if (name === "2") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
        })
        }
        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setLevelPercentError(false)
        setQuizTotalQuestionError(false)
        setCheckConfigError(false)
    
    }

    React.useEffect(() => {
        if (Object.keys(questionNumber).length > 0) {
            Object.keys(questionPoint).forEach(key => questionPoint[key] === undefined && delete questionPoint[key])
            const questmarkSync = Object.keys(questionPoint).reduce((acc, key) => {
                acc[key] = questionPoint[key] * questionNumber[key];
                return acc;
            }, {});
            var totalPoints = Object.values(questmarkSync).reduce((a, b) => a + b, 0)
            totalPoints = isNaN(totalPoints) ? 0 : totalPoints
            setTotalScores(totalPoints)
        }
    }, [questionPoint, questionNumber]);

    React.useEffect(() => {
        if (Object.values(levelPercentage).length > 0) {
            var totalPercentage = Object.values(levelPercentage)
            var result = Array.from(totalPercentage, v => v === undefined ? 0 : v);
            var calPer = result.filter(a => parseInt(a) !== 0).reduce((a, b) => { return parseInt(a) + parseInt(b) }, 0) / result.filter(a => parseInt(a) !== 0).length
            setPassingPercentage(parseInt(calPer))
        }
    }, [levelPercentage])

    React.useEffect(() => {
        Object.keys(questionNumber).forEach(key => questionNumber[key] === undefined && delete questionNumber[key])
        var totalQuestions = Object.values(questionNumber).reduce((a, b) => a + b, 0)
        totalQuestions = isNaN(totalQuestions) ? 0 : totalQuestions
        setTotalQuestions(totalQuestions)
    }, [questionNumber]);

    const handleCreateQuiz = () => {
        var levelObj = Object.values(questionNumber)
        var levels = Object.keys(questionNumber)

        var percentages = levels.map(a => {
            return {
                points: questionPoint[a],
                percent: levelPercentage[a]
            }
        })

        var iserror = percentages.filter(a => isNaN(a.percent) || isNaN(a.points) || parseInt(a.points) === 0).length
        var isPercentageCorrect = percentages.filter(a => parseInt(a.percent) <= 0 || parseInt(a.percent) > 100).length

        var checkQuestionConfig = selectedQuestions.filter(a => levels.includes(a.LevelId.toString())).length

        if (quizTitle.length > 0 && totalQuestions >= 1 && levels.length > 0 && levelObj.filter(a => isNaN(a)).length < levelObj.length && iserror === 0 && isPercentageCorrect === 0 && checkQuestionConfig > 0) {

            setModelLoader(true)

            var quizPayload = {
                Title: quizTitle.trim(),
                NumberOfRetake: parseInt(retake),
                PassScoreInPertcentage: parseInt(passingPercentage),
                TotalQuestions: parseInt(totalQuestions),
                TotalScore: parseInt(totalScores),
                IsOptional: true,
                QuizLevels: Object.keys(questionNumber).map(item => {
                    return {
                        LevelId: parseInt(item),
                        NumberOfQuestions: parseInt(questionNumber[item]),
                        PassScoreInPertcentage: parseInt(levelPercentage[item]),
                        PointsPerQuestion: parseInt(questionPoint[item])
                    }
                })
            }
            API.createQuiz(quizPayload)
                .then(res => {
                    if (res.status === 200) {
                        Promise.all(selectedQuestions.map(question => {
                            API.assignQuestionsToQuiz({
                                questionId: [question.Id],
                                score: quizPayload.QuizLevels.filter(a => a.LevelId === question.LevelId).length > 0 ? quizPayload.QuizLevels.find(a => a.LevelId === question.LevelId).PointsPerQuestion : 0,
                                quizId: res.data.QuizId
                            })
                        })).then(() =>
                            props.fetchQuizAction({ PageNumber: 1, PageSize: 20 })).then(() => setAlert({
                                active: true,
                                severity: 'success',
                                message: 'Quiz created successfully!'
                            })).then(() => handleClose())
                    }
                })
        }
        else {
            if (quizTitle.length <= 0) {
                setQuizTitleError(true)
            }
            if (passingPercentage <= 0) {
                setQuizPointError(true)
            }
            if (totalQuestions < 1) {
                setQuizTotalQuestionError(true)
            }
            if (levelObj.length <= 0) {
                setQuizLevelEmptyError(true)
            }
            if (levelObj.filter(a => isNaN(a)).length === levelObj.length) {
                setQuizLevelEmptyError(true)
            }
            if (isPercentageCorrect !== 0) {
                setLevelPercentError(true)
            }
            if (iserror !== 0) {
                setQuizLevelConfigError(true)
            }
            if (checkQuestionConfig === 0) {
                setCheckConfigError(true)
            }
        }
    }

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            severity: '',
            message: ''
        })
    }

    React.useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);

    const handleEditQuestion = () => {
        var selectedQuestion = selectedQuestions.length === 1 && selectedQuestions[0]
        setQuestionId(selectedQuestion.Id)
        setQuestionTitle(selectedQuestion.Title)
        setQuestionType(selectedQuestion.Type)
        setQuestionLevel(selectedQuestion.LevelId)
        setQuestionOptions(selectedQuestion.Choice)
        setOptions(selectedQuestion.Choice.map(choice => {
            return {
                id: choice.ChoiceId,
                option: choice.Choice,
                isCorrect: choice.IsCorrectChoice
            }
        }))


        // setQuestionsLayoutModel({
        //     Id: selectedQuestionToAssign.Id,
        //     Title: selectedQuestionToAssign.Title,
        //     Type: selectedQuestionToAssign.Type,
        //     QuestionLevelId: selectedQuestionToAssign.LevelId,
        //     QuestionLevel: selectedQuestionToAssign.Level,
        //     Choice: selectedQuestionToAssign.Choice,
        // })
        setEditQuestionOpen(true)
    }

    const handleSubmitEditQuestion = () => {
        if (questionTitle.length > 0 &&
            questionTitle.trim().length <= 250 &&
            questionType.length > 0 &&
            questionLevel.toString().length > 0 &&
            props.questionLevels.filter(a => a.Id === parseInt(questionLevel)).length > 0 &&
            questionOptions.length > 1 &&
            [singleChoice, multipleChoice].includes(questionType.toString().trim()) &&
            // questionTags.length > 0 &&
            options.filter(a => a.option === '').length === 0 &&
            options.length > 1 &&
            questionOptions.filter(a => a.Choice === '').length <= 0 &&
            (questionType.toString() === singleChoice ? questionOptions.filter(a => a.IsCorrectChoice).length === 1 :
                questionOptions.filter(a => a.IsCorrectChoice).length >= 2)
        ) {
            var model = {
                QuestionId: questionId,
                Question: questionTitle,
                QuestionType: questionType,
                QuestionLevelId: questionLevel,
                Tags: questionTags.join(', '),
                Choices: questionOptions
            }
            setModelLoader(true)
            API.updateQuestions(model).then(res => {
                if (res.status === 200) {
                    props.fetchQuizAction({ PageNumber: 1, PageSize: 20 })
                    props.fetchQuestionAction({
                        Pagination: {
                            PageNumber: 1,
                            PageSize: 20
                        }
                    })
                }
            }).then(() => {
                handleClose()
                setModelLoader(false)
            })
        }
        else {
            if (questionOptions.filter(a => a.Choice === '').length > 0) {
                setQuestionOptionValueError(true)
            }
            if (questionTitle.length <= 0) {
                setQuestionTitleError(true)
            }
            if (questionType.length <= 0 || ![singleChoice, multipleChoice].includes(questionType.toString().trim())) {
                setQuestionTypeError(true)
            }
            if (questionLevel.length <= 0 || props.questionLevels.filter(a => a.Id === parseInt(questionLevel)).length <= 0) {
                setQuestionLevelError(true)
            }
            // if (questionTags.length <= 0) {
            //     setQuestionTagsError(true)
            // }
            if (options.filter(a => a.option === '').length > 0) {
                setQuestionOptionValueError(true)
            }
            if (questionType.toString() === singleChoice && questionOptions.filter(a => a.IsCorrectChoice).length !== 1) {
                setSingleChoiceError(true)
            }
            if (questionType.toString() === multipleChoice && questionOptions.filter(a => a.IsCorrectChoice).length < 2) {
                setMultipleChoiceError(true)
            }
        }
    }

    const handleSelectQuizzes = (event, quiz) => {
        var { checked } = event.target
        if (checked) {
            setSelectedQuizListToAssign(prev => [...prev, quiz])
        }
        else {
            setSelectedQuizListToAssign((prev) => prev.filter((q) => q !== quiz));
        }
    }

    const handleAssignQuestionToQuizzes = () => {
        Promise.all(selectedQuizListToAssign.map((quiz, idx) => {
            return API.assignQuestionsToQuiz({
                // questionId: [selectedQuestionToAssign.Id],
                questionId: selectedQuestionToAssign.map(d => d.Id),
                score: quiz.FilteredLevelScore,
                quizId: quiz.Id
            })
        })).then(() => {
            setModelLoader(false)
            handleClose()
            setSearchInput('');
            setSelectedValues([]);
            setAlert({ active: true, severity: 'success', message: 'Questions create assigned to quiz successfully' })
        })
    };

    const handleOptionClick = () => {
        if (questionType.toString().length <= 0 || ![singleChoice, multipleChoice].includes(questionType.toString().trim())) {
            setQuestionTypeError(true)
            setQuestionOptionsForTypeError(true)
        }
        else {
            if (options.length < 5) {
                setOptions(prev => [...prev, { id: null, option: "", isCorrect: false }])
                setQuestionOptionsError(false)
                setQuestionOptionsMaxError(false)
                setSingleChoiceError(false)
                setMultipleChoiceError(false)
                setQuestionOptionValueError(false)
            }
            else {
                setQuestionOptionsMaxError(true)
            }
        }
    };

    const handleOptions = (index, e) => {
        let newOptions = [...options];
        newOptions[index][e.target.name] = e.target.value;
        if (e.target.name == 'isCorrect') {
            newOptions.forEach((a, expindex) => {
                if (index === expindex) {
                    a.isCorrect = a.isCorrect === "true" ? false : true;
                }
            })
        }
        setQuestionOptions(
            newOptions.map((a, idx) => {
                return {
                    ChoiceId: a.id,
                    Choice: a.option,
                    IsCorrectChoice: a.isCorrect,
                    OrderSequence: idx
                }
            })
        );
        setQuestionOptionValueError(false)
        setMultipleChoiceError(false)
    }

    const removeOptions = (i) => {
        let newOptions = [...options];
        newOptions.splice(i, 1);
        setOptions(newOptions)
        setQuestionOptions(
            newOptions.map((a, idx) => {
                return {
                    ChoiceId: a.id,
                    Choice: a.option,
                    IsCorrectChoice: a.isCorrect,
                    OrderSequence: idx
                }
            })
        );
        setQuestionOptionsMaxError(false)
    }

    const handleRadioOptions = (index, e) => {
        let newOptions = [...options];
        newOptions[index][e.target.name] = e.target.value;
        if (e.target.name == 'isCorrect') {
            newOptions.forEach((a, expindex) => {
                if (index === expindex) {
                    a.isCorrect = a.isCorrect === "true" ? false : true;
                }
                else {
                    a.isCorrect = false;
                }
            })
        }
        setQuestionOptions(
            newOptions.map((a, idx) => {
                return {
                    Choice: a.option,
                    IsCorrectChoice: a.isCorrect,
                    OrderSequence: idx
                }
            })
        );
        setQuestionOptionValueError(false)
        setSingleChoiceError(false)
    }

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...options];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setOptions(updatedList)
    };

    React.useEffect(() => {
        if (searchInput) {
            setFilteredQuizzes(difficultyFilteredQuiz.filter(quiz => 
                quiz.Title.toLowerCase().includes(searchInput.toLowerCase())
            ));
        } else {
            setFilteredQuizzes(difficultyFilteredQuiz);
        }
    }, [searchInput, difficultyFilteredQuiz]);

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };

    React.useEffect(() => {
        const retakeValue = 5;
        const value = Number(retakes);
        if (retakes === '') {
          setRetakeError('');
        } else if (value < 0) {
          setRetakeError('Retakes cannot be negative');
        } else if (value > retakeValue) {
          setRetakeError('Retakes cannot be more than 5');
        } else {
          setRetakeError('');
        }
      }, [retakes]);

    const handleFilter = () => {
        props.fetchQuestionAction({
            Pagination: {
                PageNumber: 1,
                PageSize: 20,
            },
            Filters: {
                Types: sortFieldType === "All" || sortFieldType.length === 0 ? [] : [sortFieldType],
                Sort: sortFieldDate
            },
        })
        handleClose()
    };

    React.useEffect(() => {
        API.getAllQuestions({
            Pagination: {
                PageNumber: 1,
                PageSize: 20
        }}).then(res => {
            props.data(res.data.EasyQuestions.concat(res.data.MediumQuestions, res.data.ComplexQuestions))
        })
    },[])

    const open = Boolean(anchorEl);
    return (
        <>
            <div className='questions-tabs'>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={alert.active}
                    onClick={handleBackDropClose}
                >
                    <Alert severity={alert.severity} style={{ position: "absolute", top: 20 }}>
                        {`${alert.message}`}
                    </Alert>
                </Backdrop>
                {/* <div className='d-flex align-items-center justify-content-between mrb-5 mt-4'>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='admin-analytics-tabs assesment-quiz-tab'>
                        <Tab className={tabClassName} label={`Easy (${levelCategory.easy.length})`} {...a11yProps(0)} />
                        <Tab className={tabClassName} label={`Medium (${levelCategory.medium.length})`} {...a11yProps(1)} />
                        <Tab className={tabClassName} label={`Hard (${levelCategory.hard.length})`} {...a11yProps(2)} />
                    </Tabs>
                    <div className='assessment-action-btns'>
                        <button type='button' className='create-new-btn'><p>Create a Quiz</p></button>
                        <button type='button' className='create-new-btn'><p>Assign to a Quiz</p></button>
                        <div className='horizontal-divider'></div>
                        <div className='d-flex align-items-center justify-content-between position-relative' onClick={handlePopoverOpen}>
                            <button type='button' className='create-new-btn filters-pop'><p>Filters</p></button>
                            <i class="arrow down filters-arrow-icon"></i>
                        </div>
                        <div>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <h1 sx={{ p: 2 }}>The content of the Popover.</h1>
                            </Popover>
                        </div>
                    </div>
                </div> */}
                <div className='d-flex align-items-center justify-content-between mt-4'>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='admin-analytics-tabs assesment-quiz-tab'>
                        {
                            props.questionLevels && props.questionLevels.map((level, idx) => {
                                let count = 0;

                                console.log('props.data', props.data);

                                if (level.Name === 'Easy') {
                                    count = props.data && props.questionsCount.easycount !== undefined ? props.questionsCount.easycount : 0;
                                } else if (level.Name === 'Medium') {
                                    count = props.data && props.questionsCount.mediumcount !== undefined ? props.questionsCount.mediumcount : 0;
                                } else if (level.Name === 'Complex') {
                                    count = props.data && props.questionsCount.complexcount !== undefined ? props.questionsCount.complexcount : 0;
                                }

                                return (
                                    <Tab
                                        key={idx}
                                        className={tabClassName}
                                        label={`${level.Name} (${count})`}
                                        {...a11yProps(level.Id)}
                                    />
                                );
                            })
                        }
                    </Tabs>
                    <div className='assessment-action-btns'>
                        <Tooltip title={selectedQuestionsIds.length > 0 ? "" : "Select atleast one question"}>
                            <button type='button' className='create-new-btn' onClick={handleClickOpenDropDown} disabled={selectedQuestionsIds.length === 0}><p>Add to Quiz</p></button>
                        </Tooltip>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={dropdownAnchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            arrow
                        >
                            <Tooltip title={selectedQuestionsIds.length > 0 ? "" : "Select atleast on question"}>
                                <MenuItem className="group-dpdn-size" disableRipple onClick={handleCreateQuizFromQuestionModal} disabled={selectedQuestionsIds.length === 0} >
                                    Create New
                                </MenuItem>
                            </Tooltip>

                            <Divider className="mui-devider" />
                            <Tooltip title={selectedQuestionsIds.length !== 1 ? "Select one question" : ""}>
                                <MenuItem className="group-dpdn-size" disableRipple onClick={handleAssignQuestionToQuizzesModal} disabled={!selectedQuestionsIds.length} >
                                    Add Existing
                                </MenuItem>
                            </Tooltip>
                        </StyledMenu>

                        {/* Assign Question to quizzes */}
                        <Modal
                            open={isAssignQuizOpen}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div className='popup-breadcrumd'>
                                <Box sx={QuizPopupStyle}>
                                    <div class="quiz-creator-con"><h3>Assign Question to Quiz</h3><div><img src="../assets/close-black.png" alt="lms-logo" onClick={handleClose} class="close-icon" /></div></div>
                                    <div className='mui-hr-line'></div>
                                    <div class="quiz-creator-con pt-0">
                                        <div className="questionBank-title assign-question-h2">
                                            <h4>Question: </h4>
                                            {selectedQuestionToAssign && selectedQuestionToAssign.length > 0 && (
                                                selectedQuestionToAssign.map((question, index) => (
                                                <p key={index}>{index+1}. {question.Title}</p>
                                                ))
                                            )}
                                        </div>
                                    </div>

                                    {/* For Future Scope */}
                                     <div className=' quiz-creator-con gap-2 '>
                                        <input className='w-50' type="text" placeholder="Search Quiz...." value={searchInput} onChange={handleSearchChange} />
                                        {/* <FormControl id="points" className='edit-question-select-op w-50'>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={selectedValues}
                                                onChange={handleMuiltipleChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return "Select Tags...";
                                                    }
                                                    return selected.join(', ');
                                                }}
                                                IconComponent={ExpandMoreIcon}
                                            >
                                                <MenuItem disabled value="">Select Tags...</MenuItem>
                                                <MenuItem value="singleChoice">singleChoice</MenuItem>
                                                <MenuItem value="multipleChoice">multipleChoice</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                    </div> 
                                    <div>
                                        {
                                            !modelLoader ?
                                                <List sx={{ bgcolor: 'background.paper', height: '40vh', overflow: 'auto' }} className="assign-to-quiz-list assign-question-list">

                                                    {
                                                        filteredQuizzes.map((quiz, idx) => {
                                                            return (
                                                                <ListItem
                                                                    key={idx}
                                                                    disablePadding
                                                                    className='assign-question-to-quiz'
                                                                >
                                                                    <ListItemButton role={undefined} dense>
                                                                        <ListItem disablePadding>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                value="checkedA"
                                                                                name=""
                                                                                inputProps={{
                                                                                    'aria-label': 'primary checkbox',
                                                                                }}
                                                                                disableRipple
                                                                                disablePadding
                                                                                // checked={selectedQuestionsIds.includes(question.Id)}
                                                                                onClick={(event) => handleSelectQuizzes(event, quiz)}
                                                                            />
                                                                            <div>
                                                                                <h2>{quiz.Title}</h2>
                                                                                <div className=" align-items-center justify-content-start">
                                                                                    <p>Level : <strong>{selectedQuestionToAssign.Level}</strong></p>
                                                                                </div>
                                                                            </div>
                                                                        </ListItem>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                                :
                                                <Spinner />                                                
                                        }
                                    </div>
                                    <div className='quiz-creator-con justify-content-end fixed-btns model-padding'>
                                        <div className='filter-btn-group'>
                                            <button onClick={handleClose} class="primary-button ter-btn">Cancel</button>
                                            <button class="filter-clear-search" onClick={handleAssignQuestionToQuizzes} disabled = {!selectedQuizListToAssign.length > 0}>Submit</button>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        </Modal>

                        {/* Create Quiz With Questions */}
                        <Modal
                            open={isCreateQuizOpen}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div className='popup-breadcrumd'>
                                <Box sx={QuizPopupStyle}>
                                    {
                                        !modelLoader ?
                                            <>
                                                <div className=' quiz-creator-con'>
                                                    <h2>Create Quiz</h2>
                                                    <div>
                                                        <img src="../assets/close-black.png" alt="lms-logo" class="close-icon" onClick={handleClose}/>
                                                    </div>
                                                </div>
                                                <div className='mui-hr-line'></div>
                                                <div className="quiz-text-input">
                                                    <input type="text" placeholder="Type your quiz title here" value={quizTitle}
                                                        className={quizTitleError && 'error'}
                                                        onChange={(e) => {
                                                            setQuizTitle(e.target.value)
                                                            setQuizTitleError(false)
                                                        }}
                                                    />
                                                    {quizTitleError && <label className="question-title error-label"><p>Please enter quiz title</p></label>}
                                                </div>

                                                <div className="quiz-selection-con d-flex align-items-center justify-content-between">
                                                    <div className="quiz-select">
                                                        <label for="retake">Retake</label>
                                                        <FormControl id="points">
                                                            <TextField
                                                                id="outlined-number"
                                                                label=""
                                                                type="number"
                                                                className="mui_select_con"
                                                                onChange={(e) => setRetakes(e.target.value)}
                                                                value={retakes}
                                                                />
                                                                {retakeError && <label className="question-title error-label"><p>{retakeError}</p></label>}
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                {
                                                    props.questionLevels && props.questionLevels.map((item, idx) => {
                                                        return (
                                                            <div className="quiz-selection-con d-flex align-items-center justify-content-between" key={idx}>
                                                                <div className="quiz-select select-four-layout">
                                                                    <label for="difficulty-level" className="select-four-label">Difficulty level</label>
                                                                    <h3 id="difficulty-level">{item.Name}</h3>
                                                                </div>
                                                                <div className="quiz-select select-four-layout">
                                                                    <label for="points">No. of Questions</label>
                                                                    <FormControl id="points">
                                                                        <TextField
                                                                            id="outlined-number"
                                                                            label=""
                                                                            type="number"
                                                                            className="mui_select_con"
                                                                            name={item.Id}
                                                                            onChange={handleNumberOfQuestion}
                                                                            placeholder={isNaN(questionNumber[item.Id]) && 0}
                                                                            value={questionNumber[item.Id]}
                                                                        />
                                                                    </FormControl>
                                                                    {questionNumberErrors[item.Id] && <label className="question-title error-label">Value should not be negative</label>}
                                                                </div>
                                                                <div className="quiz-select select-four-layout">
                                                                    <label for="points">Passing %</label>
                                                                    <FormControl id="points">
                                                                        <TextField
                                                                            id="outlined-number"
                                                                            label=""
                                                                            type="number"
                                                                            className="mui_select_con"
                                                                            name={item.Id}
                                                                            defaultValue={item.PassingPercentage}
                                                                            disabled={questionNumber[item.Id] === 0 || isNaN(questionNumber[item.Id])}
                                                                            onChange={handlePercentage}
                                                                            placeholder={isNaN(levelPercentage[item.Id]) && 0}
                                                                            value={levelPercentage[item.Id]}
                                                                        />
                                                                    </FormControl>
                                                                    {percentageErrors[item.Id] && <label className="question-title error-label">Value should not be negative</label>}
                                                                </div>
                                                                <div className="quiz-select select-four-layout">
                                                                    <label for="retake">Points for 1 question</label>
                                                                    <FormControl id="points">
                                                                        <TextField
                                                                            id="outlined-number"
                                                                            label=""
                                                                            type="number"
                                                                            className="mui_select_con"
                                                                            name={item.Id}
                                                                            disabled={questionNumber[item.Id] === 0 || isNaN(questionNumber[item.Id])}
                                                                            onChange={handleQuestionPoints}
                                                                            placeholder={isNaN(questionPoint[item.Id]) && 0}
                                                                            value={questionPoint[item.Id]}
                                                                        />
                                                                    </FormControl>
                                                                    {questionPointErrors[item.Id] && <label className="question-title error-label">Value should not be negative</label>}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="quiz-selection-con m-0">
                                                    {quizLevelEmptyError && <label className="question-title error-label"><p>Please add questions per levels.</p></label>}
                                                    {quizLevelConfigError && <label className="question-title error-label"><p>Please complete the difficulty level values.</p></label>}
                                                    {levelPercentError && <label className="question-title error-label"><p>Passing Percentge should be between 1 - 100 %.</p></label>}
                                                    {checkConfigError && <label className="question-title error-label"><p>Please add configuration as per selected question level.</p></label>}
                                                </div>

                                                <div className="quiz-selection-con d-flex align-items-center justify-content-between grey-box-input">
                                                    <div className="quiz-select">
                                                        <label for="points">Total Questions</label>
                                                        <input type="text" disabled value={totalQuestions} />
                                                    </div>
                                                    <div className="quiz-select">
                                                        <label for="retake">Maximum Points</label>
                                                        <input type="text" disabled value={totalScores} />
                                                    </div>
                                                </div>
                                                <div className="grey-box-input quiz-selection-con d-flex align-items-center justify-content-between">
                                                    <div className="quiz-select">
                                                        <label for="retake">Passing %</label>
                                                        <input type="text" disabled value={passingPercentage} />
                                                    </div>
                                                </div>
                                                <div className="quiz-selection-con m-0">
                                                    {quizTotalQuestionError && <label className="question-title error-label"><p>Question number cannot be less than 0</p></label>}
                                                </div>
                                                <div className="add-question-btn fixed-btns model-padding">
                                                    <button className="primary-button sec-btn" onClick={handleCreateQuiz}>{"Create Quiz"}</button>
                                                </div>
                                            </>
                                            : <Spinner />        
                                    }
                                </Box>
                            </div>
                        </Modal>

                        {/* Edit Question Modal */}
                        <Modal
                            open={isEditQuestionOpen}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div className='popup-breadcrumd'>
                                <Box sx={QuizPopupStyle}>
                                    {
                                        !modelLoader ?
                                            <>
                                                <div className=' quiz-creator-con'>
                                                    <h2>Edit Question</h2>
                                                    <div>
                                                        <img src="../assets/close-black.png" alt="lms-logo" class="close-icon" onClick={handleClose}/>
                                                    </div>
                                                </div>
                                                <div className='mui-hr-line'></div>
                                                <div className="quiz-text-input">
                                                    <label className="question-title"><p>. Write your question here *</p></label>
                                                    <input type="text" className={questionTitleError && `error`} placeholder="Write your question here" value={questionTitle}
                                                        error={questionTitleError}
                                                        onChange={(e) => {
                                                            setQuestionTitle(e.target.value)
                                                            if(e.target.value.trim().length > 250){
                                                                setQuestionTitleError(true)
                                                            }else{
                                                                setQuestionTitleError(false)
                                                            }
                                                        }} />
                                                    {questionTitleError && <label className="question-title error-label"><p>Question should between 1-250 characters</p></label>}
                                                </div>
                                                <div className="quiz-selection-con mt-4 b-0 d-flex align-items-center justify-content-between pb-0">
                                                    <div className="quiz-select">
                                                        <label for="points">Question Type*</label>
                                                        <FormControl id="points" >
                                                            <Select IconComponent={ExpandMoreIcon} value={questionType || "Select Question Type"} placeholder="Please Select Type" input={<OutlinedInput label="Tag"
                                                                onChange={(e) => {
                                                                    setQuestionType(e.target.value.trim())
                                                                    setQuestionTypeError(false)
                                                                }} />}
                                                                error={questionTypeError}
                                                                helperText={questionTypeError && `Please select type`}
                                                            >
                                                                <MenuItem value="Select Question Type">Select Question Type</MenuItem>
                                                                <MenuItem value={singleChoice}>{singleChoice}</MenuItem>
                                                                <MenuItem value={multipleChoice}>{multipleChoice}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="quiz-select">
                                                        <label for="retake" className="question-title m-0"><p>Difficulty level*</p></label>
                                                        <FormControl id="retake">
                                                            <Select IconComponent={ExpandMoreIcon}
                                                                error={questionlevelError}
                                                                placeholder="Please Select Difficulty"
                                                                value={questionLevel.toString() || "Select Level"}
                                                                input={<OutlinedInput label="Tag" onChange={(e) => {
                                                                    setQuestionLevel(e.target.value)
                                                                    setQuestionLevelError(false)
                                                                }} />}>
                                                                <MenuItem value={questionLevel || "Select Level"}>Select Level*</MenuItem>
                                                                {
                                                                    props.questionLevels && props.questionLevels.map((item, idx) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={item.Id}>{item.Name}</MenuItem>
                                                                        )
                                                                    })
                                                                }

                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div className="quiz-selection-con b-0 d-flex align-items-center justify-content-between pb-0">
                                                </div>
                                                <div className=" quiz-selection-con options-container ">
                                                    <h1>Input option for the question & select the correct answer</h1>
                                                    {
                                                        <DragDropContext onDragEnd={handleDrop}>
                                                            <List className="new-quiz-list">
                                                                <Droppable droppableId="list-container">
                                                                    {(provided) => (
                                                                        <div
                                                                            className="list-container"
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            {
                                                                                options.length > 0 &&
                                                                                options.map((element, index) => (
                                                                                    <Draggable key={index} draggableId={`draggable ${index}`} index={index}>
                                                                                        {(provided) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.dragHandleProps}
                                                                                                {...provided.draggableProps}
                                                                                            >
                                                                                                <ListItem disablePadding className="option-item" name='options'>
                                                                                                    <div className="input-option">
                                                                                                        <input type="text" placeholder={`Option ${index + 1}`} name="option" value={element.option || ''} onChange={(e) => handleOptions(index, e)} className={`${document.body.dir === "ltr" ? "op-edit-r" : "op-edit-l"}`} />
                                                                                                        <div className="lms-flex-align-center option-icons">
                                                                                                            {
                                                                                                                questionType === singleChoice &&
                                                                                                                <Radio sx={{ padding: 0 }} name="isCorrect" value={element.isCorrect} checked={element.isCorrect} onChange={(e) => handleRadioOptions(index, e)} />

                                                                                                            }
                                                                                                            {questionType === multipleChoice &&
                                                                                                                <Checkbox sx={{ padding: 0 }} name="isCorrect" checked={element.isCorrect} value={element.isCorrect} onChange={(e) => handleOptions(index, e)} />
                                                                                                            }
                                                                                                            <div><img src="../assets/vertical-bars.png" alt="lms-logo" /></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div onClick={() => removeOptions(index)} className="quiz-del-icon">
                                                                                                        <svg id="small-del-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none" >
                                                                                                            <path d="M17 4.53778C14.04 4.24444 11.0622 4.09333 8.09333 4.09333C6.33333 4.09333 4.57333 4.18222 2.81333 4.36L1 4.53778M5.88889 3.64L6.08444 2.47556C6.22667 1.63111 6.33333 1 7.83556 1H10.1644C11.6667 1 11.7822 1.66667 11.9156 2.48444L12.1111 3.64M15.0889 7.34667L14.5111 16.2978C14.4133 17.6933 14.3333 18.7778 11.8533 18.7778H6.14667C3.66667 18.7778 3.58667 17.6933 3.48889 16.2978L2.91111 7.34667M7.51556 13.8889H10.4756M6.77778 10.3333H11.2222" className="del-stroke" />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </ListItem>

                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))
                                                                            }
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </List>
                                                        </DragDropContext>
                                                    }
                                                    <div className="lms-flex-align-center" onClick={handleOptionClick}>
                                                        <AddOutlinedIcon />
                                                        <h2>Add Option</h2>
                                                    </div>
                                                    {questionOptionsForTypeError && <label className="question-title error-label"><p>Please select question type first</p></label>}
                                                    {questionOptionsError && <label className="question-title error-label"><p>Please create options</p></label>}
                                                    {questionOptionValueError && <label className="question-title error-label"><p>Option cannot be empty</p></label>}
                                                    {questionOptionsMaxError && <label className="question-title error-label"><p>You can not add more than 5 options</p></label>}
                                                    {singleChoiceError && <label className="question-title error-label"><p>Please set only 1 option as correct answer</p></label>}
                                                    {multipleChoiceError && <label className="question-title error-label"><p>Please set atleast 2 options as correct answer</p></label>}
                                                </div>
                                                <div className='quiz-creator-con justify-content-end'>
                                                    <div className='filter-btn-group'>
                                                        <button class="filter-clear-search" onClick={handleSubmitEditQuestion} >Submit</button>
                                                    </div>
                                                </div>
                                            </> : <Spinner /> 
                                    }
                                </Box>
                            </div>
                        </Modal>

                        {/* Delete Dialog */}
                        <Dialog open={deleteQuestionDialog} onClose={handleClose} className='confirmation-popup' >
                            {!localLoader ? <>
                                <div className='d-flex align-items-center justify-content-between'>
                                <DialogTitle>Remove Question</DialogTitle>
                                <div>
                                    <img src="../assets/close-black.png" alt="lms-logo" class="close-icon w-50" onClick={handleClose}/>
                                </div>
                            </div>
                            <Divider className="confirmation-popup-divider" />
                            <DialogContent>
                                <DialogContentText>
                                    Do you really want to remove the selected questions. It will be removed from all the Quizzes ?
                                </DialogContentText>
                            </DialogContent>
                            <Divider className="confirmation-popup-divider" />
                            <DialogActions sx={{ p: 2 }}>
                                <button
                                    className="primary-button ter-btn red-button"
                                    onClick={handleDeleteQuestions}
                                >
                                    Remove
                                </button>
                                <button
                                    className="primary-button ter-btn"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </DialogActions></> : <Spinner/>}
                        </Dialog>

                        <div className='horizontal-divider'></div>
                        <Tooltip title={selectedQuestionsIds.length !== 1 ? "Select one question" : "Edit Question"}>
                            <button className='edit-disable' disabled={selectedQuestionsIds.length !== 1} onClick={handleEditQuestion}>
                                <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 39" fill="none">
                                    <rect class="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                                    <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" class="edit-icon-svg" />
                                </svg>
                            </button>
                        </Tooltip>

                        <Tooltip title={selectedQuestionsIds.length === 0 ? "Select atleast one question" : "Delete Question"}>
                            <button className='edit-disable' disabled={selectedQuestionsIds.length === 0} onClick={handleDeleteDialogs}>
                                <svg id="del-icon" width="39" height="39" viewBox="0 0 42 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" className='del-stroke' stroke-width="0.9" />
                                    <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white" />
                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white" />
                                    <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" class="del-svg" />
                                    <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white" />
                                    <path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white" />
                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white" />
                                </svg>
                            </button>
                        </Tooltip>

                        <div className='horizontal-divider'></div>
                        <svg onClick={handleClickOpenFilter} id="edit-icon" width="39" height="39" viewBox="0 0 48 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.45" y="0.45" width="47.1" height="38.1" rx="2.55" stroke="#062349" stroke-width="0.9" />
                            <path d="M32.3334 12H15.6667L22.3334 19.8833V25.3333L25.6667 27V19.8833L32.3334 12Z" stroke="#062349" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                        <Popover
                            id={filterPopoverId}
                            open={openFilter}
                            anchorEl={filterAnchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{
                                boxShadow: "0px 3px 6px #00000014",
                                borderRadius: "12px",
                            }}
                        >
                            <Box>
                                <div className='filter-popup-main'>
                                    <h2>Sort By</h2>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue={sortFieldDate || Sort.Desc} onChange={(e) => setSortFieldDate(e.target.value)}>
                                        <FormControlLabel className='list-item-text' value={Sort.Desc} control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label="Newest First" />
                                        <FormControlLabel className='list-item-text' value={Sort.Asc} control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label="Oldest First" />
                                    </RadioGroup>
                                    <h2>Question Type</h2>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue={sortFieldType || "All"} onChange={(e) => setSortFieldType(e.target.value)}>
                                        <FormControlLabel className='list-item-text' value="All" control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label="All" />
                                        <FormControlLabel className='list-item-text' value={singleChoice} control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label={singleChoice} />
                                        <FormControlLabel className='list-item-text' value={multipleChoice} control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label={multipleChoice} />
                                    </RadioGroup>

                                    {/* <div className="Root">
                                                                    <h2>Tags</h2>
                                                                    <div className="InputWrapper">

                                                                        <Autocomplete
                                                                            multiple
                                                                            options={options}
                                                                            value={valueSlect}
                                                                            onChange={(event, newValue) => {
                                                                                setSelectValue(newValue);
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <input {...params} placeholder='Search Tags' />
                                                                                    <div className="ExploreMoreIcon">
                                                                                        <KeyboardArrowDownIcon />
                                                                                    </div>
                                                                                </div>

                                                                            )}
                                                                        />
                                                                        {valueSlect.map((option, index) => (
                                                                            <StyledTag
                                                                                key={index}
                                                                                label={option.title}
                                                                                onDelete={() => {
                                                                                    setSelectValue((currentValue) =>
                                                                                        currentValue.filter((val) => val.title !== option.title)
                                                                                    );
                                                                                }}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div> */}
                                    <div className='filter-btn-group '>
                                        {/* <button class="filter-clear-all">Clear All</button> */}
                                        <button class="filter-clear-search" onClick={handleFilter}>Search</button>
                                    </div>
                                </div>
                            </Box>
                        </Popover>


                    </div>
                </div >
                {
                    props.questionLevels && props.questionLevels.map((level, idx) => {
                            return (
                                <CustomTabPanel className="assessment-tab-panel" value={value} index={level.Id}>
                                    <div className='quiz-edit-white-bg'>
                                        <List component="nav" aria-labelledby="nested-list-subheader" key={idx} sx = {{p:0}}>
                                            {
                                                selectedCollection.filter(a => a.LevelId === level.Id).length > 0 ?
                                                selectedCollection.filter(a => a.LevelId === level.Id).map((question, idx) => {
                                                    return (
                                                        <>
                                                            <ListItemButton onClick={() => handleCollapseClick(idx)} className='justify-content-between' key={idx}>
                                                                <div>
                                                                    <FormControlLabel className='list-item-text' control={<Checkbox className='asses-checkbox' checked={selectedQuestionsIds.includes(question.Id)} onClick={(event) => handleSelectQuestions(event, question)} />} label={`${idx + 1}. ${question.Title} `} />
                                                                </div>
                                                                {openIndex === idx ? <ExpandLess className='asses-expand-icon' /> : <ExpandMore className='asses-expand-icon' />}
                                                            </ListItemButton>
                                                            <Collapse in={openIndex === idx} timeout="auto" unmountOnExit>
                                                                <div className={`collapse-inside ${document.body.dir === "ltr" ? "coll-body-left" : "coll-body-right"}`}>
                                                                {
                                                                        question.Type === singleChoice &&
                                                                        <div className='quiz-answer-con'>
                                                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                                                {
                                                                                    question.Choice.map((choice, idx) => {
                                                                                        return (
                                                                                            <FormControlLabel className='list-item-text' value={choice.ChoiceId} control={<Radio className='asses-radiobox' disabled checked={choice.IsCorrectChoice} />} label={`${String.fromCharCode(65 + idx)}) ${choice.Choice}`} />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </RadioGroup>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        question.Type === multipleChoice &&
                                                                        <div className='quiz-answer-con'>
                                                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                                                {
                                                                                    question.Choice.map((choice, idx) => {
                                                                                        return (
                                                                                            <FormControlLabel className='list-item-text' value={choice.ChoiceId} control={<Checkbox className='asses-radiobox' disabled checked={choice.IsCorrectChoice} />} label={`${String.fromCharCode(65 + idx)}) ${choice.Choice}`} />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </RadioGroup>
                                                                        </div>
                                                                    }
                                                                    <div className='d-flex align-items-center justify-content-between mt-3 mb-3 question-info-con'>
                                                                        <div>
                                                                            <p>Tags: <span><strong>{ question.Tags && question.Tags.length > 0 ? question.Tags : "No Tags"}</strong></span></p>
                                                                        </div>
                                                                        <Divider orientation="vertical" flexItem />
                                                                        <div>
                                                                            <p>Date Created: <span><strong>{moment(question.CreatedDate).format("DD/MM/YYYY")}</strong></span></p>
                                                                        </div>
                                                                        <Divider orientation="vertical" flexItem />
                                                                        <div>
                                                                            <p>Question Type: <span><strong>{question.Type}</strong></span></p>
                                                                        </div>
                                                                        <Divider orientation="vertical" flexItem />
                                                                        <div>
                                                                            <p>Difficulty Level: <span><strong>{question.Level}</strong></span></p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                            <Divider />
                                                        </>
                                                    )
                                                }):
                                                !props.questionSearchLoader && <div className='no-questions-added'><h3>NO QUESTION ADDED YET</h3></div>                                         }
                                        </List>
                                    </div>
                                </CustomTabPanel>
                            )
                        })
                }
                {props.questionSearchLoader &&<Spinner/> }
            </div >
        </>
    )
}

const mapStateToProps = (state) => ({
    pageNumber: state.quiz.QuizPagenumber,
    questionLevels: state.quiz.QuestionLevels,
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchQuestionAction: fetchQuestions,
        fetchQuizAction: fetchQuiz
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTableLayout);

