import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import ProgressBar from "../Common/ProgressBar";
import { Avatar, Stack } from "@mui/material";
import GenerateProfilePic from "../Common/GenerateProfilePictureIntitals";
import ErrorTab from "../Common/ErrorTab";
import VideoUploadedDate from "../Common/VideoUploadedDate";
import VideoExpireTime from "../Common/VideoExpireTime";
import { connect } from "react-redux";

export const SavedCoursesList = (props) => {
  const [borderColor, setBorderColor] = React.useState({});

  const handleBorderColorChange = (courseId, color) => {
    setBorderColor((prev) => ({
      ...prev,
      [courseId]: color,
    }));
  };

  if (props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((course, idx) => {
          const borderStyle = {
            border: `2px solid ${borderColor[course.Id] || '#3ADDC8'}`,
          };

          return (
            <li
              className="card-space"
              key={idx}
              style={!course.WatchExpirationDateTime || course.WatchExpirationDateTime === null 
                ? {} 
                : { border: `2px solid ${borderColor[course.Id] || '#3ADDC8'}` }
              }
            >
              <Link to={`/course/${course.PlaylistId}/0`}>
                <div className="card-img">
                  <img
                    src={course.ThumbnailUrl}
                    alt="lms-logo" />
                </div>
                <ProgressBar value={course.PercentageOfCourseCompleted}></ProgressBar>
                <div className="card-info lms-flex-between">
                  <p >
                    {course.PlaylistCategoryName}
                  </p>
                  <div className="card-date">
                    {!course.WatchExpirationDateTime || course.WatchExpirationDateTime === null ? (
                      <VideoUploadedDate createdDate={course.CreatedDate} />
                    ) : (
                      <VideoExpireTime expirationTime={course.WatchExpirationDateTime}
                        onBorderColorChange={(color) =>
                          handleBorderColorChange(course.Id, color)
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="card-heading">{course.Title}</div>
                <div className="card-para">{course.Description}</div>

                {props.type !== "save" && (
                  <div style={{ marginTop: 10 }}>
                  <Stack direction="row" className= "d-flex align-items-center gap-1">
                    {
                      props.type === "sharebyyou" ? (
                        <Avatar
                          alt="Remy Sharp"
                          src={course.SharedWithProfilePictureUrl === '' || course.SharedWithProfilePictureUrl === null ? GenerateProfilePic(course.SharedWithName) : `${process.env.REACT_APP_AZURE_BLOB_BASE_URL}/${props.tenant.tenant.organisationUniqueName}/cdn${course.SharedWithProfilePictureUrl}`}
                          sx={{ width: 25, height: 25 }}
                        />
                      ) : (
                        <Avatar
                          alt="Remy Sharp"
                          src={course.SharedByProfilePictureUrl === '' || course.SharedByProfilePictureUrl === null ? GenerateProfilePic(course.SharedByName) : `${process.env.REACT_APP_AZURE_BLOB_BASE_URL}/${props.tenant.tenant.organisationUniqueName}/cdn${course.SharedByProfilePictureUrl}`}
                          sx={{ width: 25, height: 25 }}
                        />
                      )}
                    <div className="identify">{props.type === 'sharebyyou' ? course.SharedWithName : course.SharedByName}</div>
                  </Stack>
                </div>)}
              </Link>
            </li>
          );
        })}
      </React.Fragment>
    );
  } else {
    return (
      <div id="no-result-saved-item">
        <h1>No Courses are available</h1>
        <p>Please search with alternative keyword</p>
      </div>
    )
  }
};

const mapStateToProps = (state) => ({
  tenant: state.tenant
});

export default connect(mapStateToProps, null) (SavedCoursesList);
