export const FETCH_TUTORIALS_START = "FETCH_TUTORIALS_START";
export const FETCH_TUTORIALS_SUCCEEDED = "FETCH_TUTORIALS_SUCCEEDED";
export const FETCH_TUTORIALS_FAILED = "FETCH_TUTORIALS_FAILED";
export const FETCH_SAVED_TUTORIALS_START = "FETCH_SAVED_TUTORIALS_START";
export const FETCH_SAVED_TUTORIALS_SUCCEEDED = "FETCH_SAVED_TUTORIALS_SUCCEEDED";
export const FETCH_SAVED_TUTORIALS_FAILED = "FETCH_SAVED_TUTORIALS_FAILED";

export const RESET_TUTORIALS_DETAILS_START = "RESET_TUTORIALS_DETAILS_START";
export const RESET_TUTORIALS_DETAILS_SUCCEEDED = "RESET_TUTORIALS_DETAILS_SUCCEEDED";

export const FETCH_SAVED_LOADMORE_TUTORIALS_START = "FETCH_SAVED_LOADMORE_TUTORIALS_START";
export const FETCH_SAVED_LOADMORE_TUTORIALS_SUCCEEDED = "FETCH_SAVED_LOADMORE_TUTORIALS_SUCCEEDED";
export const FETCH_SAVED_LOADMORE_TUTORIALS_FAILED = "FETCH_SAVED_LOADMORE_TUTORIALS_FAILED";
export const FETCH_SAVED_LOADMORE_TUTORIALS_FINISHED = "FETCH_SAVED_LOADMORE_TUTORIALS_FINISHED";

export const FETCH_TUTORIALS_LOADMORE_START = "FETCH_TUTORIALS_LOADMORE_START";
export const FETCH_TUTORIALS_LOADMORE_SUCCEEDED = "FETCH_TUTORIALS_LOADMORE_SUCCEEDED";
export const FETCH_TUTORIALS_LOADMORE_FAILED = "FETCH_TUTORIALS_LOADMORE_FAILED";
export const FETCH_TUTORIALS_LOADMORE_FINISHED = "FETCH_TUTORIALS_LOADMORE_FINISHED";

export const FETCH_ADMIN_TUTORIALS_LOADMORE_START = "FETCH_ADMIN_TUTORIALS_LOADMORE_START";
export const FETCH_ADMIN_TUTORIALS_LOADMORE_SUCCEEDED = "FETCH_ADMIN_TUTORIALS_LOADMORE_SUCCEEDED";
export const FETCH_ADMIN_TUTORIALS_LOADMORE_FAILED = "FETCH_ADMIN_TUTORIALS_LOADMORE_FAILED";
export const FETCH_ADMIN_TUTORIALS_LOADMORE_FINISHED = "FETCH_ADMIN_TUTORIALS_LOADMORE_FINISHED";

export const FETCH_ADMIN_COURSES_LOADMORE_START = "FETCH_ADMIN_COURSES_LOADMORE_START";
export const FETCH_ADMIN_COURSES_LOADMORE_SUCCEEDED = "FETCH_ADMIN_COURSES_LOADMORE_SUCCEEDED";
export const FETCH_ADMIN_COURSES_LOADMORE_FAILED = "FETCH_ADMIN_COURSES_LOADMORE_FAILED";
export const FETCH_ADMIN_COURSES_LOADMORE_FINISHED = "FETCH_ADMIN_COURSES_LOADMORE_FINISHED";

export const FETCH_COURSES_LOADMORE_START = "FETCH_COURSES_LOADMORE_START";
export const FETCH_COURSES_LOADMORE_SUCCEEDED = "FETCH_COURSES_LOADMORE_SUCCEEDED";
export const FETCH_COURSES_LOADMORE_FAILED = "FETCH_COURSES_LOADMORE_FAILED";
export const FETCH_COURSES_LOADMORE_FINISHED = "FETCH_COURSES_LOADMORE_FINISHED";

export const FETCH_USERS_LOADMORE_START = "FETCH_USERS_LOADMORE_START";
export const FETCH_USERS_LOADMORE_SUCCEEDED = "FETCH_USERS_LOADMORE_SUCCEEDED";
export const FETCH_USERS_LOADMORE_FAILED = "FETCH_USERS_LOADMORE_FAILED";
export const FETCH_USERS_LOADMORE_FINISHED = "FETCH_USERS_LOADMORE_FINISHED";

export const FETCH_CATEGORY_START = "FETCH_CATEGORY_START";
export const FETCH_CATEGORY_SUCCEEDED = "FETCH_CATEGORY_SUCCEEDED";
export const FETCH_CATEGORY_FAILED = "FETCH_CATEGORY_FAILED";

export const FETCH_PLAYLIST_CATEGORY_START = "FETCH_PLAYLIST_CATEGORY_START";
export const FETCH_PLAYLIST_CATEGORY_SUCCEEDED = "FETCH_PLAYLIST_CATEGORY_SUCCEEDED";
export const FETCH_PLAYLIST_CATEGORY_FAILED = "FETCH_PLAYLIST_CATEGORY_FAILED";

export const FETCH_TUTORIALS_DETAIL_START = "FETCH_TUTORIALS_DETAIL_START";
export const FETCH_TUTORIALS_DETAIL_SUCCESS = "FETCH_TUTORIALS_DETAIL_SUCCESS";

export const FILTER_TUTORIALS_START = "FILTER_TUTORIALS_START";
export const FILTER_TUTORIALS_SUCCEEDED = "FILTER_TUTORIALS_SUCCEEDED";
export const FILTER_TUTORIALS_FAILED = "FILTER_TUTORIALS_FAILED";

export const SORT_TUTORIALS_START = "SORT_TUTORIALS_START";
export const SORT_TUTORIALS_SUCCEEDED = "SORT_TUTORIALS_SUCCEEDED";
export const SORT_TUTORIALS_FAILED = "SORT_TUTORIALS_FAILED";

export const FETCH_COURSES_START = "FETCH_COURSES_START";
export const FETCH_COURSES_SUCCEEDED = "FETCH_COURSES_SUCCEEDED";
export const FETCH_COURSES_FAILED = "FETCH_COURSES_FAILED";

export const FETCH_SAVED_COURSES_START = "FETCH_SAVED_COURSES_START";
export const FETCH_SAVED_COURSES_SUCCEEDED = "FETCH_SAVED_COURSES_SUCCEEDED";
export const FETCH_SAVED_COURSES_FAILED = "FETCH_SAVED_COURSES_FAILED";

export const FETCH_SAVED_COURSES_LOADMORE_START = "FETCH_SAVED_COURSES_LOADMORE_START";
export const FETCH_SAVED_COURSES_LOADMORE_SUCCEEDED = "FETCH_SAVED_COURSES_LOADMORE_SUCCEEDED";
export const FETCH_SAVED_COURSES_LOADMORE_FAILED = "FETCH_SAVED_COURSES_LOADMORE_FAILED";
export const FETCH_SAVED_COURSES_LOADMORE_FINISHED = "FETCH_SAVED_COURSES_LOADMORE_FINISHED";


export const FETCH_SHAREDBYME_COURSES_START = "FETCH_SHAREDBYME_COURSES_START";
export const FETCH_SHAREDBYME_COURSES_SUCCEEDED = "FETCH_SHAREDBYME_COURSES_SUCCEEDED";
export const FETCH_SHAREDBYME_COURSES_FAILED = "FETCH_SHAREDBYME_COURSES_FAILED";

export const FETCH_SHAREDBYME_COURSES_LOADMORE_START = "FETCH_SHAREDBYME_COURSES_LOADMORE_START";
export const FETCH_SHAREDBYME_COURSES_LOADMORE_SUCCEEDED = "FETCH_SHAREDBYME_COURSES_LOADMORE_SUCCEEDED";
export const FETCH_SHAREDBYME_COURSES_LOADMORE_FAILED = "FETCH_SHAREDBYME_COURSES_LOADMORE_FAILED";
export const FETCH_SHAREDBYME_COURSES_LOADMORE_FINISHED = "FETCH_SHAREDBYME_COURSES_LOADMORE_FINISHED";


export const FETCH_SHAREDWITHME_COURSES_START = "FETCH_SHAREDWITHME_COURSES_START";
export const FETCH_SHAREDWITHME_COURSES_SUCCEEDED = "FETCH_SHAREDWITHME_COURSES_SUCCEEDED";
export const FETCH_SHAREDWITHME_COURSES_FAILED = "FETCH_SHAREDWITHME_COURSES_FAILED";

export const FETCH_SHAREDWITHME_COURSES_LOADMORE_START = "FETCH_SHAREDWITHME_COURSES_LOADMORE_START";
export const FETCH_SHAREDWITHME_COURSES_LOADMORE_SUCCEEDED = "FETCH_SHAREDWITHME_COURSES_LOADMORE_SUCCEEDED";
export const FETCH_SHAREDWITHME_COURSES_LOADMORE_FAILED = "FETCH_SHAREDWITHME_COURSES_LOADMORE_FAILED";
export const FETCH_SHAREDWITHME_COURSES_LOADMORE_FINISHED = "FETCH_SHAREDWITHME_COURSES_LOADMORE_FINISHED";


export const FETCH_SHAREDBYME_VIDEOS_START = "FETCH_SHAREDBYME_VIDEOS_START";
export const FETCH_SHAREDBYME_VIDEOS_SUCCEEDED = "FETCH_SHAREDBYME_VIDEOS_SUCCEEDED";
export const FETCH_SHAREDBYME_VIDEOS_FAILED = "FETCH_SHAREDBYME_VIDEOS_FAILED";

export const FETCH_SHAREDBYME_VIDEOS_LOADMORE_START = "FETCH_SHAREDBYME_VIDEOS_LOADMORE_START";
export const FETCH_SHAREDBYME_VIDEOS_LOADMORE_SUCCEEDED = "FETCH_SHAREDBYME_VIDEOS_LOADMORE_SUCCEEDED";
export const FETCH_SHAREDBYME_VIDEOS_LOADMORE_FAILED = "FETCH_SHAREDBYME_VIDEOS_LOADMORE_FAILED";
export const FETCH_SHAREDBYME_VIDEOS_LOADMORE_FINISHED = "FETCH_SHAREDBYME_VIDEOS_LOADMORE_FINISHED";

export const FETCH_QUIZ_LOADMORE_START = "FETCH_QUIZ_LOADMORE_START";
export const FETCH_QUIZ_LOADMORE_SUCCEEDED = "FETCH_QUIZ_LOADMORE_SUCCEEDED";
export const FETCH_QUIZ_LOADMORE_FAILED = "FETCH_QUIZ_LOADMORE_FAILED";
export const FETCH_QUIZ_LOADMORE_FINISHED = "FETCH_QUIZ_LOADMORE_FINISHED";

export const FETCH_SHAREDWITHME_VIDEOS_START = "FETCH_SHAREDWITHME_VIDEOS_START";
export const FETCH_SHAREDWITHME_VIDEOS_SUCCEEDED = "FETCH_SHAREDWITHME_VIDEOS_SUCCEEDED";
export const FETCH_SHAREDWITHME_VIDEOS_FAILED = "FETCH_SHAREDWITHME_VIDEOS_FAILED";

export const FETCH_SHAREDWITHME_VIDEOS_LOADMORE_START = "FETCH_SHAREDWITHME_VIDEOS_LOADMORE_START";
export const FETCH_SHAREDWITHME_VIDEOS_LOADMORE_SUCCEEDED = "FETCH_SHAREDWITHME_VIDEOS_LOADMORE_SUCCEEDED";
export const FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FAILED = "FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FAILED";
export const FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FINISHED = "FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FINISHED";


export const FETCH_INPROGRESS_COURSES_START = "FETCH_INPROGRESS_COURSES_START";
export const FETCH_INPROGRESS_COURSES_SUCCEEDED = "FETCH_INPROGRESS_COURSES_SUCCEEDED";
export const FETCH_INPROGRESS_COURSES_FAILED = "FETCH_INPROGRESS_COURSES_FAILED";

export const FETCH_QUIZ_START = "FETCH_QUIZ_START";
export const FETCH_QUIZ_SUCCEEDED = "FETCH_QUIZ_SUCCEEDED";
export const FETCH_QUIZ_FAILED = "FETCH_QUIZ_FAILED";

export const FETCH_COMPLETED_COURSES_START = "FETCH_COMPLETED_COURSES_START";
export const FETCH_COMPLETED_COURSES_SUCCEEDED = "FETCH_COMPLETED_COURSES_SUCCEEDED";
export const FETCH_COMPLETED_COURSES_FAILED = "FETCH_COMPLETED_COURSES_FAILED";

export const FETCH_CERTIFICATES_START = "FETCH_CERTIFICATES_START";
export const FETCH_CERTIFICATES_SUCCEEDED = "FETCH_CERTIFICATES_SUCCEEDED";
export const FETCH_CERTIFICATES_FAILED = "FETCH_CERTIFICATES_FAILED";

export const FETCH_BADGES_START = "FETCH_BADGES_START";
export const FETCH_BADGES_SUCCEEDED = "FETCH_BADGES_SUCCEEDED";
export const FETCH_BADGES_FAILED = "FETCH_BADGES_FAILED";

export const SEARCH_TUTORIALS_START = "SEARCH_TUTORIALS_START";
export const SEARCH_TUTORIALS_SUCCEEDED = "SEARCH_TUTORIALS_SUCCEEDED";
export const SEARCH_TUTORIALS_FAILED = "SEARCH_TUTORIALS_FAILED";
export const RESET_TUTORIALS_PAGE_NUMBER = "RESET_TUTORIALS_PAGE_NUMBER";

export const FETCH_COURSES_DETAIL_START = " FETCH_COURSES_DETAIL_START";
export const FETCH_COURSES_DETAIL_SUCCEEDED = "FETCH_COURSES_DETAIL_SUCCEEDED";
export const FETCH_COURSES_DETAIL_FAILED = " FETCH_COURSES_DETAIL_FAILED";

export const FETCH_COURSES_ADMIN_START = "FETCH_COURSES_ADMIN_START";
export const FETCH_COURSES_ADMIN_SUCCEEDED = "FETCH_COURSES_ADMIN_SUCCEEDED";
export const FETCH_COURSES_ADMIN_FAILED = "FETCH_COURSES_ADMIN_FAILED";

export const FETCH_ALL_USERS_START = " FETCH_ALL_USERS_START";
export const FETCH_ALL_USERS_SUCCEEDED = "FETCH_ALL_USERS_SUCCEEDED";
export const FETCH_ALL_USERS_FAILED = " FETCH_ALL_USERS_FAILED";

export const FILTER_COURSES_START = "FILTER_COURSES_START";
export const FILTER_COURSES_SUCCEEDED = "FILTER_COURSES_SUCCEEDED";
export const FILTER_COURSES_FAILED = "FILTER_COURSES_FAILED";

export const SORT_COURSES_START = "SORT_COURSES_START";
export const SORT_COURSES_SUCCEEDED = "SORT_COURSES_SUCCEEDED";
export const SORT_COURSES_FAILED = "SORT_COURSES_FAILED";

export const POST_TUTORIAL_VIDEO_START = "POST_TUTORIAL_START";
export const POST_TUTORIAL_VIDEO_SUCCEEDED = "POST_TUTORIAL_VIDEO_SUCCEEDED";

export const VIDEO_UPLOAD_CHECK = "VIDEO_UPLOAD_CHECK";
export const VIDEO_UPLOAD_CHECK_SUCCEEDED = "VIDEO_UPLOAD_CHECK_SUCCEEDED";
export const VIDEO_UPLOADED_CHECK = "VIDEO_UPLOADED_CHECK";
export const VIDEO_UPLOADED_SUCCESSFULLY = "  VIDEO_UPLOADED_SUCCESSFULLY";



export const CREATE_TUTORIAL_DOCUMENT_START = "CREATE_TUTORIAL_DOCUMENT_START";
export const CREATE_TUTORIAL_DOCUMENT_SUCCEEDED =
  "CREATE_TUTORIAL_DOCUMENT_SUCCEEDED";

export const UPDATE_TUTORIAL_VIDEO_START = "UPDATE_TUTORIAL_VIDEO_START";
export const UPDATE_TUTORIAL_VIDEO_SUCCEEDED =
  "UPDATE_TUTORIAL_VIDEO_SUCCEEDED";

export const UPDATE_DOCUMENT_VIDEO_START = "UPDATE_DOCUMENT_VIDEO_START";
export const UPDATE_DOCUMENT_VIDEO_SUCCEEDED =
  "UPDATE_DOCUMENT_VIDEO_SUCCEEDED";

export const FETCH_ADMIN_VIDEO_START = "FETCH_ADMIN_VIDEO_START";
export const FETCH_ADMIN_VIDEO_SUCCEEDED = "FETCH_ADMIN_VIDEO_SUCCEEDED";

export const FETCH_ADMIN_COURSE_START = "FETCH_ADMIN_COURSE_START";
export const FETCH_ADMIN_COURSE_SUCCEEDED = "FETCH_ADMIN_COURSE_SUCCEEDED";
export const RESET_COLLECTION_PAGE_NUMBER = "RESET_COLLECTION_PAGE_NUMBER";

export const GET_USER_DETAILS_START = "GET_USER_DETAILS_START";
export const GET_USER_DETAILS_SUCCEEDED = "GET_USER_DETAILS_SUCCEEDED";
export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAIL";

export const GET_PROFILE_DATA_START = "GET_PROFILE_DATA_START";
export const GET_PROFILE_DATA_SUCCEEDED = "GET_PROFILE_DATA_SUCCEEDED";
export const GET_PROFILE_DATA_FAILED = "GET_PROFILE_DATA_FAILED";


export const FETCH_ROLES_START = "FETCH_ROLES_START";
export const FETCH_ROLES_SUCCEEDED = "FETCH_ROLES_SUCCEEDED";
export const FETCH_ROLES_FAILED = "FETCH_ROLES_FAILED";

export const COLLECTION_TYPE = "COLLECTION_TYPE";
export const HANDLE_BLUEBAR_START = "HANDLE_BLUEBAR_START";
export const HANDLE_BLUEBAR_SUCCEEDED = "HANDLE_BLUEBAR_SUCCEEDED";

export const FETCH_COURSE_REPORT_START = "FETCH_COURSE_REPORT_START";
export const FETCH_COURSE_REPORT_SUCCEEDED = "FETCH_COURSE_REPORT_SUCCEEDED";
export const FETCH_COURSE_REPORT_FAILED = "FETCH_COURSE_REPORT_FAILED";
export const FETCH_ORGANISATION_DETAILS_START = "FETCH_ORGANISATION_DETAILS_START";
export const FETCH_ORGANISATION_DETAILS_SUCCEEDED = "FETCH_ORGANISATION_DETAILS_SUCCEEDED";
export const FETCH_ORGANISATION_DETAILS_FAILED = "FETCH_ORGANISATION_DETAILS_FAILED";

export const HANDLE_BLUEBAR_COURSE_START = "HANDLE_BLUEBAR_COURSE_START";
export const HANDLE_BLUEBAR_COURSE_SUCCEEDED = "HANDLE_BLUEBAR_COURSE_SUCCEEDED";

export const FETCH_TENANT_DETAILS_START = "FETCH_TENANT_DETAILS_START";
export const FETCH_TENANT_DETAILS_SUCCEEDED = "FETCH_TENANT_DETAILS_SUCCEEDED";
export const FETCH_TENANT_DETAILS_FAILED = "FETCH_TENANT_DETAILS_FAILED";


export const FETCH_ALL_GROUPS_START = "FETCH_ALL_GROUPS_START"
export const FETCH_ALL_GROUPS_SUCCEEDED = 'FETCH_ALL_GROUPS_SUCCEEDED'

export const FETCH_ALL_GROUPS_LOADMORE_START = "FETCH_ALL_GROUPS_LOADMORE_START"
export const FETCH_ALL_GROUPS_LOADMORE_SUCCEEDED = 'FETCH_ALL_GROUPS_LOADMORE_SUCCEEDED'
export const FETCH_ALL_GROUPS_LOADMORE_FAILED = 'FETCH_ALL_GROUPS_LOADMORE_FAILED'
export const FETCH_ALL_GROUPS_LOADMORE_FINISHED = 'FETCH_ALL_GROUPS_LOADMORE_FINISHED'

export const FETCH_ADMIN_SETTINGS_START = "FETCH_ADMIN_SETTINGS_START";
export const FETCH_ADMIN_SETTINGS_SUCCEEDED = "FETCH_ADMIN_SETTINGS_SUCCEEDED";
export const FETCH_ADMIN_SETTINGS_FAILED = "FETCH_ADMIN_SETTINGS_FAILED";

export const FETCH_QUESTION_LEVELS_START = "FETCH_QUESTION_LEVELS_START"
export const FETCH_QUESTION_LEVELS_SUCCEEDED = "FECH_QUESTION_LEVELS_SUCCEEDED"
export const FETCH_QUESTION_LEVELS_FAILED = "FETCH_QUESTION_LEVELS_FAILED"

export const FETCH_QUESTION_START = "FETCH_QUESTION_START"
export const FETCH_QUESTION_SUCCEEDED = "FECH_QUESTION_SUCCEEDED"
export const FETCH_QUESTION_FAILED = "FETCH_QUESTION_FAILED"

export const FETCH_QUESTION_LOADMORE_START = "FETCH_QUESTION_LOADMORE_START"
export const FETCH_QUESTION_LOADMORE_SUCCEEDED = "FECH_QUESTION_LOADMORE_SUCCEEDED"
export const FETCH_QUESTION_LOADMORE_FAILED = "FETCH_QUESTION_LOADMORE_FAILED"
export const FETCH_QUESTION_LOADMORE_FINISHED = "FETCH_QUESTION_LOADMORE_FINISHED"


export const SIGNOUT_USER = "SIGNOUT_USER";


export default {
  COLLECTION_TYPE,

  HANDLE_BLUEBAR_START,
  HANDLE_BLUEBAR_SUCCEEDED,

  HANDLE_BLUEBAR_COURSE_START,
  HANDLE_BLUEBAR_COURSE_SUCCEEDED,

  FETCH_TENANT_DETAILS_START,
  FETCH_TENANT_DETAILS_SUCCEEDED,
  FETCH_TENANT_DETAILS_FAILED,


  FETCH_TUTORIALS_START,
  FETCH_TUTORIALS_SUCCEEDED,
  FETCH_TUTORIALS_FAILED,

  RESET_TUTORIALS_DETAILS_START,
  RESET_TUTORIALS_DETAILS_SUCCEEDED,

  FETCH_SAVED_TUTORIALS_START,
  FETCH_SAVED_TUTORIALS_SUCCEEDED,
  FETCH_SAVED_TUTORIALS_FAILED,

  FETCH_ORGANISATION_DETAILS_START,
  FETCH_ORGANISATION_DETAILS_SUCCEEDED,
  FETCH_ORGANISATION_DETAILS_FAILED,


  FETCH_TUTORIALS_LOADMORE_START,
  FETCH_TUTORIALS_LOADMORE_SUCCEEDED,
  FETCH_TUTORIALS_LOADMORE_FAILED,
  FETCH_TUTORIALS_DETAIL_START,
  FETCH_TUTORIALS_DETAIL_SUCCESS,
  FILTER_TUTORIALS_START,
  FILTER_TUTORIALS_SUCCEEDED,
  FILTER_TUTORIALS_FAILED,

  FETCH_CATEGORY_START,
  FETCH_CATEGORY_SUCCEEDED,
  FETCH_CATEGORY_FAILED,

  FETCH_PLAYLIST_CATEGORY_START,
  FETCH_PLAYLIST_CATEGORY_SUCCEEDED,
  FETCH_PLAYLIST_CATEGORY_FAILED,

  SORT_TUTORIALS_START,
  SORT_TUTORIALS_SUCCEEDED,
  SORT_TUTORIALS_FAILED,

  SEARCH_TUTORIALS_START,
  SEARCH_TUTORIALS_SUCCEEDED,
  SEARCH_TUTORIALS_FAILED,

  FETCH_COURSES_START,
  FETCH_COURSES_SUCCEEDED,
  FETCH_COURSES_FAILED,

  FETCH_ROLES_START,
  FETCH_ROLES_SUCCEEDED,
  FETCH_ROLES_FAILED,

  FETCH_SAVED_COURSES_START,
  FETCH_SAVED_COURSES_SUCCEEDED,
  FETCH_SAVED_COURSES_FAILED,

  FETCH_ALL_USERS_START,
  FETCH_ALL_USERS_SUCCEEDED,
  FETCH_ALL_USERS_FAILED,


  FETCH_INPROGRESS_COURSES_START,
  FETCH_INPROGRESS_COURSES_SUCCEEDED,
  FETCH_INPROGRESS_COURSES_FAILED,

  FETCH_COMPLETED_COURSES_START,
  FETCH_COMPLETED_COURSES_SUCCEEDED,
  FETCH_COMPLETED_COURSES_FAILED,

  FETCH_CERTIFICATES_START,
  FETCH_CERTIFICATES_SUCCEEDED,
  FETCH_CERTIFICATES_FAILED,

  FETCH_COURSE_REPORT_START,
  FETCH_COURSE_REPORT_SUCCEEDED,
  FETCH_COURSE_REPORT_FAILED,

  FETCH_BADGES_START,
  FETCH_BADGES_SUCCEEDED,
  FETCH_BADGES_FAILED,

  FETCH_COURSES_DETAIL_START,
  FETCH_COURSES_DETAIL_SUCCEEDED,
  FETCH_COURSES_DETAIL_FAILED,

  FETCH_COURSES_ADMIN_START,
  FETCH_COURSES_ADMIN_SUCCEEDED,
  FETCH_COURSES_ADMIN_FAILED,

  FILTER_COURSES_START,
  FILTER_COURSES_SUCCEEDED,
  FILTER_COURSES_FAILED,

  SORT_COURSES_START,
  SORT_COURSES_SUCCEEDED,
  SORT_COURSES_FAILED,

  POST_TUTORIAL_VIDEO_START,
  POST_TUTORIAL_VIDEO_SUCCEEDED,

  VIDEO_UPLOAD_CHECK,
  VIDEO_UPLOAD_CHECK_SUCCEEDED,

  VIDEO_UPLOADED_CHECK,
  VIDEO_UPLOADED_SUCCESSFULLY,

  CREATE_TUTORIAL_DOCUMENT_START,
  CREATE_TUTORIAL_DOCUMENT_SUCCEEDED,

  UPDATE_TUTORIAL_VIDEO_START,
  UPDATE_TUTORIAL_VIDEO_SUCCEEDED,

  UPDATE_DOCUMENT_VIDEO_START,
  UPDATE_DOCUMENT_VIDEO_SUCCEEDED,

  FETCH_ADMIN_VIDEO_START,
  FETCH_ADMIN_VIDEO_SUCCEEDED,

  FETCH_ALL_GROUPS_START,
  FETCH_ALL_GROUPS_SUCCEEDED,

  FETCH_ADMIN_COURSE_START,
  FETCH_ADMIN_COURSE_SUCCEEDED,

  GET_USER_DETAILS_START,
  GET_USER_DETAILS_SUCCEEDED,
  GET_USER_DETAILS_FAILED,

  GET_PROFILE_DATA_START,
  GET_PROFILE_DATA_SUCCEEDED,
  GET_PROFILE_DATA_FAILED,

  FETCH_SHAREDBYME_COURSES_START,
  FETCH_SHAREDBYME_COURSES_SUCCEEDED,
  FETCH_SHAREDBYME_COURSES_FAILED,
  FETCH_SHAREDWITHME_COURSES_START,
  FETCH_SHAREDWITHME_COURSES_SUCCEEDED,
  FETCH_SHAREDWITHME_COURSES_FAILED,
  FETCH_SHAREDBYME_VIDEOS_START,
  FETCH_SHAREDBYME_VIDEOS_SUCCEEDED,
  FETCH_SHAREDBYME_VIDEOS_FAILED,
  FETCH_SHAREDWITHME_VIDEOS_START,
  FETCH_SHAREDWITHME_VIDEOS_SUCCEEDED,
  FETCH_SHAREDWITHME_VIDEOS_FAILED,

  FETCH_COURSES_LOADMORE_START,
  FETCH_COURSES_LOADMORE_SUCCEEDED,
  FETCH_COURSES_LOADMORE_FAILED,
  FETCH_COURSES_LOADMORE_FINISHED,
  FETCH_TUTORIALS_LOADMORE_FINISHED,

  FETCH_ADMIN_TUTORIALS_LOADMORE_START,
  FETCH_ADMIN_TUTORIALS_LOADMORE_SUCCEEDED,
  FETCH_ADMIN_TUTORIALS_LOADMORE_FAILED,
  FETCH_ADMIN_TUTORIALS_LOADMORE_FINISHED,
  RESET_TUTORIALS_PAGE_NUMBER,

  FETCH_ADMIN_COURSES_LOADMORE_START,
  FETCH_ADMIN_COURSES_LOADMORE_SUCCEEDED,
  FETCH_ADMIN_COURSES_LOADMORE_FAILED,
  FETCH_ADMIN_COURSES_LOADMORE_FINISHED,
  RESET_COLLECTION_PAGE_NUMBER,

  FETCH_QUIZ_START,
  FETCH_QUIZ_SUCCEEDED,
  FETCH_QUIZ_FAILED,

  FETCH_QUESTION_LEVELS_START,
  FETCH_QUESTION_LEVELS_SUCCEEDED,
  FETCH_QUESTION_LEVELS_FAILED,

  FETCH_QUESTION_START,
  FETCH_QUESTION_SUCCEEDED,
  FETCH_QUESTION_FAILED,

  FETCH_QUESTION_LOADMORE_START,
  FETCH_QUESTION_LOADMORE_SUCCEEDED,
  FETCH_QUESTION_LOADMORE_FAILED,
  FETCH_QUESTION_LOADMORE_FINISHED,

  FETCH_QUIZ_LOADMORE_START,
  FETCH_QUIZ_LOADMORE_SUCCEEDED,
  FETCH_QUIZ_LOADMORE_FAILED,
  FETCH_QUIZ_LOADMORE_FINISHED,

  FETCH_USERS_LOADMORE_START,
  FETCH_USERS_LOADMORE_SUCCEEDED,
  FETCH_USERS_LOADMORE_FAILED,
  FETCH_USERS_LOADMORE_FINISHED,

  FETCH_SAVED_LOADMORE_TUTORIALS_START,
  FETCH_SAVED_LOADMORE_TUTORIALS_SUCCEEDED,
  FETCH_SAVED_LOADMORE_TUTORIALS_FAILED,
  FETCH_SAVED_LOADMORE_TUTORIALS_FINISHED,

  FETCH_SAVED_COURSES_LOADMORE_START,
  FETCH_SAVED_COURSES_LOADMORE_SUCCEEDED,
  FETCH_SAVED_COURSES_LOADMORE_FAILED,
  FETCH_SAVED_COURSES_LOADMORE_FINISHED,

  FETCH_SHAREDBYME_VIDEOS_LOADMORE_START,
  FETCH_SHAREDBYME_VIDEOS_LOADMORE_SUCCEEDED,
  FETCH_SHAREDBYME_VIDEOS_LOADMORE_FAILED,
  FETCH_SHAREDBYME_VIDEOS_LOADMORE_FINISHED,

  FETCH_SHAREDBYME_COURSES_LOADMORE_START,
  FETCH_SHAREDBYME_COURSES_LOADMORE_SUCCEEDED,
  FETCH_SHAREDBYME_COURSES_LOADMORE_FAILED,
  FETCH_SHAREDBYME_COURSES_LOADMORE_FINISHED,

  FETCH_SHAREDWITHME_VIDEOS_LOADMORE_START,
  FETCH_SHAREDWITHME_VIDEOS_LOADMORE_SUCCEEDED,
  FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FAILED,
  FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FINISHED,

  FETCH_SHAREDWITHME_COURSES_LOADMORE_START,
  FETCH_SHAREDWITHME_COURSES_LOADMORE_SUCCEEDED,
  FETCH_SHAREDWITHME_COURSES_LOADMORE_FAILED,
  FETCH_SHAREDWITHME_COURSES_LOADMORE_FINISHED,


  FETCH_ALL_GROUPS_LOADMORE_START,
  FETCH_ALL_GROUPS_LOADMORE_SUCCEEDED,
  FETCH_ALL_GROUPS_LOADMORE_FAILED,
  FETCH_ALL_GROUPS_LOADMORE_FINISHED,

  FETCH_ADMIN_SETTINGS_START,
  FETCH_ADMIN_SETTINGS_SUCCEEDED,
  FETCH_ADMIN_SETTINGS_FAILED,

  SIGNOUT_USER
};
