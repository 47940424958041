import { takeEvery } from "redux-saga/effects";
import {
  fetchTutorialsSaga,
  fetchTutorialsLoadMoreSaga,
  fetchTutorialsDetailSaga,
  filterTutorialsSaga,
  sortTutorialsSaga,
  searchTutorialsSaga,
  fetchSavedTutorialsSaga,
  fetchSharedWithMeVideosSaga,
  fetchSharedByMeVideosSaga,
  fetchSavedVideosLoadMoreSaga,
  fetchSharedBymeVideosLoadMoreSaga,
  fetchSharedWithmeVideosLoadMoreSaga,
  resetVideoTutorialSaga
} from "./tutorialsSagas";

import {
  postTutorialVideoSaga,
  videoUploadCheckSaga,
  postTutorialDocumentSaga,
  updateTutorialVideoSaga,
  fetchAdminVideoSaga,
  updateDocumentSaga,
  fetchAdminCourseSaga,
  roleSagas,
  fetchAdminTutorialLoadMoreSaga,
  fetchAdminCourseLoadMoreSaga,
  CollectionTypeSaga,
  videoUploadtrueSaga,
  fetchQuizeSaga,
  fetchQuizLoadMoreSaga,
  fetchTenantDetails,
  fetchTenantDetailsSaga,
  fetchAllGroupSaga,
  fetchAllGroupLoadMoreSaga,
  fetchAdminSettingsSaga,
  fetchQuestionLevelSaga,
  fetchQuestionsLoadmoreSaga,
  fetchQuestionsSaga,
  fetchCoursesAdminSaga
} from "../../modules/Admin/store/sagas/admin";

import {
  fetchCoursesSaga,
  fetchCoursesDetailSaga,
  fetchSavedCoursesSaga,
  filterCoursesSaga,
  sortCoursesSaga,
  fetchInprogressCoursesSaga,
  fetchCompletedCoursesSaga,
  fetchCertificatesSaga,
  fetchBadgesSaga,
  fetchSharedByMeCoursesSaga,
  fetchSharedWithMeCoursesSaga,
  fetchCoursesLoadMoreSaga,
  fetchSavedCourseLoadMoreSaga,
  fetchSharedByMeCourseLoadMoreSaga,
  fetchSharedWithMeCourseLoadMoreSaga,
  fetchCourseReportSaga
} from "./coursesSagas";

import {
  getUserDetailsSaga
} from "./userSagas";

import actions from "../actions/actionTypes";
import { getprofileDataSaga } from "./profileDataSaga";
import { AllUserSaga, loadMoreUsersSaga } from "./AllUserSagas";
import { fetchCategoriesSaga, fetchPlaylistCategoriesSaga } from "./CategorySaga";
import { handleBlueBarCourseSaga, handleBlueBarSaga } from "./BlueBarSaga";
import { videoUploadChecktrue } from "../../modules/Admin/store/actions/admin";
import { fetchOrganisationDetailSaga } from "./OrganisationSaga";

export function* rootSaga() {
  yield takeEvery(actions.FETCH_TUTORIALS_START, fetchTutorialsSaga);
  yield takeEvery(actions.FETCH_SAVED_TUTORIALS_START, fetchSavedTutorialsSaga);

  yield takeEvery(
    actions.FETCH_TUTORIALS_LOADMORE_START,
    fetchTutorialsLoadMoreSaga
  );
  yield takeEvery(
    actions.FETCH_TUTORIALS_DETAIL_START,
    fetchTutorialsDetailSaga
  );
  yield takeEvery(actions.FILTER_TUTORIALS_START, filterTutorialsSaga);
  yield takeEvery(actions.SORT_TUTORIALS_START, sortTutorialsSaga);
  yield takeEvery(actions.SEARCH_TUTORIALS_START, searchTutorialsSaga);
  yield takeEvery(actions.FETCH_COURSES_START, fetchCoursesSaga);
  yield takeEvery(actions.FETCH_SAVED_COURSES_START, fetchSavedCoursesSaga);
  yield takeEvery(actions.FETCH_COURSES_DETAIL_START, fetchCoursesDetailSaga);
  yield takeEvery(actions.FETCH_COURSES_ADMIN_START, fetchCoursesAdminSaga);
  yield takeEvery(actions.FILTER_COURSES_START, filterCoursesSaga);
  yield takeEvery(actions.SORT_COURSES_START, sortCoursesSaga);

  yield takeEvery(actions.POST_TUTORIAL_VIDEO_START, postTutorialVideoSaga);
  yield takeEvery(actions.VIDEO_UPLOAD_CHECK, videoUploadCheckSaga);
  yield takeEvery(actions.VIDEO_UPLOADED_CHECK, videoUploadtrueSaga);


  yield takeEvery(
    actions.CREATE_TUTORIAL_DOCUMENT_START,
    postTutorialDocumentSaga
  );

  yield takeEvery(actions.UPDATE_TUTORIAL_VIDEO_START, updateTutorialVideoSaga);

  yield takeEvery(actions.UPDATE_DOCUMENT_VIDEO_START, updateDocumentSaga);


  yield takeEvery(actions.FETCH_ADMIN_VIDEO_START, fetchAdminVideoSaga);
  yield takeEvery(actions.FETCH_ADMIN_COURSE_START, fetchAdminCourseSaga);
  yield takeEvery(actions.GET_USER_DETAILS_START, getUserDetailsSaga);

  yield takeEvery(actions.GET_PROFILE_DATA_START, getprofileDataSaga);

  yield takeEvery(actions.FETCH_CATEGORY_START, fetchCategoriesSaga);
  yield takeEvery(actions.FETCH_PLAYLIST_CATEGORY_START, fetchPlaylistCategoriesSaga);
  yield takeEvery(actions.FETCH_INPROGRESS_COURSES_START, fetchInprogressCoursesSaga);
  yield takeEvery(actions.FETCH_COMPLETED_COURSES_START, fetchCompletedCoursesSaga);
  yield takeEvery(actions.FETCH_CERTIFICATES_START, fetchCertificatesSaga);
  yield takeEvery(actions.FETCH_BADGES_START, fetchBadgesSaga);
  yield takeEvery(actions.FETCH_ALL_USERS_START, AllUserSaga);
  yield takeEvery(actions.FETCH_ROLES_START, roleSagas);
  yield takeEvery(actions.FETCH_SHAREDBYME_COURSES_START, fetchSharedByMeCoursesSaga);
  yield takeEvery(actions.FETCH_SHAREDWITHME_COURSES_START, fetchSharedWithMeCoursesSaga);
  yield takeEvery(actions.FETCH_SHAREDBYME_VIDEOS_START, fetchSharedByMeVideosSaga);
  yield takeEvery(actions.FETCH_SHAREDWITHME_VIDEOS_START, fetchSharedWithMeVideosSaga);
  yield takeEvery(actions.FETCH_COURSES_LOADMORE_START, fetchCoursesLoadMoreSaga);
  yield takeEvery(actions.FETCH_SAVED_LOADMORE_TUTORIALS_START, fetchSavedVideosLoadMoreSaga);
  yield takeEvery(actions.FETCH_SAVED_COURSES_LOADMORE_START, fetchSavedCourseLoadMoreSaga);
  yield takeEvery(actions.FETCH_SHAREDBYME_VIDEOS_LOADMORE_START, fetchSharedBymeVideosLoadMoreSaga);
  yield takeEvery(actions.FETCH_SHAREDBYME_COURSES_LOADMORE_START, fetchSharedByMeCourseLoadMoreSaga);
  yield takeEvery(actions.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_START, fetchSharedWithmeVideosLoadMoreSaga);
  yield takeEvery(actions.FETCH_SHAREDWITHME_COURSES_LOADMORE_START, fetchSharedWithMeCourseLoadMoreSaga);
  yield takeEvery(actions.FETCH_ADMIN_TUTORIALS_LOADMORE_START, fetchAdminTutorialLoadMoreSaga);
  yield takeEvery(actions.FETCH_ADMIN_COURSES_LOADMORE_START, fetchAdminCourseLoadMoreSaga);
  yield takeEvery(actions.FETCH_USERS_LOADMORE_START, loadMoreUsersSaga);
  yield takeEvery(actions.RESET_TUTORIALS_DETAILS_START, resetVideoTutorialSaga);
  yield takeEvery(actions.FETCH_COURSE_REPORT_START, fetchCourseReportSaga);

  yield takeEvery(actions.FETCH_ORGANISATION_DETAILS_START, fetchOrganisationDetailSaga);


  yield takeEvery(actions.HANDLE_BLUEBAR_START, handleBlueBarSaga);
  yield takeEvery(actions.HANDLE_BLUEBAR_COURSE_START, handleBlueBarCourseSaga);
  yield takeEvery(actions.FETCH_QUIZ_START, fetchQuizeSaga);
  yield takeEvery(actions.FETCH_QUESTION_LEVELS_START, fetchQuestionLevelSaga);
  yield takeEvery(actions.FETCH_QUIZ_LOADMORE_START, fetchQuizLoadMoreSaga);

  yield takeEvery(actions.FETCH_QUESTION_LOADMORE_START, fetchQuestionsLoadmoreSaga);
  yield takeEvery(actions.FETCH_QUESTION_START, fetchQuestionsSaga);



  yield takeEvery(actions.FETCH_TENANT_DETAILS_START, fetchTenantDetailsSaga);

  yield takeEvery(actions.FETCH_ALL_GROUPS_START, fetchAllGroupSaga);
  
  yield takeEvery(actions.FETCH_ALL_GROUPS_LOADMORE_START, fetchAllGroupLoadMoreSaga);
  yield takeEvery(actions.FETCH_ADMIN_SETTINGS_START, fetchAdminSettingsSaga);
}

// yield takeEvery(actions.UPLOAD_REQUEST, postTutorialVideoSaga);
