import actions from "../actions/actionTypes";

let initialState = {
  courses: { Count: 0, Playlist: [] },
  coursesDetail: [],
  savedCourses: {
    Count: 0,
    Playlist: []
  },
  CollectionData: {
    Count: 0,
    Playlist: []
  },
  loaded: false,
  coursesDetailLoad: false,
  coursesAdminLoad:false,
  inprogressCourses: [],
  completedCourses: [],
  certificates: [],
  badges: [],
  sharedbymecourses: {
    Count: 0,
    Playlist: []
  },
  sharedwithmecouses: {
    Count: 0,
    Playlist: []
  },

  courseReports: [],
  courseReportLoad: true,


  lazyloader: true,
  savedCourseLazyLoader: true,
  sharedBymeCourseLazyLoader: true,
  sharedWithMeCourseLazyLoader: true,

  coursePageNumber: 2,
  savedCoursePageNumber: 2,
  sharedByMeCoursePageNumber: 2,
  sharedWithMeCoursePageNumber: 2,

  collectionPageNumber: 2

};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSES_SUCCEEDED: {
      return {
        ...state,
        courses: {
          Count: action.courses == "" ? 0 : action.courses.Count, Playlist: action.courses == "" ? [] : action.courses.Playlist
        },
        loaded: true,
        savedCourseLazyLoader: true,
      };
    }

    case actions.FETCH_SAVED_COURSES_SUCCEEDED: {
      return {
        ...state,
        savedCourses: {
          Count: action.savedCourses == "" ? 0 : action.savedCourses.Count,
          Playlist: action.savedCourses == "" ? [] : action.savedCourses.Playlist
        },
        loaded: true,
      };
    }
    case actions.FETCH_SAVED_COURSES_LOADMORE_SUCCEEDED:
      return {
        ...state,
        savedCourses: {
          Count: action.savedCourses.Count,
          Playlist: [...state.savedCourses.Playlist, ...action.savedCourses.Playlist]
        },
        savedCourseLazyLoader: true,
        loaded: true,
        savedCoursePageNumber: state.savedCoursePageNumber + 1,
      };

    case actions.FETCH_SAVED_COURSES_LOADMORE_FINISHED:
      return {
        ...state,
        savedCourseLazyLoader: false,
      };
    case actions.FETCH_COURSES_DETAIL_START:
      return {
        ...state,
        coursesDetailLoad: false
      };
    case actions.FETCH_COURSES_DETAIL_SUCCEEDED:
      return {
        ...state,
        coursesDetail: action.coursesDetail,
        coursesDetailLoad: true,
      };
    
    case actions.FETCH_COURSES_ADMIN_START:
      return {
        ...state,
        coursesAdminLoad :false
      }
    case actions.FETCH_COURSES_ADMIN_SUCCEEDED:
      return {
        ...state,
        coursesAdmin: action.coursesAdmin,
        coursesAdminLoad:true,
      }

    case actions.FILTER_COURSES_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        courses: action.courses,
      };

    case actions.SORT_COURSES_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        courses: action.courses,
      };

    case actions.FETCH_ADMIN_COURSE_SUCCEEDED:
      return {
        ...state,
        CollectionData: {
          Count: action.CollectionData == "" ? 0 : action.CollectionData.Count,
          Playlist: action.CollectionData == "" ? [] : action.CollectionData.Playlist
        },
      };

    case actions.FETCH_INPROGRESS_COURSES_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        inprogressCourses: action.inprogressCourses,
      };

    case actions.FETCH_COMPLETED_COURSES_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        completedCourses: action.completedCourses,
      };

    case actions.FETCH_CERTIFICATES_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        certificates: action.certificates,
      };

    case actions.FETCH_BADGES_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        badges: action.badges,
      };

    case actions.FETCH_SHAREDBYME_COURSES_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        sharedbymecourses: {
          Count: action.sharedbymecourses == "" ? 0 : action.sharedbymecourses.Count,
          Playlist: action.sharedbymecourses == "" ? [] : action.sharedbymecourses.Playlist
        },
      };

    case actions.FETCH_SHAREDBYME_COURSES_LOADMORE_SUCCEEDED:
      return {
        ...state,
        sharedBymeCourseLazyLoader: true,
        loaded: true,
        sharedByMeCoursePageNumber: state.sharedByMeCoursePageNumber + 1,
        sharedbymecourses: {
          Count: action.sharedbymecourses.Count,
          Playlist: [...state.sharedbymecourses.Playlist, ...action.sharedbymecourses.Playlist]
        },
      };

    case actions.FETCH_SHAREDBYME_COURSES_LOADMORE_FINISHED:
      return {
        ...state,
        sharedBymeCourseLazyLoader: false,
      };
    case actions.FETCH_SHAREDWITHME_COURSES_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        sharedwithmecouses: {
          Count: action.sharedwithmecouses == "" ? 0 : action.sharedwithmecouses.Count,
          Playlist: action.sharedwithmecouses == "" ? [] : action.sharedwithmecouses.Playlist
        },
      };

    case actions.FETCH_SHAREDWITHME_COURSES_LOADMORE_SUCCEEDED:
      return {
        ...state,
        sharedWithMeCourseLazyLoader: true,
        loaded: true,
        sharedWithMeCoursePageNumber: state.sharedWithMeCoursePageNumber + 1,
        sharedwithmecouses: {
          Count: action.sharedwithmecouses.Count,
          Playlist: [...state.sharedwithmecouses.Playlist, ...action.sharedwithmecouses.Playlist]
        },
      };

    case actions.FETCH_SHAREDWITHME_COURSES_LOADMORE_FINISHED:
      return {
        ...state,
        sharedWithMeCourseLazyLoader: false,
      };

    case actions.FETCH_COURSES_LOADMORE_SUCCEEDED:
      return {
        ...state,
        courses: {
          Count: action.courses == "" ? 0 : action.courses.Count,
          Playlist: [...state.courses.Playlist, ...action.courses.Playlist]
        },
        lazyloader: true,
        coursePageNumber: state.coursePageNumber + 1
      };

    case actions.FETCH_COURSES_LOADMORE_FINISHED:
      return {
        ...state,
        lazyloader: action.lazyloaderl,
      };
    case actions.FETCH_ADMIN_COURSES_LOADMORE_START:
      return {
        ...state,
        loaded: true,
      };
    case actions.FETCH_ADMIN_COURSES_LOADMORE_SUCCEEDED:
      return {
        ...state,
        CollectionData: {
          Count: action.CollectionData.Count,
          Playlist: [...state.CollectionData.Playlist, ...action.CollectionData.Playlist]
        },
        lazyloader: true,
        collectionPageNumber: state.collectionPageNumber + 1
      };
    case actions.FETCH_ADMIN_COURSES_LOADMORE_FAILED:
      return {
        ...state,
        error: action.payload,
        loaded: false,
      };
    case actions.FETCH_ADMIN_COURSES_LOADMORE_FINISHED:
      return {
        ...state,
        lazyloader: action.lazyloader,
      };
    case actions.FETCH_COURSE_REPORT_SUCCEEDED:
      return {
        ...state,
        courseReports: action.payload,
        courseReportLoad: true,
      };
    case actions.RESET_COLLECTION_PAGE_NUMBER:
      return {
        ...state,
        collectionPageNumber: 2,
        coursePageNumber: 2,
      };
    default:
      return state;
  }
};
