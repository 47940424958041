import React from 'react'
import Datagriddata from './Datagriddata'
import SearchFilter from './SearchFilter';

export default function CourseDatagriddata(props) {
    const [selections, setSelections] = React.useState([]);
    const [selectedVideos, setSelectedVideos] = React.useState([]);
    const [filterData, setFilterData] = React.useState({
        Count: 0,
        Data: [],
    });

    React.useEffect(() => {
        setSelectedVideos([])
    }, [window.location.pathname.split('/').pop()])

    const handleSelection = (event) => {
        console.log(event)
        setSelections(event);
    };

    const handleFilterCollection = (event) => {
        console.log(event)
        setFilterData({
            Count: event.Count,
            Data: event.Data,
        });
    };

    const handleSelectedVideos = (event) => {
        setSelectedVideos(event);
    };

    return (
        <>
            <SearchFilter selection={selections} resetSelection={() => setSelections([])}  filterData={handleFilterCollection} />
            <Datagriddata selection={handleSelection} collectionType={"course"} selectedVideos={handleSelectedVideos} filterData={filterData} />
        </>
    )
}

