import React, { useState, forwardRef, useEffect } from 'react';
import { FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchAdminCourse } from '../store/actions/admin';
import { validationLimits } from './Constants';

const tagInputStyle = {width: '100% !important', height: '47px'};

const CourseCreation = forwardRef(({courseDetails, updateCourseDetails, playlistCategories, formFilled, setFormFilled}, ref) => {
  const [courseNameError, setCourseNameError] = useState(false);
  const [courseDescriptionError, setCourseDescriptionError] = useState(false);
  const [courseCategoryError, setCourseCategoryError] = useState(false);
  const [thumbnailError, setThumbnailError] = useState(false);
  const thumbnailFile = React.useRef(null);
  const [isCompChanged,setChangeOnComp] = useState(false);

  const isValidCourseName = courseDetails?.courseName.trim().length > 0 && courseDetails?.courseName.trim().length <= validationLimits.courseNameMax;
  const isValidCourseDescription = courseDetails?.courseDescription?.trim().length > 0 && courseDetails?.courseDescription?.trim().length <= validationLimits.courseDescriptionMax;
  const isValidCategory = courseDetails?.selectedCategory?.Id !== null;
  const isValidThumbnail = !thumbnailError && courseDetails?.thumbnail?.length > 0;


  const handleCourseNameChange = (e) => {
    updateCourseDetails('courseName', e.target.value);
  };

  const handleDescriptionChange = (e) => {
    updateCourseDetails('courseDescription', e.target.value);
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = playlistCategories.find((cat) => cat.Id === e.target.value);
    updateCourseDetails('selectedCategory', selectedCategory);
  };

  const handleTagsChange = (e) => {
    updateCourseDetails('tags', e.target.value);
  };

  const handleThumbnail = (files) => {
    if (files && files[0]) {
      const file = files[0];
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const isValidFile =
        allowedTypes.includes(file.type) &&
        /\.(png|jpg|jpeg)$/.test(file.name.toLowerCase());

      if (isValidFile) {
        const reader = new FileReader();
        reader.onload = (e) => updateCourseDetails('thumbnailPreview', e.target.result);
        reader.readAsDataURL(file);
        updateCourseDetails('thumbnail', file);
      } else {
        updateCourseDetails('thumbnailPreview', '');
        updateCourseDetails('thumbnail', null);
      }

      updateCourseDetails('thumbnailError', !isValidFile);
    }
  };
  
  useEffect(() => {
    const formValid =
    courseDetails?.courseName.trim().length > 0 && courseDetails?.courseName.trim().length <= validationLimits.courseNameMax &&
    courseDetails?.courseDescription.trim().length > 0 && courseDetails?.courseDescription.trim().length <= validationLimits.sessionDescriptionMax &&
    courseDetails?.selectedCategory?.Id != null && !thumbnailError && courseDetails?.thumbnailPreview.length > 0;

      formFilled(formValid);
      if (setFormFilled) {
        setFormFilled(formValid);
  }
}, [courseDetails]);


  const handleThumbnailClick = () => {
    thumbnailFile.current.click();
    setChangeOnComp(true)
  };

  useEffect(() => {
    if (isValidCourseName) {
      setCourseNameError(false);
    }
    if (isValidCourseDescription) {
      setCourseDescriptionError(false);
    }
    if (isValidCategory) {
      setCourseCategoryError(false);
    }
    if (isValidThumbnail) {
      setThumbnailError(false);
    }
}, [isValidCourseName, isValidCourseDescription, isValidCategory, isValidThumbnail]);

  return (
    <div className='studio-course-creation'>
      <div className="form-body">
          <FormControl sx={{ width: '100%' }} className="form-control m-0">
            <h4>Course Title</h4>
            <input
              type='text'
              placeholder='Title of your Course goes here'
              value={courseDetails?.courseName || ''}
              onChange={handleCourseNameChange}
              maxLength={validationLimits.courseNameMax}
              error={courseNameError}
            />
            {courseNameError && (
              <FormHelperText sx={{ fontSize: 12, color: 'red', margin: 0 }}>
                Course Name should be between 1-50 characters
              </FormHelperText>
            )}
          </FormControl>

          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/png, image/jpeg, image/jpg"
            ref={thumbnailFile}
            onChange={(e) => handleThumbnail(e.target.files)}
          />

          {courseDetails?.thumbnailPreview.length === 0 ? (
            <FormControl sx={{ width: '100%' }} className="form-control mb-4">
              <div className="webinar-upload-part-parent">
                <div className="video-upload thumb-upload webinar-upload-part" onClick={handleThumbnailClick}>
                  <svg width="36" height="36" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="64" height="64" rx="17.6" className="svg-for-menu"></rect>
                    <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white"></rect>
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z" className="svg-for-menu"></path>
                  </svg>
                  <h4>Upload thumbnail image (Only PNG/JPEG)</h4>
                </div>
                {thumbnailError && (
                  <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                    Upload only PNG/JPEG format
                  </FormHelperText>
                )}
              </div>
            </FormControl>
          ) : (
            <div className="replace-image-con">
              <img src={courseDetails?.thumbnailPreview} className="thumbnail" alt="Thumbnail preview" />
              <button onClick={handleThumbnailClick}>Replace image</button>
              {thumbnailError && (
                <FormHelperText sx={{ fontSize: 10, color: 'red', position: 'absolute', top: '107px' }}>
                  Upload only PNG/JPEG format
                </FormHelperText>
              )}
            </div>
          )}

          <div className='d-flex align-items-center gap-2 mb-5 mt-4'>
          <FormControl  className="admin-course-select-option select-cat-upload" sx={{ width: '100%' }}>
            <h4>Category</h4>
            <Select
              labelId="select-category-label"
              id="select-category"
              value={courseDetails?.selectedCategory?.Id || ''}
              onChange={handleCategoryChange}
              displayEmpty
              error={courseCategoryError}
              inputProps={{ 'aria-label': 'Without label' }}
              renderValue={(selected) => {
                if (!selected) {
                return <span style={{opacity:"0.7", color: 'black'}}>Select Category</span>;
                }
                return playlistCategories.find((cat) => cat.Id === selected).PlaylistCategoryName;
              }}
            >
              <MenuItem value="" disabled>Select Category</MenuItem>
              {playlistCategories.map((category) => (
                <MenuItem key={category.Id} value={category.Id}>
                  {category.PlaylistCategoryName}
                </MenuItem>
              ))}
            </Select>
            {courseCategoryError && (
              <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                Please select a Course Category
              </FormHelperText>
            )}
          </FormControl>
          <FormControl  className="admin-course-select-option select-cat-upload" sx={{ width: '100%' }}>
          <h4>Tag</h4>
          <input 
            placeholder='Add Tags' 
            type = "text"
            value={courseDetails?.tags || ''}
            className='tags-input points-input px-4'
            style={tagInputStyle}
            onChange={(e) => handleTagsChange(e)}
            maxLength={25}
            />
            </FormControl>
          </div>


          <FormControl sx={{ width: '100%' }} className="form-control mb-4">
            <h4>About This Course</h4>
            <textarea
              id="course-description"
              value={courseDetails?.courseDescription || ''}
              rows="12"
              cols="50"
              maxLength="1000"
              placeholder="Add Course Description (Max 1000 characters)"
              onChange={handleDescriptionChange}
            />
            {courseDescriptionError && (
              <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                Description cannot exceed 1000 characters
              </FormHelperText>
            )}
          </FormControl>
        </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  playlistCategories: state.category.playlistCategories,
  courses: state.courses.CollectionData.Playlist,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAdminCourseAction: fetchAdminCourse,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CourseCreation);
