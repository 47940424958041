import * as React from "react";
import { connect } from "react-redux";
import { FormHelperText } from '@mui/material';
import Selectcategory from "./Selectcategory";
import Draganddrop from "./Draganddrop";
import Uploadwithsas from "../Common/Uploadwithsas";
import API from "../utils/API";
import {Backdrop,LinearProgress} from "@mui/material";
import LoadingScreen from "../../../components/Common/LoadingScreen";
import { updateDocumentVideo, updateTutorialVideo } from "../store/actions/admin";
import { documentPath, tempVideoPath, thumbnailPath, uploadedVideoStateUploadedToTemp, validationLimits, errorTitles } from "../Common/Constants";
import UploadVideowithsas from "../Common/UploadVideoWithSas";
import '../../../css/CollectionTableCss.css'

class VerticalTimeline extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      document: {},
      title: "",
      description: "",
      transcript: "",
      titleError: {},
      descriptionError: {},
      documentError: "",
      transcriptError: "",
      categoryId: null,
      categoryIdError: "",
      videoUploadResponse: {
        status: false,
        message: '',
        alertType: ''
      },
      thumbnailUploadResponse: {
        status: false,
        message: '',
        alertType: ''
      },
      documentUploadResponse: {
        status: false,
        message: '',
        alertType: ''
      },
      isInputFocus: false,
      isLoadingScreenActive: false,
      thumbnailPreview: '',
      thumbnailError: false,
      thumbnail: null,
      thumbnails: {}
    };
    this.thumbnailFile = React.createRef();
  }


  componentDidUpdate() {
    this.validate();
  }



  // validate = () => {
  //   let titleError = "";
  //   let descriptionError = "";
  //   let categoryIdError = "";
  //   let thumbnailError = "";

  //   if (this.state.title.length === 0) {
  //     titleError = errorTitles.titleEmptyError;
  //   } else if (this.state.title.length > validationLimits.videoTitleMax) {
  //     titleError = errorTitles.titleExceedError;
  //   }

  //   if (this.state.description.length === 0) {
  //     descriptionError = errorTitles.descriptionError;
  //   } else if (this.state.description.length > validationLimits.videoDescriptionMax) {
  //     descriptionError = errorTitles.descriptionExceedError;
  //   }

  //   if (!this.state.categoryId || this.state.categoryId === 'null') {
  //     categoryIdError = "Select any category";
  //   }

  //   if (!this.state.thumbnail || this.state.thumbnail.length === 0) {
  //     thumbnailError = "Thumbnail is required";
  //   }

  //   if (titleError || descriptionError || categoryIdError || thumbnailError) {
  //     this.setState({ titleError, descriptionError, categoryIdError, thumbnailError });
  //     return false;
  //   }

  //   return true;
  // };

  validate = () => {
    if (!this.props.files.length) {
      return false;
    }
    
    let validated = true;

    for(let i = 0; i < this.props.files.length; i ++) {
      const d = this.props.files[i];
      if(!d.title?.trim() || !d.description?.trim() || !d.categoryId) {
        validated =  false;
        break;
      }
      if (d.title.length > validationLimits.videoTitleMax || d.description.length > validationLimits.videoDescriptionMax) {
        validated = false;
        break;
      }
      if (!this.state.thumbnails[i]?.thumbnail || this.state.thumbnails[i].thumbnailError) {
        validated = false;
        break;
      }
    }

    return validated;
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });

    const _files = [...this.props.files];
    const _file = _files[this.props.selectedVideoIndex];
    _file[e.target.name] = e.target.value;
    this.props.setGetFiles(_files);

    if (e.target.name === "title") {
      const titleError = e.target.value?.trim()?.length > validationLimits.videoTitleMax
        ? errorTitles.titleExceedError
        : "";
        const te = {...this.titleError};
        te[this.props.selectedVideoIndex] = titleError;
        this.setState({ titleError:  te });
    }

    if (e.target.name == "description") {
      let descriptionError = ""
      if (e.target.value?.trim()?.length > validationLimits.videoDescriptionMax) {
        descriptionError = errorTitles.descriptionExceedError;
      }
      const de = { ...this.descriptionError }
      de[this.props.selectedVideoIndex] = descriptionError;
      this.setState({ descriptionError: de });
    }
  };

  handleInpActive = () => {
    this.setState({ isInputFocus: true });
  }

  handleCategoryId = (e) => {
    this.setState({
      categoryId: e,
    });

    const _files = [...this.props.files];
    const _file = _files[this.props.selectedVideoIndex];
    _file["categoryId"] = e;
    this.props.setGetFiles(_files);
  };

  handleVideoPoints = (e) => {
    const _files = [...this.props.files];
    const _file = _files[this.props.selectedVideoIndex];
    _file["points"] = e;
    this.props.setGetFiles(_files);
  }

  document = (e) => {
    this.setState({
      document: e
    })
  }

  handleDocumentError = (e) => {
    this.setState({
      documentError: e
    })
  }

  handleThumbnail = (event) => {
    if (!event.target.value) {
      return;
    }

    const file = event.target.files[0];
    const fileType = file && file.type;
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    if (validTypes.includes(fileType)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const _thumbnails = {...this.state.thumbnails};
        _thumbnails[this.props.selectedVideoIndex] = {
          thumbnailPreview: reader.result,
          thumbnailError: false,
          thumbnail: file,
        }
        this.setState({thumbnails: _thumbnails});
        this.thumbnailFile.current.value = "";
      };
      reader.readAsDataURL(file);
    } else {
      const _thumbnails = {...this.state.thumbnails};
      _thumbnails[this.props.selectedVideoIndex] = {
        thumbnailError: true,
        thumbnailPreview: '',
        thumbnail: null,
      }
      this.setState({ thumbnails: _thumbnails });
      this.thumbnailFile.current.value = "";
    }
  };


  handleThumbnailClick = () => {
    this.thumbnailFile.current.click();
  };

  handleUploadClick = async () => {
    const {
      updateTutorialVideoAction,
      updateDocumentVideoAction,
      VideoData,
      DocumentData,
      DocumentName,
      DocumentExtension,
      VideoName,
      VideoExtension,
      files,
      videolength
    } = this.props;

    const { documentResponce, updatedDocumentData, uploadedFiles } = this.state.document;

    const isValid = this.validate();
    if (isValid) {
      this.setState({ isLoadingScreenActive: true, uploadProgress: -1  });

      const UpdateVideos = [];
      this.props.files.forEach((d, i) => {
        UpdateVideos.push({
            Id: d.VideoId,
            Name: d.name.substring(0, d.name.lastIndexOf('.')) || d.name,
            Title: d.title,
            FileId: d.FileId,
            CategoryId: d.categoryId,
            IsActive: true,
            FileName: d.name.substring(0, d.name.lastIndexOf('.')) || d.name,
            Extension: "m3u8",
            Points:d.points,
            Description: d.description,
            Transcript: d.transcript,
            VideoLength: d.videoLength,
            ThumbnailGeneratedFileName: this.state.thumbnails[i].thumbnail ? `${d.FileId}.${this.state.thumbnails[i].thumbnail.name.split('.').pop().toLowerCase()}` : null
          })
      })

      // updateTutorialVideoAction({
      //   UpdateVideos:[
      //     {
      //       Id: this.props.VideoData[0].VideoId,
      //       Name: this.props.VideoName,
      //       Title: this.state.title,
      //       FileId: this.props.VideoData[0].FileId,
      //       CategoryId: this.state.categoryId,
      //       IsActive: true,
      //       FileName: this.props.VideoName,
      //       Extension: "m3u8",
      //       Description: this.state.description,
      //       Transcript: this.state.transcript,
      //       VideoLength: this.props.videolength,
      //       ThumbnailGeneratedFileName: this.state.thumbnail ? `${this.props.VideoData[0].FileId}.${this.state.thumbnail.name.split('.').pop().toLowerCase()}` : null
      //     }
      //   ]
      // });
      updateTutorialVideoAction({UpdateVideos});

      try {
        const uploadPromises = [];

        const updateProgress = (progress) => {
          this.setState({ uploadProgress: Math.round(progress) });
        };
        updateProgress(0);
        // const videoUpload = UploadVideowithsas(
        //   files,
        //   this.props.VideoData[0].VideoId,
        //   this.props.VideoData[0].FileId,
        //   this.props.VideoData[0].VideoSasToken,'',
        //   (progress) => {
        //     console.log(`Upload Progress: ${progress.toFixed(2)}%`);
        //     updateProgress(progress);
        //   }
        // ).then(res => {
        //   if (res.status === 200) {
        //     setTimeout(() => {
        //       this.props.setAlert({
        //         active: true,
        //         severity: 'success',
        //         message: 'Video added to queue',
        //       });
        //     }, 3000);

        //     this.props.videoUploaded({
        //       status: true,
        //       alertType: 'success',
        //       FileId: this.props.VideoData.FileId,
        //     });
        //   }
        // });
        this.props.files.forEach((d, i) => {
            const videoUpload = UploadVideowithsas(
            d,
            d.VideoId,
            d.FileId,
            d.VideoSasToken,'',
            (progress) => {
              console.log(`Upload Progress: ${progress.toFixed(2)}%`);
              updateProgress(progress);
            }
          ).then(res => {
            if (res.status === 200) {
              setTimeout(() => {
                this.props.setAlert({
                  active: true,
                  severity: 'success',
                  message: 'Video added to queue',
                });
              }, 3000);

              this.props.videoUploaded({
                status: true,
                alertType: 'success',
                FileId: this.props.VideoData.FileId,
              });
            }
          });
          uploadPromises.push(videoUpload)

          const thumbnailUpload = Uploadwithsas(
            this.state.thumbnails[i].thumbnail,
            d.FileId,
            d.ThumbnailSasToken,
            thumbnailPath
          ).then(res => {
            if (res.status !== 201) {
              this.props.videoUploaded({
                status: false,
                alertType: 'error',
                FileId: this.props.VideoData[0].FileId,
              });
            }
          });
          uploadPromises.push(thumbnailUpload);

        });

        // if (this.state.thumbnail && this.state.thumbnail instanceof File) {
        //   const thumbnailUpload = Uploadwithsas(
        //     this.state.thumbnail,
        //     this.props.VideoData[0].FileId,
        //     this.props.VideoData[0].ThumbnailSasToken,
        //     thumbnailPath
        //   ).then(res => {
        //     if (res.status !== 201) {
        //       this.props.videoUploaded({
        //         status: false,
        //         alertType: 'error',
        //         FileId: this.props.VideoData[0].FileId,
        //       });
        //     }
        //   });
        //   uploadPromises.push(thumbnailUpload);
        // }

        if (uploadedFiles && uploadedFiles.length > 0) {
          const documentUploads = documentResponce.map((doc, index) =>
            Uploadwithsas(
              uploadedFiles[index],
              doc.FileId,
              doc.DocumentSasToken,
              documentPath
            )
          );
          uploadPromises.push(...documentUploads);
          uploadPromises.push(API.updateDocument(updatedDocumentData));
        }
        await Promise.all(uploadPromises);

        const updateVideoData = [];
        this.props.files.forEach(d => {
          updateVideoData.push({
            Id: d.VideoId,
            state: uploadedVideoStateUploadedToTemp
          });
        })
        // await API.updateVideoData({
        //   updateVideoData:[
        //     {
        //       Id:this.props.VideoData[0].VideoId,
        //       state:uploadedVideoStateUploadedToTemp
        //     }
        //   ]
        // });
        await API.updateVideoData({ updateVideoData });

        this.props.setAlert({
          active: true,
          severity: 'success',
          message: 'Video uploaded Successfully.',
        });
        setTimeout(() => {
          this.props.navigate('/collection/videocollection');   /// we need to create a progress page to show realtime progress (navigate to that page)
        }, 3000);
        // this.props.videoUploaded({
        //   status: true,
        //   alertType: 'success',
        //   FileId: VideoData.FileId,
        // });


      } catch (error) {
        this.props.setAlert({
          active: true,
          severity: 'error',
          message: 'Failed to create videos',
        });
        console.error('Upload failed', error);
      } finally {
        this.setState({ isLoadingScreenActive: false });
      }
    } else {
      this.validate();
    }
  };


  render() {
    const { thumbnailPreview, thumbnailError } = this.state;
    const { isVideoUploaded, selectedFile } = this.props;

    return isVideoUploaded ? (
      <div className="m-5">

        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            justifyContent: 'center',
            alignItems: 'center',
            background:"rgba(0, 0, 0, 0.8)",
          }}
          open={this.state.uploadProgress > -1 && this.state.uploadProgress < 100}
        >
          <div className="video-upload-progress">
            <LinearProgress
              variant="determinate"
              value={this.state.uploadProgress}
              sx={{ width: '20vw',height:"0.8vw"}}
            />
            <p>{this.state.uploadProgress}%</p>
          </div>
        </Backdrop>

        <div className="video-desc  lms-flex-between">
          <h4>Video Title *</h4>
        </div>
        <div className={`video-input ${this.state.isInputFocus ? "is-inp-focus" : "is-inp-not-focus"}`}>
          <input
            type="text"
            name="title"
            // value={this.state.title}
            value={this.props.files[this.props.selectedVideoIndex].title || ""}
            onChange={this.handleInputChange}
            onFocus={this.handleInpActive}
          />
        </div>
        <h6 style={{ color: "red" }}>{this.state.titleError[this.props.selectedVideoIndex] || ""}</h6>
        {/* {!this.props.files[this.props.selectedVideoIndex]?.title?.trim()?.length ? <h6 style={{color: 'red'}}>{errorTitles.titleEmptyError}</h6> : ""} */}
        <div className="thumbnail-upload">
          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/png, image/jpeg, image/jpg"
            ref={this.thumbnailFile}
            onChange={this.handleThumbnail}
          />
          {(!this.state.thumbnails[this.props.selectedVideoIndex]?.thumbnailPreview) ? (
            <div className="webinar-upload-part-parent">
              <div
                className="video-upload thumb-upload webinar-upload-part"
                onClick={this.handleThumbnailClick}
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 84 84"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="64" height="64" rx="17.6" className="svg-for-menu" />
                  <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z"
                    className="svg-for-menu"
                  />
                </svg>
                <h4>Upload thumbnail image (Only PNG/JPEG) *</h4>
              </div>
              {(this.state.thumbnails[this.props.selectedVideoIndex]?.thumbnailError //||
                // !this.state.thumbnails[this.props.selectedVideoIndex]?.thumbnail
              ) && (
                <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                  Upload only PNG/JPEG format
                </FormHelperText>
              )}
            </div>
          ) : (
            <div className="replace-image-con">
              <img
                // src={thumbnailPreview}
                src={this.state.thumbnails[this.props.selectedVideoIndex]?.thumbnailPreview}
                className="thumbnail"
                alt="Thumbnail preview"
              />
              <button onClick={this.handleThumbnailClick}>Replace image</button>
              {thumbnailError && (
                <FormHelperText sx={{ fontSize: 10, color: 'red', position: 'absolute', top: '107px' }}>
                  Upload only PNG/JPEG format
                </FormHelperText>
              )}
            </div>
          )}
        </div>
        <Selectcategory 
          categoryId={this.props.files[this.props.selectedVideoIndex].categoryId} 
          onSeletecCategoryId={this.handleCategoryId}
          points={this.props.files[this.props.selectedVideoIndex].points}
          videoPoints={this.handleVideoPoints}
        />
        {/* {this.state.categoryId === null && <h6 style={{ color: "red" }}>{this.state.categoryIdError}</h6>} */}
        {/* {!(this.props.files[this.props.selectedVideoIndex]?.categoryId) ? <h6 style={{ color: "red" }}>Select any category</h6> : ""} */}
        
        <h4>Description *</h4>
        <textarea
          id="video-descripation"
          rows="12"
          cols="50"
          form="usrform"
          placeholder="Add Description"
          name="description"
          // value={this.state.description}
          value={this.props.files[this.props.selectedVideoIndex].description || ""}
          onChange={this.handleInputChange}
        ></textarea>
        <h6 style={{ color: "red" }}>{this.state.descriptionError[this.props.selectedVideoIndex] || ""}</h6>
        {/* {!this.props.files[this.props.selectedVideoIndex]?.description?.trim()?.length ? <h6 style={{color: 'red'}}>{errorTitles.descriptionError}</h6> : ""} */}

        <h4 className="m-30">Documents</h4>
        {/* <Draganddrop document={this.document} error={this.handleDocumentError} VideoData={this.props.VideoData[0] || {}} /> */}
        <Draganddrop document={this.document} error={this.handleDocumentError} VideoId={this.props.files[this.props.selectedVideoIndex]?.VideoId} FileId={this.props.files[this.props.selectedVideoIndex]?.FileId} />
        {this.state.documentError.length > 0 && <h6 style={{ color: "red", marginTop: '1vw' }}>{this.state.documentError}</h6>}
        <h4 className="mt-3">Transcript</h4>
        <textarea
          id="video-descripation"
          rows="12"
          cols="50"
          form="usrform"
          placeholder="Add Transcript"
          name="transcript"
          // value={this.state.transcript}
          value={this.props.files[this.props.selectedVideoIndex].transcript || ""}
          onChange={this.handleInputChange}
        ></textarea>

        <div className="fixed-video-btns">
          {/* <button className="back-btn">Back</button> */}
          <div></div>
          <button onClick={this.handleUploadClick} disabled={!this.validate()}>Upload</button>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
}

const mapStateToProps = (state) => {
  const { VideoData, VideoName, DocumentData, VideoExtension, DocumentExtension, DocumentName, isVideoUploaded } =
    state.tutorials;

  return {
    VideoData: VideoData,
    DocumentData: DocumentData,
    VideoName: VideoName,
    VideoExtension: VideoExtension,
    isVideoUploaded: isVideoUploaded,
    DocumentExtension: DocumentExtension,
    DocumentName: DocumentName,
    tenant: state.tenant.tenant
  };
};

const mapDispatchToProps = {
  updateTutorialVideoAction: updateTutorialVideo,
  updateDocumentVideoAction: updateDocumentVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalTimeline);
