import * as React from 'react';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import store from '../../../store/configureStore';
import GenerateProfilePic from '../../../components/Common/GenerateProfilePictureIntitals';
import ErrorTab from '../../../components/Common/ErrorTab';
import { useTranslation } from 'react-i18next';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import '../../../css/QuestionTableLayout.css';

import {
    Box,
    Typography,
    Modal,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    List,
    ListItemButton,
    Divider,
    Collapse,
    FormControlLabel,
    Radio,
    RadioGroup,
    Breadcrumbs,
    Link,
    Tooltip,
    Zoom,
    tooltipClasses,
    styled,
    MenuItem,
    Select,
    Popover,
    FormControl,
    OutlinedInput,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    ListItem,
    InputLabel,
    TextField,
    Backdrop,
    Alert,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { StyledMenu } from "../Video/SearchFilter";
import API from '../utils/API';
import Spinner from '../../../components/Common/Spinner';
import { Sort, multipleChoice, singleChoice } from './Constants';
import { fetchQuestions, fetchQuiz } from '../store/actions/admin';
import { bindActionCreators } from 'redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Tag(props) {
    const { label, onDelete, ...other } = props;
    return (
        <div {...other}>
            <span>{label}</span>
            <CloseIcon onClick={onDelete} />
        </div>
    );
}

Tag.propTypes = {
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

const StyledTag = (props) => <Tag {...props} className="StyledTag" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function QuizTableLayout(props) {
    const { t } = useTranslation();
    const [selectedCollection, setSelectedCollection] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const [IsBreadcrumbsActive, setBreadcrumbsActive] = React.useState(false);
    const [selectedQuiz, setSelectedQuiz] = React.useState({});
    const [dropdownAnchorEl, setDropdownAnchorEl] = React.useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const [openFilter, setFilterOpen] = React.useState(false);
    const [openIndex, setOpenIndex] = React.useState(null);
    const [isCreateQuestionOpen, setCreateQuestionOpen] = React.useState(false);
    const [isEditQuizOpen, setEditQuizOpen] = React.useState(false);
    const [isAssignQuestionOpen, setAssignQuestionOpen] = React.useState(false);


    //questions
    const [selectedQuizQuestions, setSelectedQuizQuestions] = React.useState([]);
    const [questionsLayoutModel, setQuestionsLayoutModel] = React.useState({});
    const [selectedQuestionIdsList, setSelectedQuestionIdsList] = React.useState([]);
    const [selectedQuestionList, setSelectedQuestionList] = React.useState([]);
    const [deleteQuestionDialog, setDeleteQuestionDialog] = React.useState(false);
    const [assignQuestionList, setAssignQuestionList] = React.useState({
        Count: 0,
        Questions: []
    });
    const [assignQuestionPagenumber, setAssignQuestionPageNumber] = React.useState(1);
    const [selectedAssignQuestionList, setSelectedAssignQuestionList] = React.useState([]);

    //Quiz 
    const [quizTitle, setQuizTitle] = React.useState('')
    const [retake, setRetake] = React.useState(0)
    const [retakeError, setRetakeError] = React.useState('');
    const [isRetakeAllowed, setIsRetakeAllowed] = React.useState(false)
    const [totalQuestions, setTotalQuestions] = React.useState(0)
    const [totalScores, setTotalScores] = React.useState(0)
    const [passingPercentage, setPassingPercentage] = React.useState(0)
    const [questionNumber, setQuestionNumber] = React.useState({});
    const [levelPercentage, setLevelPercentage] = React.useState({});
    const [questionPoint, setQuestionPoint] = React.useState({});
    const [levels, setLevels] = React.useState([]);
    const [sortPayload, setSortPayload] = React.useState({
        SortField: '',
        SortType: '',
    });

    const [sortFieldType, setSortFieldType] = React.useState('');
    const [sortFieldDate, setSortFieldDate] = React.useState('');



    //create questions
    const [questionTitle, setQuestionTitle] = React.useState('');
    const [questionType, setQuestionType] = React.useState('');
    const [questionLevel, setQuestionLevel] = React.useState('');
    const [questionTags, setQuestionTags] = React.useState([]);
    const [questionOptions, setQuestionOptions] = React.useState([]);
    const [options, setOptions] = React.useState([])
    const [questionTypeFilter, setQuestionTypeFilter] = React.useState([])
    const [questionLevelFilter, setQuestionLevelFilter] = React.useState([])
    const [searchAssignQuestion, setSearchAssignQuestion] = React.useState('')

    //localloaders
    const [questionListLoader, setQuestionListLoader] = React.useState(false);
    const [modelLoader, setModelLoader] = React.useState(false);

    //errors
    const [questionTitleError, setQuestionTitleError] = React.useState(false);
    const [questionTypeError, setQuestionTypeError] = React.useState(false);
    const [questionOptionsForTypeError, setQuestionOptionsForTypeError] = React.useState(false)
    const [questionlevelError, setQuestionLevelError] = React.useState(false);
    const [questionTagsError, setQuestionTagsError] = React.useState(false);
    const [questionOptionsError, setQuestionOptionsError] = React.useState(false);
    const [singleChoiceError, setSingleChoiceError] = React.useState(false);
    const [multipleChoiceError, setMultipleChoiceError] = React.useState(false);
    const [questionOptionsMaxError, setQuestionOptionsMaxError] = React.useState(false);
    const [questionOptionValueError, setQuestionOptionValueError] = React.useState(false);
    const [quizTitleError, setQuizTitleError] = React.useState(false);
    const [quizTotalQuestionError, setQuizTotalQuestionError] = React.useState(false);
    const [quizLevelConfigError, setQuizLevelConfigError] = React.useState(false);
    const [quizPointError, setQuizPointError] = React.useState(false);
    const [quizLevelEmptyError, setQuizLevelEmptyError] = React.useState(false);
    const [emptyQuestionForQuiz, setEmptyQuestionForQuiz] = React.useState(false);
    const [levelPercentError, setLevelPercentError] = React.useState(false);
    const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
    const [alert, setAlert] = React.useState({
        active: false,
        severity: '',
        message: ''
    });
    const [localLoader, setLocalLoader] = React.useState(false);

    const isDropdownOpen = Boolean(dropdownAnchorEl);
    const isFilterOpen = Boolean(filterAnchorEl);
    const filterPopoverId = isFilterOpen ? 'filter-popover' : undefined;

    const Quiz_Not_Found = t("Quiz_Not_Found");

    const tabClassName = `admin-analytics-tab assesment-tabs ${document.body.dir === "ltr" ? "assess-right-padd" : "assess-left-padd"}`;

    const QuizPopupStyle = {
        position: "absolute",
        top: "20vh",
        right: "0%",
        width: "38.95vw",
        height: "80vh",
        bgcolor: "background.paper",
        border: "0",
        boxShadow: 24,
        overflowY: "auto",
        padding: "0 0 6vw 0"
    };

    function handleBreadcomClick(event) {
        event.preventDefault();
        setQuestionsLayoutModel({})
        setSelectedQuizQuestions([])
        setSelectedQuiz({})
        setSelectedQuestionIdsList([])
        setBreadcrumbsActive(!IsBreadcrumbsActive);
    }

    const openQuizQuestionList = (event, selectedQuiz) => {
        setSelectedQuiz(selectedQuiz)
        setBreadcrumbsActive(!IsBreadcrumbsActive);
        handleGetQuziQuestions(selectedQuiz)
    }

    const handleGetQuziQuestions = (selectedQuiz) => {
        setQuestionListLoader(true)
        var payload = {
            Id: selectedQuiz.Id,
            Body: {
                Sort: Sort.Asc
            }
        }
        API.getallQuizQuestions(payload).then(res => {
            if (res.status === 200) {
                setSelectedQuizQuestions(res.data)
                setQuestionsLayoutModel({
                    Id: selectedQuiz.Id,
                    Title: selectedQuiz.Title,
                    TotalMarks: selectedQuiz.TotalMarks,
                    QuestionSetTotal: selectedQuiz.TotalQuestions,
                    PassingScore: selectedQuiz.PassScore,
                    QuestionWithLevels: questionSetsCount(res.data),
                    CreatedOn: selectedQuiz.CreatedDate,
                    QuestionCount: res.data[0].QuestionCount,
                    Questions: res.data
                })
            }
            else {
                setQuestionsLayoutModel({
                    Id: selectedQuiz.Id,
                    Title: selectedQuiz.Title,
                    TotalMarks: selectedQuiz.TotalMarks,
                    QuestionSetTotal: selectedQuiz.TotalQuestions,
                    PassingScore: selectedQuiz.PassScore,
                    QuestionWithLevels: [],
                    CreatedOn: selectedQuiz.CreatedDate,
                    QuestionCount: 0,
                    Questions: []
                })
            }
        }).then(() => setQuestionListLoader(false))
    }

    function questionSetsCount(arr) {
        return (props.questionLevels && props.questionLevels.map(a => {
            return arr.filter(v => v.QuestionLevelId == a.Id).length > 0 ? {
                Id: a.Id,
                Level: a.Name,
                Count: arr.filter(v => v.QuestionLevelId == a.Id).length,
                Questions: arr.filter(v => v.QuestionLevelId == a.Id)
            } : {
                Id: null,
                Level: a.Name,
                Count: 0,
                Questions: []
            }
        }));
    }

    React.useEffect(() => {
        if (props.data.length > 0 && props.questionLevels && props.questionLevels.length > 0) {
            setSelectedCollection(props.data.map(d => ({
                select: false,
                Id: d.Id,
                Title: d.Title,
                TotalQuestions: d.TotalQuestions,
                TotalMarks: d.TotalScore,
                DifficultyRatio: difficultyRation(d.QuizLevels).join(' : '),
                PassScore: d.PassScoreInPertcentage,
                Retakes: d.NumberOfRetake,
                CreatedOn: d.CreatedOn,
                Levels: d.QuizLevels
            })));
        } else {
            setSelectedCollection([]);
        }
    }, [props.data, props.questionLevels]);
    
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function difficultyRation(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return  props.questionLevels && props.questionLevels.map(a => 
            (arr.find(v => v.LevelId == a.Id) || {}).NumberOfQuestions || 0
        );
    }
    
    const handleQuestionCreateDropdown = () => {
        setCreateQuestionOpen(true);
    };

    const handleQuizEditModal = () => {
        setQuizTitle(selectedQuiz.Title)
        setRetake(parseInt(selectedQuiz.Retakes))
        setIsRetakeAllowed(selectedQuiz.Retakes > 0)
        setTotalQuestions(selectedQuiz.TotalQuestions)
        setTotalScores(selectedQuiz.TotalMarks)
        setPassingPercentage(selectedQuiz.PassScore)
        setQuestionNumber(selectedQuiz.Levels.reduce((acc, cur) => ({ ...acc, [cur.LevelId]: cur.NumberOfQuestions }), {}))
        setLevelPercentage(selectedQuiz.Levels.reduce((acc, cur) => ({ ...acc, [cur.LevelId]: cur.PassScoreInPertcentage }), {}))
        setQuestionPoint(selectedQuiz.Levels.reduce((acc, cur) => ({ ...acc, [cur.LevelId]: cur.PointsPerQuestion }), {}))
        setEditQuizOpen(true);
    };

    const handleQuestionAssignDropdown = () => {
        setAssignQuestionOpen(true);
    };

    const handleClickOpenDropDown = (event) => {
        setDropdownAnchorEl(event.currentTarget);
        setFilterAnchorEl(null);
        setFilterOpen(false);
    };

    const handleClickOpenFilter = (event) => {
        setFilterAnchorEl(event.currentTarget);
        setDropdownAnchorEl(null);
        setFilterOpen(true);
    };

    const handleClose = () => {
        setDropdownAnchorEl(null);
        setFilterAnchorEl(null);
        setFilterOpen(false);
        setCreateQuestionOpen(false);
        setEditQuizOpen(false)
        setAssignQuestionOpen(false)
        setDeleteQuestionDialog(false)
        setQuestionLevelFilter([])
        setQuestionTypeFilter([])
        setAssignQuestionList({
            Count: 0,
            Questions: []
        })
        setAssignQuestionPageNumber(1)
        setSearchAssignQuestion('')
        setSelectedAssignQuestionList([])

        handleResetQuestions()


        setQuestionTitleError(false)
        setQuestionLevelError(false)
        setQuestionTypeError(false)
        setQuestionTagsError(false)
        setQuestionOptionsError(false)
        setEmptyQuestionForQuiz(false)
        setQuestionOptionValueError(false)
        setLevelPercentError(false)

        setQuizPointError(false)
        setQuizTotalQuestionError(false)
        setQuizTitleError(false)
        setQuizLevelConfigError(false)
        setQuizLevelEmptyError(false)

        setSingleChoiceError(false)
        setMultipleChoiceError(false)
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: ' var(--icons-color)',
            maxWidth: 220,
        },
    }));

    const handleCollapseClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleSelectQuestions = (event, questions) => {
        var { checked } = event.target
        if (checked) {
            setSelectedQuestionList(prev => [...prev, questions])
            setSelectedQuestionIdsList(prev => [...prev, questions.QuestionId])
        }
        else {
            var questionIdsIndex = selectedQuestionIdsList.indexOf(questions.QuestionId)
            var questionIndex = selectedQuestionList.indexOf(questions)
            selectedQuestionIdsList.splice(questionIdsIndex, 1)
            selectedQuestionList.splice(questionIndex, 1)
        }
    }

    const handleDeleteDialogs = () => {
        setDeleteQuestionDialog(true)
    }

    const handleDeleteQuestions = () => {
        setLocalLoader(true);
        API.removeQuestionfromquiz({
            questionId: selectedQuestionIdsList,
            quizId: questionsLayoutModel.Id
        }).then((res) => {
            setLocalLoader(false);
            handleClose()
            if (res.status === 200) {
                props.fetchQuizAction({ PageNumber: 1, PageSize: 20 })
                setAlert({
                    active: true,
                    severity: 'success',
                    message: 'Question removed successfully!'
                })
                handleGetQuziQuestions(selectedQuiz)
            }
            else {
                setAlert({
                    active: true,
                    severity: 'error',
                    message: 'Error while removing Question!'
                })
            }
        })
    }

    const handleOptionClick = () => {
        if (questionType.toString().length <= 0 || ![singleChoice, multipleChoice].includes(questionType.toString().trim())) {
            setQuestionTypeError(true)
            setQuestionOptionsForTypeError(true)
        }
        else {
            if (options.length < 5) {
                setOptions(prev => [...prev, { option: "", isCorrect: false }])
                setQuestionOptionsError(false)
                setQuestionOptionsMaxError(false)
                setSingleChoiceError(false)
                setMultipleChoiceError(false)
                setQuestionOptionValueError(false)
            }
            else {
                setQuestionOptionsMaxError(true)
            }
        }
    };


    const handleOptions = (index, e) => {
        let newOptions = [...options];
        newOptions[index][e.target.name] = e.target.value;
        if (e.target.name == 'isCorrect') {
            newOptions.forEach((a, expindex) => {
                if (index === expindex) {
                    a.isCorrect = a.isCorrect === "true" ? false : true;
                }
            })
        }
        setQuestionOptions(
            newOptions.map((a, idx) => {
                return {
                    Choice: a.option,
                    IsCorrectChoice: a.isCorrect,
                    OrderSequence: idx
                }
            })
        );
        setQuestionOptionValueError(false)
        setMultipleChoiceError(false)
    }

    const removeOptions = (i) => {
        let newOptions = [...options];
        newOptions.splice(i, 1);
        setOptions(newOptions)
        setQuestionOptions(
            newOptions.map((a, idx) => {
                return {
                    Choice: a.option,
                    IsCorrectChoice: a.isCorrect,
                    OrderSequence: idx
                }
            })
        );
        setQuestionOptionsMaxError(false)
    }

    const handleRadioOptions = (index, e) => {
        let newOptions = [...options];
        newOptions[index][e.target.name] = e.target.value;
        if (e.target.name == 'isCorrect') {
            newOptions.forEach((a, expindex) => {
                if (index === expindex) {
                    a.isCorrect = a.isCorrect === "true" ? false : true;
                }
                else {
                    a.isCorrect = false;
                }
            })
        }
        setQuestionOptions(
            newOptions.map((a, idx) => {
                return {
                    Choice: a.option,
                    IsCorrectChoice: a.isCorrect,
                    OrderSequence: idx
                }
            })
        );
        setQuestionOptionValueError(false)
        setSingleChoiceError(false)
    }

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...options];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setOptions(updatedList)
    };

    const handleCreateQuestion = () => {
        if (questionTitle.length > 0 &&
            questionType.length > 0 &&
            questionLevel.toString().length > 0 &&
            props.questionLevels && props.questionLevels.filter(a => a.Id === parseInt(questionLevel)).length > 0 &&
            questionOptions.length > 1 &&
            [singleChoice, multipleChoice].includes(questionType.toString().trim()) &&
            // questionTags.length > 0 &&
            options.filter(a => a.option.trim() === '').length === 0 &&
            options.length > 1 &&
            questionOptions.filter(a => a.Choice.trim() === '').length <= 0 &&
            (questionType.toString() === singleChoice ? questionOptions.filter(a => a.IsCorrectChoice).length === 1 :
                questionOptions.filter(a => a.IsCorrectChoice).length >= 2)
        ) {
            var model = {
                Question: questionTitle,
                QuestionType: questionType,
                QuestionLevelId: questionLevel,
                Tags: questionTags.join(', '),
                Choices: questionOptions
            }
            setModelLoader(true)
            API.createQuestions(model).then(res => {
                if (res.status === 200) {
                    setModelLoader(false)
                    setQuestionListLoader(true)
                    API.assignQuestionsToQuiz({
                        questionId: [res.data.QuestionId],
                        score: selectedQuiz.Levels.filter(a => a.LevelId == questionLevel).length > 0 ? selectedQuiz.Levels.find(a => a.LevelId == questionLevel).PointsPerQuestion : 0,
                        quizId: selectedQuiz.Id
                    }).then((res) => {
                        if (res.status === 200) {
                            handleGetQuziQuestions(selectedQuiz)
                            props.fetchQuizAction({ PageNumber: 1, PageSize: 20 })
                            props.fetchQuestionAction({
                                Pagination: {
                                    PageNumber: 1,
                                    PageSize: 20
                                }
                            })
                            setQuestionListLoader(false)
                            handleClose()
                            handleResetQuestions()
                        }
                    })
                }
            })
        }
        else {
            if (questionOptions.filter(a => a.Choice.trim() === '').length > 0) {
                setQuestionOptionValueError(true)
            }
            if (questionTitle.trim().length <= 0 || questionTitle.length > 250) {
                setQuestionTitleError(true)
            }
            if (questionType.length <= 0 || ![singleChoice, multipleChoice].includes(questionType.toString().trim())) {
                setQuestionTypeError(true)
            }
            if (questionLevel.length <= 0 || props.questionLevels && props.questionLevels.filter(a => a.Id === parseInt(questionLevel)).length <= 0) {
                setQuestionLevelError(true)
            }
            // if (questionTags.length <= 0) {
            //     setQuestionTagsError(true)
            // }
            if (options.filter(a => a.option.trim() === '').length > 0) {
                setQuestionOptionValueError(true)
            }
            if (questionType.toString() === singleChoice && questionOptions.filter(a => a.IsCorrectChoice).length !== 1) {
                setSingleChoiceError(true)
            }
            if (questionType.toString() === multipleChoice && questionOptions.filter(a => a.IsCorrectChoice).length < 2) {
                setMultipleChoiceError(true)
            }
        }
    }

    const handleEditQuestion = () => {
        if (questionTitle.length > 0 &&
            questionType.length > 0 &&
            questionLevel.toString().length > 0 &&
            props.questionLevels && props.questionLevels.filter(a => a.Id === parseInt(questionLevel)).length > 0 &&
            questionOptions.length > 1 &&
            [singleChoice, multipleChoice].includes(questionType.toString().trim()) &&
            // questionTags.length > 0 &&
            options.filter(a => a.option === '').length === 0 &&
            options.length > 1 &&
            questionOptions.filter(a => a.Choice === '').length <= 0 &&
            (questionType.toString() === singleChoice ? questionOptions.filter(a => a.IsCorrectChoice).length === 1 :
                questionOptions.filter(a => a.IsCorrectChoice).length >= 2)
        ) {
            var model = {
                Question: questionTitle,
                QuestionType: questionType,
                QuestionLevelId: questionLevel,
                Tags: questionTags.join(', '),
                Choices: questionOptions
            }
            setModelLoader(true)
            // API.updateQuestions(model).then(res => {
            //     if (res.status === 200) {
            //         setModelLoader(false)
            //     }
            // })
        }
        else {
            if (questionOptions.filter(a => a.Choice.trim() === '').length > 0) {
                setQuestionOptionValueError(true)
            }
            if (questionTitle.trim().length <= 0 || questionTitle.length > 250) {
                setQuestionTitleError(true)
            }
            if (questionType.length <= 0 || ![singleChoice, multipleChoice].includes(questionType.toString().trim())) {
                setQuestionTypeError(true)
            }
            if (questionLevel.length <= 0 || props.questionLevels && props.questionLevels.filter(a => a.Id === parseInt(questionLevel)).length <= 0) {
                setQuestionLevelError(true)
            }
            // if (questionTags.length <= 0) {
            //     setQuestionTagsError(true)
            // }
            if (options.filter(a => a.option.trim() === '').length > 0) {
                setQuestionOptionValueError(true)
            }
            if (questionType.toString() === singleChoice && questionOptions.filter(a => a.IsCorrectChoice).length !== 1) {
                setSingleChoiceError(true)
            }
            if (questionType.toString() === multipleChoice && questionOptions.filter(a => a.IsCorrectChoice).length < 2) {
                setMultipleChoiceError(true)
            }
        }
    }

    const handleSearchAssignQuestion = async () => {
        setAssignQuestionPageNumber(1)
        setModelLoader(true)
        var response = await getAllQuestions(1, questionLevelFilter, [], questionTypeFilter, searchAssignQuestion.trim())
        if (response.status === 200) {
            const QuestionIds = questionsLayoutModel.Questions.map(q => q.QuestionId);
        const filteredMediumQuestions = response.data.MediumQuestions.filter(question => !QuestionIds.includes(question.QuestionId));
        const filteredEasyQuestions = response.data.EasyQuestions.filter(question => !QuestionIds.includes(question.QuestionId));
        const filteredComplexQuestions = response.data.ComplexQuestions.filter(question => !QuestionIds.includes(question.QuestionId));

        const filteredQuestions = [
        ...filteredMediumQuestions,
        ...filteredEasyQuestions,
        ...filteredComplexQuestions
        ];
        setAssignQuestionList({
            Count: filteredQuestions.length,
            Questions: filteredQuestions
        });
        setModelLoader(false);
        }
        else {
            setAssignQuestionList({
                Count: 0,
                Questions: []
            })
            setModelLoader(false)
        }
    }

    const handleAssignQuestionLoadMore = async () => {
        setModelLoader(true)
        var response = await getAllQuestions(assignQuestionPagenumber + 1, questionLevelFilter, [], questionTypeFilter, searchAssignQuestion.trim())
        if (response.status === 200) {
            setAssignQuestionPageNumber(prev => prev + 1)
            setAssignQuestionList({
                Count: response.data.Count,
                Questions: [...assignQuestionList.Questions, ...response.data.Questions]
            })
            setModelLoader(false)
        }
    }

    const getAllQuestions = (pageNumber, difficulty, tags, types, searchText) => {
        return API.getAllQuestions({
            Filters: {
                Difficulty: difficulty,
                Tags: tags,
                Types: types,
            },
            Pagination: {
                PageNumber: pageNumber,
                PageSize: 5,
                FilterField: "Question",
                FilterText: searchText
            }
        })
    }

    const handleResetQuestions = () => {
        setQuestionTitle('')
        setQuestionLevel('')
        setQuestionType('')
        setQuestionTags([])
        setQuestionOptions([])
        setOptions([])

        setQuestionTitleError(false)
        setQuestionLevelError(false)
        setQuestionTypeError(false)
        setQuestionTagsError(false)
        setQuestionOptionsError(false)
        setQuestionOptionsForTypeError(false)
        setQuestionOptionValueError(false)
        setQuestionOptionsMaxError(false)
        setSingleChoiceError(false)
        setMultipleChoiceError(false)
        setQuestionOptionsMaxError(false)
        setQuestionOptionValueError(false)
    }

    const handleAssignQuestionQuestionLevels = (event) => {
        const {
            target: { value },
        } = event;
        setQuestionLevelFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleAssignQuestionQuestionTypes = (event) => {
        const {
            target: { value },
        } = event;
        setQuestionTypeFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleAddQuestionToAssignedList = (question) => {
        selectedAssignQuestionList.filter(a => a.QuestionId === question.QuestionId).length === 0 &&
            setSelectedAssignQuestionList(prev => [...prev, question])
    };

    const handleRemoveQuestionToAssignedList = (question) => {
        var index = selectedAssignQuestionList.indexOf(question)
        let newList = [...selectedAssignQuestionList];
        newList.splice(index, 1);
        setSelectedAssignQuestionList(newList)
    };

    const handleAssignQuestion = () => {
        setModelLoader(true)
        Promise.all(selectedAssignQuestionList.map(question => {
            return API.assignQuestionsToQuiz({
                questionId: [question.QuestionId],
                score: selectedQuiz.Levels.filter(a => a.LevelType == question.Difficulty).length > 0 ? selectedQuiz.Levels.find(a => a.LevelType == question.Difficulty).PointsPerQuestion : 0,
                quizId: selectedQuiz.Id
            })
        })).then(() => {
            handleGetQuziQuestions(selectedQuiz)
            props.fetchQuizAction({ PageNumber: 1, PageSize: 20 })
            props.fetchQuestionAction({
                Pagination: {
                    PageNumber: 1,
                    PageSize: 20
                }
            })
            handleClose()
            handleResetQuestions()
            setModelLoader(false)
        })
    }


    const handleNumberOfQuestion = (event) => {
        const { name, value } = event.target
        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setLevelPercentError(false)
        setQuizTotalQuestionError(false)
    
        const parsedValue = parseInt(value.replace(/^0+/, ""), 10);
    
        if (isNaN(parsedValue) || parsedValue < 0) {
            setQuestionNumber((prevInfo) => {
                return { ...prevInfo, [name]: 0 }; 
            });
        } else {
            setQuestionNumber((prevInfo) => {
                return { ...prevInfo, [name]: parsedValue };
            })
        }
    }

    const handlePercentage = (event) => {
        const { name, value } = event.target
        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setQuizTotalQuestionError(false)
        setLevelPercentError(false)
    
        const parsedValue = parseInt(value.replace(/^0+/, ""), 10);
        if (isNaN(parsedValue) || parsedValue < 0) {
            setLevelPercentage((prevInfo) => {
              return { ...prevInfo, [name]: 0 }; 
            })
        } else {
            setLevelPercentage((prevInfo) => {
                return { ...prevInfo, [name]: parsedValue };
            })
        }
    }

    const handleQuestionPoints = (event) => {
        const { name, value } = event.target
        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setLevelPercentError(false)
        setQuizTotalQuestionError(false)
    
        const parsedValue = parseInt(value.replace(/^0+/, ""), 10);
    
        if (isNaN(parsedValue) || parsedValue < 0) {
        setQuestionPoint((prevInfo) => {
              return { ...prevInfo, [name]: 0 };
            });
        } else {
            setQuestionPoint((prevInfo) => {
                return { ...prevInfo, [name]: parsedValue };
            })
        }
    }

    const handleEditQuiz = () => {
        var levelObj = Object.values(questionNumber)
        var levels = Object.keys(questionNumber)
        // var totalPercentage = Object.values(levelPercentage)

        var percentages = levels.map(a => {
            return {
                points: questionPoint[a],
                percent: levelPercentage[a]
            }
        })

        let validValuesCount = 0;
    
        Object.keys(questionNumber).forEach((levelId) => {
            if (
                questionNumber[levelId] > 0 &&
                !isNaN(questionNumber[levelId]) && 
                levelPercentage[levelId] >= 0 &&
                !isNaN(levelPercentage[levelId]) &&
                questionPoint[levelId] > 0 &&
                !isNaN(questionPoint[levelId])
            ) {
                validValuesCount += 3;
            }
        });

        const isLevelComplete = validValuesCount === 9 ? true : false;

        var iserror = percentages.filter(a => isNaN(a.percent) || isNaN(a.points) || parseInt(a.points) === 0).length
        var isPercentageCorrect = percentages.filter(a => parseInt(a.percent) <= 0 || parseInt(a.percent) > 100).length

        if ( isLevelComplete && quizTitle.length > 0 && quizTitle.length <= 50 && totalQuestions >= 1 && retake <= 5 && levels.length && !levelObj.some(isNaN) && !iserror && !isPercentageCorrect) {
            setModelLoader(true)
            var quizPayload = {
                Id: selectedQuiz.Id,
                Title: quizTitle.trim(),
                NumberOfRetake: isNaN(retake) ? 0 : retake,
                PassScoreInPertcentage: parseInt(passingPercentage),
                TotalQuestions: parseInt(totalQuestions),
                TotalScore: parseInt(totalScores),
                IsOptional: true,
                QuizLevels: Object.keys(questionNumber).map(item => {
                    return {
                        LevelId: parseInt(item),
                        NumberOfQuestions: parseInt(questionNumber[item]),
                        PassScoreInPertcentage: parseInt(levelPercentage[item]),
                        PointsPerQuestion: parseInt(questionPoint[item])
                    }
                })
            }
            API.updateQuiz(quizPayload)
                .then(res => res.status === 200 &&
                    props.fetchQuizAction({ PageNumber: 1, PageSize: 20 }))
                .then(() =>
                    setModelLoader(false))
                .then(() => {
                    var payload = {
                        select: false,
                        Id: quizPayload.Id,
                        Title: quizPayload.Title,
                        TotalQuestions: quizPayload.TotalQuestions,
                        TotalMarks: quizPayload.TotalScore,
                        DifficultyRatio: difficultyRation(quizPayload.QuizLevels).join(' : '),
                        PassScore: quizPayload.PassScoreInPertcentage,
                        Retakes: quizPayload.NumberOfRetake,
                        CreatedOn: quizPayload.CreatedDate,
                        Levels: quizPayload.QuizLevels.map(a => {
                            return {
                                AssignedQuestions: selectedQuiz.Levels.filter(j => j.LevelId == a.LevelId)[0].AssignedQuestions,
                                Index: null,
                                LevelId: a.LevelId,
                                LevelType: props.questionLevels && props.questionLevels.filter(j => j.Id == a.LevelId)[0].Name,
                                NumberOfQuestions: a.NumberOfQuestions,
                                PassScoreInPertcentage: a.PassScoreInPertcentage,
                                PointsPerQuestion: a.PointsPerQuestion,
                            }
                        })
                    }
                    setSelectedQuiz(payload)
                })
                .then(() =>
                    handleClose())
        }
        else {
            if(!isLevelComplete){
                setQuizLevelConfigError(true)
            }
            if (quizTitle.length <= 0) {
                setQuizTitleError(true)
            }
            if (passingPercentage <= 0) {
                setQuizPointError(true)
            }
            if (totalQuestions < 1) {
                setQuizTotalQuestionError(true)
            }
            if (levelObj.length <= 0) {
                setQuizLevelEmptyError(true)
            }
            if (levelObj.filter(a => isNaN(a)).length === levelObj.length) {
                setQuizLevelEmptyError(true)
            }
            if (isPercentageCorrect !== 0) {
                setLevelPercentError(true)
            }
            if (iserror !== 0) {
                setQuizLevelConfigError(true)
            }
        }
    }

    React.useEffect(() => {
        if (Object.keys(questionNumber).length > 0) {
            Object.keys(questionPoint).forEach(key => questionPoint[key] === undefined && delete questionPoint[key])
            const questmarkSync = Object.keys(questionPoint).reduce((acc, key) => {
                acc[key] = questionPoint[key] * questionNumber[key];
                return acc;
            }, {});
            var totalPoints = Object.values(questmarkSync).reduce((a, b) => a + b, 0)
            totalPoints = isNaN(totalPoints) ? 0 : totalPoints
            setTotalScores(totalPoints)
        }
    }, [questionPoint, questionNumber]);

    React.useEffect(() => {
        if (Object.values(levelPercentage).length > 0) {
            var totalPercentage = Object.values(levelPercentage)
            var result = Array.from(totalPercentage, v => v === undefined ? 0 : v);
            var calPer = result.filter(a => parseInt(a) !== 0).reduce((a, b) => { return parseInt(a) + parseInt(b) }, 0) / result.filter(a => parseInt(a) !== 0).length
            setPassingPercentage(parseInt(calPer))
        }
    }, [levelPercentage])

    React.useEffect(() => {
        Object.keys(questionNumber).forEach(key => questionNumber[key] === undefined && delete questionNumber[key])
        var totalQuestions = Object.values(questionNumber).reduce((a, b) => a + b, 0)
        totalQuestions = isNaN(totalQuestions) ? 0 : totalQuestions
        setTotalQuestions(totalQuestions)
    }, [questionNumber]);

    const handleFilter = () => {
        setQuestionListLoader(true)
        var payload = {
            Id: selectedQuiz.Id,
            Body: {
                Types: sortFieldType === "All" || sortFieldType.length === 0 ? [] : [sortFieldType],
                Sort: sortFieldDate
            }
        }
        API.getallQuizQuestions(payload).then(res => {
            if (res.status === 200) {
                setSelectedQuizQuestions(res.data)
                setQuestionsLayoutModel({
                    Id: selectedQuiz.Id,
                    Title: selectedQuiz.Title,
                    TotalMarks: selectedQuiz.TotalMarks,
                    QuestionSetTotal: selectedQuiz.TotalQuestions,
                    PassingScore: selectedQuiz.PassScore,
                    QuestionWithLevels: questionSetsCount(res.data),
                    CreatedOn: selectedQuiz.CreatedDate,
                    QuestionCount: res.data[0].QuestionCount,
                    Questions: res.data
                })
            }
            else {
                setQuestionsLayoutModel({
                    Id: selectedQuiz.Id,
                    Title: selectedQuiz.Title,
                    TotalMarks: selectedQuiz.TotalMarks,
                    QuestionSetTotal: selectedQuiz.TotalQuestions,
                    PassingScore: selectedQuiz.PassScore,
                    QuestionWithLevels: [],
                    CreatedOn: selectedQuiz.CreatedDate,
                    QuestionCount: 0,
                    Questions: []
                })
            }
        }).then(() => {
            setFilterOpen(false)
            setQuestionListLoader(false)
        })
    }

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            severity: '',
            message: ''
        })
    }

    React.useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);

    React.useEffect(() => {
        const checkIfSubmitEnabled = () => {
            return (
                selectedAssignQuestionList.length > 0 &&
                (
                    searchAssignQuestion.trim() !== '' ||
                    questionTypeFilter.length > 0 ||
                    questionLevelFilter.length > 0
                )
            );
        };
        setIsSubmitEnabled(checkIfSubmitEnabled());
    }, [searchAssignQuestion, questionTypeFilter, questionLevelFilter, selectedAssignQuestionList]);

    React.useEffect(() => {
        const value = Number(retake);
        const error = value < 0 ? 'Retakes cannot be negative' : value > 5 ? 'Retakes cannot be more than 5' : '';
        setRetakeError(error);
      }, [retake]);

      const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        }
      };

    return (
        <div style={{ width: "99%" }}>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={alert.active}
                onClick={handleBackDropClose}
            >
                <Alert severity={alert.severity} style={{ position: "absolute", top: 20 }}>
                    {`${alert.message}`}
                </Alert>
            </Backdrop>
            {IsBreadcrumbsActive ?
                (!questionListLoader ?
                    <div className='main-quiz-container'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <Breadcrumbs aria-label="breadcrumb"
                                separator={<NavigateNextIcon fontSize="medium" />}>
                                <Link underline="hover" color="inherit" href="/" style={{ opacity: "0.4" }} onClick={handleBreadcomClick}>
                                    <h2>Quiz</h2>
                                </Link>
                                <h2 className='word-break'>{selectedQuiz.Title}</h2>
                            </Breadcrumbs>
                            <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 42 39" fill="none" onClick={handleQuizEditModal}>
                                <rect class="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                                <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" class="edit-icon-svg" />
                            </svg>
                        </div>

                        <div>
                            <div>
                                <div className='d-flex align-items-center justify-content-between marks-and-tags-info'>
                                    <HtmlTooltip
                                        title={
                                            <div className='tooltip-content'>
                                                <p><span>Question Set : </span>{selectedQuiz.Levels.reduce((n, { NumberOfQuestions }) => n + NumberOfQuestions, 0)}</p>
                                                {
                                                    props.questionLevels && props.questionLevels.map((level) => {
                                                        return <p>{level.Name} :<span>{
                                                            selectedQuiz.Levels.filter(a => a.LevelId === level.Id).length > 0 ? selectedQuiz.Levels.find(a => a.LevelId === level.Id).NumberOfQuestions : 0
                                                        }</span></p>
                                                    })
                                                }
                                            </div>
                                        }
                                        arrow TransitionComponent={Zoom}>
                                        <div className='d-flex  align-items-center total-scrore'>
                                            <p><span>Question Set : </span>{selectedQuiz.Levels.reduce((n, { NumberOfQuestions }) => n + NumberOfQuestions, 0)}</p>
                                            {
                                                props.questionLevels && props.questionLevels.map((level) => {
                                                    return <p><span>{level.Name} : </span>{
                                                        selectedQuiz.Levels.filter(a => a.LevelId === level.Id).length > 0 ? selectedQuiz.Levels.find(a => a.LevelId === level.Id).NumberOfQuestions : 0
                                                    }</p>
                                                })
                                            }
                                        </div>
                                    </HtmlTooltip>
                                    <div className='d-flex  align-items-center total-scrore'>
                                        <p><span>Total Questions : </span>{questionsLayoutModel.QuestionCount}</p>
                                        <p><span>Total Marks : </span>{questionsLayoutModel.TotalMarks}</p>
                                        <p><span>Pass % : </span>{questionsLayoutModel.PassingScore} %</p>
                                    </div>
                                </div>
                                <div>
                                    <Box >
                                        <Box >
                                            <div className='d-flex align-items-center justify-content-between mt-4'>
                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='admin-analytics-tabs assesment-quiz-tab'>
                                                    {
                                                        props.questionLevels && props.questionLevels.map((level, idx) => {
                                                            return <Tab className={tabClassName} label={`${level.Name} (${questionsLayoutModel.QuestionWithLevels.filter(a => a.Id === level.Id).length > 0 ? questionsLayoutModel.QuestionWithLevels.find(a => a.Id === level.Id).Count : 0
                                                                })`} {...a11yProps(level.Id)} />
                                                        })
                                                    }

                                                </Tabs>
                                                <div className='assessment-action-btns'>
                                                    <button type='button' className='create-new-btn' onClick={handleClickOpenDropDown}><p>Add question</p></button>
                                                    <StyledMenu
                                                        id="demo-customized-menu"
                                                        MenuListProps={{
                                                            'aria-labelledby': 'demo-customized-button',
                                                        }}
                                                        anchorEl={dropdownAnchorEl}
                                                        open={isDropdownOpen}
                                                        onClose={handleClose}
                                                        arrow
                                                    >
                                                        <MenuItem className="group-dpdn-size" disableRipple onClick={handleQuestionCreateDropdown}>
                                                            Create New
                                                        </MenuItem>
                                                        <Divider className="mui-devider" />
                                                        <MenuItem className="group-dpdn-size" disableRipple onClick={handleQuestionAssignDropdown}>
                                                            Add Existing
                                                        </MenuItem>
                                                    </StyledMenu>

                                                    <div className='horizontal-divider'></div>
                                                    {/* <Tooltip title="Edit Question">
                                                        <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 39" fill="none" pointerEvents={selectedQuestionIdsList.length === 1 ? "" : "none"} onClick={handleQuestionEditDropdown}>
                                                            <rect class="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                                                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" class="edit-icon-svg" />
                                                        </svg>
                                                    </Tooltip> */}
                                                    <Tooltip title={selectedQuestionIdsList.length === 0 ? "Select atleast one question" : "Delete Question"}>
                                                        <button className='edit-disable' disabled={selectedQuestionIdsList.length === 0} onClick={handleDeleteDialogs}>
                                                            <svg id="del-icon" width="39" height="39" viewBox="0 0 42 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" className='del-stroke' stroke-width="0.9" />
                                                                <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white" />
                                                                <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white" />
                                                                <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" class="del-svg" />
                                                                <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white" />
                                                                <path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white" />
                                                                <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white" />
                                                            </svg>
                                                        </button>
                                                    </Tooltip>
                                                    <div className='horizontal-divider'></div>
                                                    <svg onClick={handleClickOpenFilter} id="edit-icon" width="39" height="39" viewBox="0 0 48 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.45" y="0.45" width="47.1" height="38.1" rx="2.55" stroke="#062349" stroke-width="0.9" />
                                                        <path d="M32.3334 12H15.6667L22.3334 19.8833V25.3333L25.6667 27V19.8833L32.3334 12Z" stroke="#062349" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>


                                                    <Popover
                                                        id={filterPopoverId}
                                                        open={openFilter}
                                                        anchorEl={filterAnchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        sx={{
                                                            boxShadow: "0px 3px 6px #00000014",
                                                            borderRadius: "12px",
                                                        }}
                                                    >
                                                        <Box>
                                                            <div className='filter-popup-main'>
                                                                <h2>Sort By</h2>
                                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue={sortFieldDate || Sort.Desc} onChange={(e) => setSortFieldDate(e.target.value)}>
                                                                    {/* <FormControlLabel className='list-item-text' value="All" control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label="All" /> */}
                                                                    <FormControlLabel className='list-item-text' value={Sort.Desc} control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label="Newest First" />
                                                                    <FormControlLabel className='list-item-text' value={Sort.Asc} control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label="Oldest First" />
                                                                </RadioGroup>
                                                                <h2>Question Type</h2>
                                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue={sortFieldType || "All"} onChange={(e) => setSortFieldType(e.target.value)}>
                                                                    <FormControlLabel className='list-item-text' value="All" control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label="All" />
                                                                    <FormControlLabel className='list-item-text' value={singleChoice} control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label={singleChoice} />
                                                                    <FormControlLabel className='list-item-text' value={multipleChoice} control={<Radio className='asses-radiobox' sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />} label={multipleChoice} />
                                                                </RadioGroup>

                                                                {/* <div className="Root">
                                                                    <h2>Tags</h2>
                                                                    <div className="InputWrapper">

                                                                        <Autocomplete
                                                                            multiple
                                                                            options={options}
                                                                            value={valueSlect}
                                                                            onChange={(event, newValue) => {
                                                                                setSelectValue(newValue);
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <input {...params} placeholder='Search Tags' />
                                                                                    <div className="ExploreMoreIcon">
                                                                                        <KeyboardArrowDownIcon />
                                                                                    </div>
                                                                                </div>

                                                                            )}
                                                                        />
                                                                        {valueSlect.map((option, index) => (
                                                                            <StyledTag
                                                                                key={index}
                                                                                label={option.title}
                                                                                onDelete={() => {
                                                                                    setSelectValue((currentValue) =>
                                                                                        currentValue.filter((val) => val.title !== option.title)
                                                                                    );
                                                                                }}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div> */}
                                                                <div className='fixed-btns model-padding'>
                                                                    <div className='filter-btn-group '>
                                                                        {/* <button class="filter-clear-all">Clear All</button> */}
                                                                        <button class="filter-clear-search" onClick={handleFilter}>Search</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Popover>
                                                </div>
                                            </div>
                                        </Box>
                                        {questionsLayoutModel.Questions.length > 0 ?
                                            props.questionLevels && props.questionLevels.map((level, idx) => {
                                                return (
                                                    <CustomTabPanel className="assessment-tab-panel" value={value} index={level.Id} >
                                                        <div className='quiz-edit-white-bg'>
                                                            <List component="nav" aria-labelledby="nested-list-subheader" sx = {{p:0}}>
                                                                {
                                                                    questionsLayoutModel.QuestionWithLevels.filter(a => a.Id === level.Id).length > 0 &&
                                                                    questionsLayoutModel.QuestionWithLevels.find(a => a.Id === level.Id).Questions.map((question, idx) => {
                                                                        return (
                                                                            <>
                                                                                <ListItemButton onClick={() => handleCollapseClick(idx)} className='justify-content-between'>
                                                                                    <div>
                                                                                        <FormControlLabel className='list-item-text' control={<Checkbox className='asses-checkbox' onClick={(event) => handleSelectQuestions(event, question)} />} label={`${idx + 1}. ${question.Title} `} />
                                                                                    </div>
                                                                                    {openIndex === idx ? <ExpandLess className='asses-expand-icon' /> : <ExpandMore className='asses-expand-icon' />}
                                                                                </ListItemButton>
                                                                                <Collapse in={openIndex === idx} timeout="auto" unmountOnExit>
                                                                                    <div className={`collapse-inside ${document.body.dir === "ltr" ? "coll-body-left" : "coll-body-right"}`}>
                                                                                    {
                                                                                            question.QuestionType === singleChoice &&
                                                                                            <div className='quiz-answer-con'>
                                                                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                                                                    {
                                                                                                        question.Choice.map((choice, idx) => {
                                                                                                            return (
                                                                                                                <FormControlLabel className='list-item-text' value={choice.ChoiceId} control={<Radio className='asses-radiobox' disabled checked={choice.IsCorrectChoice} />} label={`${String.fromCharCode(65 + idx)}) ${choice.Choice}`} />
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </RadioGroup>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            question.QuestionType === multipleChoice &&
                                                                                            <div className='quiz-answer-con'>
                                                                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                                                                    {
                                                                                                        question.Choice.map((choice, idx) => {
                                                                                                            return (
                                                                                                                <FormControlLabel className='list-item-text' value={choice.ChoiceId} control={<Checkbox className='asses-radiobox' disabled checked={choice.IsCorrectChoice} />} label={`${String.fromCharCode(65 + idx)}) ${choice.Choice}`} />
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </RadioGroup>
                                                                                            </div>
                                                                                        }
                                                                                        <div className='d-flex align-items-center justify-content-between mt-3 mb-3 question-info-con'>
                                                                                            <div>
                                                                                                <p>Tags: <span><strong>{question.Tags.length > 0 ? question.Tags : "No Tags"}</strong></span></p>
                                                                                            </div>
                                                                                            <Divider orientation="vertical" flexItem />
                                                                                            <div>
                                                                                                <p>Date Created: <span><strong>{moment(question.CreatedDate).format("DD/MM/YYYY")}</strong></span></p>
                                                                                            </div>
                                                                                            <Divider orientation="vertical" flexItem />
                                                                                            <div>
                                                                                                <p>Question Type: <span><strong>{question.QuestionType}</strong></span></p>
                                                                                            </div>
                                                                                            <Divider orientation="vertical" flexItem />
                                                                                            <div>
                                                                                                <p>Difficulty Level: <span><strong>{question.QuestionLevel}</strong></span></p>
                                                                                            </div>
                                                                                            <Divider orientation="vertical" flexItem />
                                                                                            <div>
                                                                                                <p>Points for correct Answer: <span><strong>{question.Point}</strong></span></p>
                                                                                            </div>
                                                                                        </div>
                                                                                        


                                                                                    </div>
                                                                                </Collapse>
                                                                                <Divider />
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </List>
                                                        </div>
                                                    </CustomTabPanel>
                                                )
                                            })
                                            :
                                            <div className='no-questions-added'><h3>NO QUESTION ADDED YET</h3></div>
                                        }
                                        <Dialog open={deleteQuestionDialog} onClose={handleClose} className='confirmation-popup' >
                                            {!localLoader? 
                                            <> <div className='d-flex align-items-center justify-content-between'>
                                                <DialogTitle>Remove Question</DialogTitle>
                                                <div>
                                                    <img src="../assets/close-black.png" alt="lms-logo" class="close-icon w-50" onClick={handleClose}/>
                                                </div>
                                            </div>
                                            <Divider className="confirmation-popup-divider" />
                                            <DialogContent>
                                                <DialogContentText>
                                                    Do you really want to remove the selected questions. It will be remove from this quiz ?
                                                </DialogContentText>
                                            </DialogContent>
                                            <Divider className="confirmation-popup-divider" />
                                            <DialogActions sx={{ p: 2 }}>
                                                <button
                                                    className="primary-button ter-btn red-button"
                                                    onClick={handleDeleteQuestions}
                                                >
                                                    Remove
                                                </button>
                                                <button
                                                    className="primary-button ter-btn"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </button>
                                            </DialogActions> </>: <Spinner/>}
                                        </Dialog>

                                        {/*Create Question modal*/}
                                        <Modal
                                            open={isCreateQuestionOpen}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <div className='popup-breadcrumd'>
                                                <Box sx={QuizPopupStyle}>
                                                    {
                                                        !modelLoader ?
                                                            <>
                                                                <div className=' quiz-creator-con'>
                                                                    <Breadcrumbs aria-label="breadcrumb"
                                                                        separator={<NavigateNextIcon fontSize="medium" />}>
                                                                        <h2 className='word-break' style={{ opacity: "0.4" }}>{selectedQuiz.Title}</h2>
                                                                        <h2>Create Question</h2>
                                                                    </Breadcrumbs>
                                                                    <div>
                                                                        <img src="../assets/close-black.png" alt="lms-logo" class="close-icon" onClick={handleClose}/>
                                                                    </div>
                                                                </div>
                                                                <div className='mui-hr-line'></div>
                                                                <div className="quiz-text-input">
                                                                    <label className="question-title"><p>. Write your question here *</p></label>
                                                                    <input type="text" className={questionTitleError && `error`} placeholder="Write your question here" value={questionTitle}
                                                                        error={questionTitleError}
                                                                        onChange={(e) => {
                                                                            setQuestionTitle(e.target.value)
                                                                            setQuestionTitleError(false)
                                                                        }} />
                                                                    {questionTitleError && <label className="question-title error-label"><p>Quiz title should between 1-250 characters</p></label>}
                                                                </div>
                                                                <div className="quiz-selection-con mt-4 b-0 d-flex align-items-center justify-content-between pb-0">
                                                                    <div className="quiz-select">
                                                                        <label for="points">Question Type*</label>
                                                                        <FormControl id="points" >
                                                                            <Select IconComponent={ExpandMoreIcon} value={questionType.toString() || "Select Question Type"} placeholder="Please Select Type" input={<OutlinedInput label="Tag"
                                                                                onChange={(e) => {
                                                                                    setQuestionType(e.target.value.trim())
                                                                                    setQuestionTypeError(false)
                                                                                }} />}
                                                                                error={questionTypeError}
                                                                                helperText={questionTypeError && `Please select type`}
                                                                            >
                                                                                <MenuItem value="Select Question Type">Select Question Type</MenuItem>
                                                                                <MenuItem value={singleChoice}>{singleChoice}</MenuItem>
                                                                                <MenuItem value={multipleChoice}>{multipleChoice}</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className="quiz-select">
                                                                        <label for="retake" className="question-title m-0"><p>Difficulty level*</p></label>
                                                                        <FormControl id="retake">
                                                                            <Select IconComponent={ExpandMoreIcon}
                                                                                error={questionlevelError}
                                                                                placeholder="Please Select Difficulty"
                                                                                value={questionLevel.toString() || "Select Level"}
                                                                                input={<OutlinedInput label="Tag" onChange={(e) => {
                                                                                    setQuestionLevel(e.target.value)
                                                                                    setQuestionLevelError(false)
                                                                                }} />}>
                                                                                <MenuItem value={questionLevel || "Select Level"}>Select Level</MenuItem>
                                                                                {
                                                                                    props.questionLevels && props.questionLevels.filter(a => selectedQuiz.Levels.filter(a => a.NumberOfQuestions > 0).map(i => { return i.LevelId }).includes(a.Id)).map((item, idx) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                value={item.Id}>{item.Name}</MenuItem>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className="quiz-selection-con b-0 d-flex align-items-center justify-content-between pb-0">
                                                                </div>
                                                                <div className=" quiz-selection-con options-container ">
                                                                    <h1>Input option for the question & select the correct answer</h1>
                                                                    {
                                                                        <DragDropContext onDragEnd={handleDrop}>
                                                                            <List className="new-quiz-list">
                                                                                <Droppable droppableId="list-container">
                                                                                    {(provided) => (
                                                                                        <div
                                                                                            className="list-container"
                                                                                            {...provided.droppableProps}
                                                                                            ref={provided.innerRef}
                                                                                        >
                                                                                            {
                                                                                                options.length > 0 &&
                                                                                                options.map((element, index) => (
                                                                                                    <Draggable key={index} draggableId={`draggable ${index}`} index={index}>
                                                                                                        {(provided) => (
                                                                                                            <div
                                                                                                                ref={provided.innerRef}
                                                                                                                {...provided.dragHandleProps}
                                                                                                                {...provided.draggableProps}
                                                                                                            >
                                                                                                                <ListItem disablePadding className="option-item" name='options'>
                                                                                                                    <div className="input-option">
                                                                                                                        <input type="text" placeholder={`Option ${index + 1}`} name="option" value={element.option || ''} onChange={(e) => handleOptions(index, e)} className={`${document.body.dir === "ltr" ? "op-edit-r" : "op-edit-l"}`} />
                                                                                                                        <div className="lms-flex-align-center option-icons">
                                                                                                                            {
                                                                                                                                questionType === singleChoice &&
                                                                                                                                <Radio sx={{ padding: 0 }} name="isCorrect" value={element.isCorrect} checked={element.isCorrect} onChange={(e) => handleRadioOptions(index, e)} />

                                                                                                                            }
                                                                                                                            {questionType === multipleChoice &&
                                                                                                                                <Checkbox sx={{ padding: 0 }} name="isCorrect" checked={element.isCorrect} value={element.isCorrect} onChange={(e) => handleOptions(index, e)} />
                                                                                                                            }
                                                                                                                            <div><img src="../assets/vertical-bars.png" alt="lms-logo" /></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div onClick={() => removeOptions(index)} className="quiz-del-icon">
                                                                                                                        <svg id="small-del-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none" >
                                                                                                                            <path d="M17 4.53778C14.04 4.24444 11.0622 4.09333 8.09333 4.09333C6.33333 4.09333 4.57333 4.18222 2.81333 4.36L1 4.53778M5.88889 3.64L6.08444 2.47556C6.22667 1.63111 6.33333 1 7.83556 1H10.1644C11.6667 1 11.7822 1.66667 11.9156 2.48444L12.1111 3.64M15.0889 7.34667L14.5111 16.2978C14.4133 17.6933 14.3333 18.7778 11.8533 18.7778H6.14667C3.66667 18.7778 3.58667 17.6933 3.48889 16.2978L2.91111 7.34667M7.51556 13.8889H10.4756M6.77778 10.3333H11.2222" className="del-stroke" />
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                </ListItem>

                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Draggable>
                                                                                                ))
                                                                                            }
                                                                                            {provided.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>
                                                                            </List>
                                                                        </DragDropContext>
                                                                    }
                                                                    <div className="lms-flex-align-center" onClick={handleOptionClick}>
                                                                        <AddOutlinedIcon />
                                                                        <h2>Add Option</h2>
                                                                    </div>
                                                                    {questionOptionsForTypeError && <label className="question-title error-label"><p>Please select question type first</p></label>}
                                                                    {questionOptionsError && <label className="question-title error-label"><p>Please create options</p></label>}
                                                                    {questionOptionValueError && <label className="question-title error-label"><p>Option cannot be empty and whitespace</p></label>}
                                                                    {questionOptionsMaxError && <label className="question-title error-label"><p>You can not add more than 5 options</p></label>}
                                                                    {singleChoiceError && <label className="question-title error-label"><p>Please set only 1 option as correct answer</p></label>}
                                                                    {multipleChoiceError && <label className="question-title error-label"><p>Please set atleast 2 options as correct answer</p></label>}
                                                                </div>
                                                                <div className='fixed-btns model-padding'>
                                                                    <div className='filter-btn-group'>
                                                                        <button class="filter-clear-search" onClick={handleCreateQuestion}>Submit</button>
                                                                    </div>
                                                                </div>
                                                            </> : <Spinner />
                                                    }
                                                </Box>
                                            </div>
                                        </Modal>

                                        {/*Add Existing Question*/}
                                        <Modal
                                            open={isAssignQuestionOpen}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <div className='popup-breadcrumd'>
                                                <Box sx={QuizPopupStyle}>
                                                    <div className=' quiz-creator-con'>
                                                        <Breadcrumbs aria-label="breadcrumb"
                                                            separator={<NavigateNextIcon fontSize="medium" />}>
                                                            <h2 className='word-break' style={{ opacity: "0.4" }}>{selectedQuiz.Title}</h2>
                                                            <h2>Add Existing Questions</h2>
                                                        </Breadcrumbs>
                                                        <div>
                                                            <img src="../assets/close-black.png" alt="lms-logo" class="close-icon" onClick={handleClose}/>
                                                        </div>
                                                    </div>
                                                    <div className='mui-hr-line'></div>
                                                    <div className=' quiz-creator-con gap-2'>
                                                        <input className='w-50' type="text" placeholder="Search" onChange={(e) => setSearchAssignQuestion(e.target.value)} />
                                                        <FormControl id="points" className='edit-quiz-select-op w-50'>
                                                            <Select
                                                                multiple
                                                                displayEmpty
                                                                value={questionTypeFilter}
                                                                onChange={handleAssignQuestionQuestionTypes}
                                                                input={<OutlinedInput label="" />}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return "All Types"
                                                                    }

                                                                    return selected.join(', ');
                                                                }}
                                                                MenuProps={MenuProps}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                <MenuItem disabled value="">
                                                                    All Types
                                                                </MenuItem>
                                                                <MenuItem value={singleChoice}> {singleChoice}</MenuItem>
                                                                <MenuItem value={multipleChoice}>{multipleChoice}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className=' quiz-creator-con gap-2 align-items-end'>
                                                        <div className='w-100'>
                                                            <label for="points">Difficulty</label>
                                                            <FormControl id="points" className='edit-quiz-select-medium'>
                                                                <Select
                                                                    multiple
                                                                    displayEmpty
                                                                    value={questionLevelFilter}
                                                                    onChange={handleAssignQuestionQuestionLevels}
                                                                    input={<OutlinedInput label="" />}
                                                                    renderValue={(selected) => {
                                                                        if (selected.length === 0) {
                                                                            return "Difficulty"
                                                                        }

                                                                        return selected.join(', ');
                                                                    }}
                                                                    MenuProps={MenuProps}
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                >
                                                                    <MenuItem disabled value="">
                                                                        Difficulty
                                                                    </MenuItem>
                                                                    {props.questionLevels && props.questionLevels.map((item, idx) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={item.Id}>{item.Name}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>

                                                            </FormControl>
                                                        </div>
                                                        <div className='filter-btn-group '>
                                                            <button class="filter-clear-all" onClick={handleSearchAssignQuestion}>Search</button>
                                                        </div>
                                                    </div>
                                                    {
                                                        !modelLoader ?
                                                            <ul className='quiz-creator-con d-block'>
                                                                {
                                                                    assignQuestionList.Questions.map((questions, idx) => {
                                                                        return (
                                                                            <li className='edit-quiz-list' key={idx}>
                                                                                <p>{questions.Question} </p>
                                                                                
                                                                                {
                                                                                    selectedAssignQuestionList.filter(a => a.QuestionId == questions.QuestionId).length > 0 ?
                                                                                        <div class="share-ic d-flex align-items-center"><div class="horizontal-divider mx-4"></div><img src="assets/unshare-icon.png" class="remove-icon" onClick={() => handleRemoveQuestionToAssignedList(questions)} /></div> :
                                                                                        <div class="share-ic d-flex align-items-center"><div class="horizontal-divider mx-4"></div><img src="assets/share-icon.png" class="remove-icon" onClick={() => handleAddQuestionToAssignedList(questions)} /></div>
                                                                                }
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                                {/* <li className='edit-quiz-list'>
                                                            <p>Top 5 questions for the search will be appear here, you can load more questions if you didn’t find and begin scroll for next 5 and load again ? </p>
                                                            <div class="horizontal-divider"></div>
                                                            <div class="share-ic"><img src="assets/unshare-icon.png" class="remove-icon" /></div>
                                                        </li>
                                                        <li className='edit-quiz-list'>
                                                            <p>Top 5 questions for the search will be appear here, you can load more questions if you didn’t find and begin scroll for next 5 and load again ? </p>
                                                            <div class="horizontal-divider"></div>
                                                            <div class="share-ic"><img src="assets/unshare-icon.png" class="remove-icon" /></div>
                                                        </li> */}
                                                            </ul>
                                                            : <Spinner />
                                                    }
                                                    {
                                                        assignQuestionList.Count > assignQuestionList.Questions.length &&
                                                        <div className='quiz-creator-con justify-content-center'>
                                                            <span className='load-more-edit-quiz' onClick={handleAssignQuestionLoadMore}>Load More</span>
                                                        </div>
                                                    }
                                                    <div className='fixed-btns model-padding'>
                                                        <div className='filter-btn-group  mb-3'>
                                                            <button class="filter-clear-search disabled-submit-btn" onClick={handleAssignQuestion} disabled={!isSubmitEnabled}>Submit </button>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </div>
                                        </Modal>

                                        {/*Edit Quiz modal*/}
                                        <Modal
                                            open={isEditQuizOpen}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <div className='popup-breadcrumd'>
                                                <Box sx={QuizPopupStyle}>
                                                    {
                                                        !modelLoader ?
                                                            <>
                                                                <div className=' quiz-creator-con'>
                                                                    <Breadcrumbs aria-label="breadcrumb"
                                                                        separator={<NavigateNextIcon fontSize="medium" />}>
                                                                        <h2 className='word-break' style={{ opacity: "0.4" }}>{selectedQuiz.Title}</h2>
                                                                        <h2 className=''>Edit Quiz</h2>
                                                                    </Breadcrumbs>
                                                                    <div>
                                                                        <img src="../assets/close-black.png" alt="lms-logo" class="close-icon" onClick={handleClose}/>
                                                                    </div>
                                                                </div>
                                                                <div className="quiz-text-input">
                                                                    <input type="text" className={quizTitleError && `error`} placeholder="Type your quiz title here" value={quizTitle}
                                                                        onChange={(e) => {
                                                                            setQuizTitle(e.target.value)
                                                                            if(e.target.value.trim().length > 50){
                                                                                setQuizTitleError(true)
                                                                            }else{
                                                                                setQuizTitleError(false)
                                                                            }
                                                                        }}
                                                                    />
                                                                    {quizTitleError && <label className="question-title error-label"><p>Quiz title should between 1-50 characters</p></label>}
                                                                </div>

                                                                <div className="quiz-selection-con d-flex align-items-center justify-content-between">
                                                                    <div className="quiz-select">
                                                                        <label for="retake">Retake</label>
                                                                        <FormControl id="points">
                                                                            <TextField
                                                                                id="outlined-number"
                                                                                label=""
                                                                                type="number"
                                                                                className="mui_select_con"
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    if (/^\d+$/.test(value) || value === '') {
                                                                                        setRetake(value);
                                                                                    }
                                                                                }}
                                                                                onKeyPress={handleKeyPress}
                                                                                value={retake}
                                                                            />
                                                                            {retakeError && <label className="question-title error-label"><p>{retakeError}</p></label>}
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    {quizPointError && <label className="question-title error-label"><p>Passing Percentage should be between 1-100 %</p></label>}
                                                                </div>
                                                                {
                                                                    props.questionLevels && props.questionLevels.map((item, idx) => {
                                                                        return (
                                                                            <div className="quiz-selection-con d-flex align-items-center justify-content-between" key={idx}>
                                                                                <div className="quiz-select select-four-layout">
                                                                                    <label for="difficulty-level" className="select-four-label">Difficulty level</label>
                                                                                    <h3 id="difficulty-level">{item.Name}</h3>
                                                                                </div>
                                                                                <div className="quiz-select select-four-layout">
                                                                                    <label for="points">No. of Questions</label>
                                                                                    <FormControl id="points">
                                                                                        <TextField
                                                                                            id="outlined-number"
                                                                                            label=""
                                                                                            type="number"
                                                                                            className="mui_select_con"
                                                                                            name={item.Id}
                                                                                            onChange={handleNumberOfQuestion}
                                                                                            onWheel={e => e.target.blur()}
                                                                                            onKeyPress={handleKeyPress}
                                                                                            placeholder={isNaN(questionNumber[item.Id]) ? "0" : questionNumber[item.Id]} 
                                                                                            value={questionNumber[item.Id] || ""}
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div className="quiz-select select-four-layout">
                                                                                    <label for="points">Passing %</label>
                                                                                    <FormControl id="points">
                                                                                        <TextField
                                                                                            id="outlined-number"
                                                                                            label=""
                                                                                            type="number"
                                                                                            className="mui_select_con"
                                                                                            name={item.Id}
                                                                                            // defaultValue={item.PassingPercentage}
                                                                                            disabled={questionNumber[item.Id] === 0 || isNaN(questionNumber[item.Id])}
                                                                                            onChange={handlePercentage}
                                                                                            onWheel={e => e.target.blur()}
                                                                                            onKeyPress={handleKeyPress}
                                                                                            placeholder={isNaN(levelPercentage[item.Id]) ? "0" : levelPercentage[item.Id]} 
                                                                                            value={levelPercentage[item.Id] || ""} />
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div className="quiz-select select-four-layout">
                                                                                    <label for="retake">Points for 1 question</label>
                                                                                    <FormControl id="points">
                                                                                        <TextField
                                                                                            id="outlined-number"
                                                                                            label=""
                                                                                            type="number"
                                                                                            className="mui_select_con"
                                                                                            name={item.Id}
                                                                                            disabled={questionNumber[item.Id] === 0 || isNaN(questionNumber[item.Id])}
                                                                                            onChange={handleQuestionPoints}
                                                                                            onWheel={e => e.target.blur()}
                                                                                            onKeyPress={handleKeyPress}
                                                                                            placeholder={isNaN(questionPoint[item.Id]) ? "0" : questionPoint[item.Id]} 
                                                                                            value={questionPoint[item.Id] || ""} />
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                <div className="quiz-selection-con m-0">
                                                                    {quizLevelEmptyError && <label className="question-title error-label"><p>Please add questions per levels</p></label>}
                                                                    {quizLevelConfigError && <label className="question-title error-label"><p>Please complete the difficulty level values</p></label>}
                                                                    {levelPercentError && <label className="question-title error-label"><p>Passing Percentge should be between 1 - 100 %</p></label>}
                                                                </div>

                                                                <div className="quiz-selection-con d-flex align-items-center justify-content-between grey-box-input">
                                                                    <div className="quiz-select">
                                                                        <label for="points">Total Questions</label>
                                                                        <input type="text" disabled value={totalQuestions} />
                                                                    </div>
                                                                    <div className="quiz-select">
                                                                        <label for="retake">Maximum Points</label>
                                                                        <input type="text" disabled value={totalScores} />
                                                                    </div>
                                                                </div>
                                                                <div className="grey-box-input quiz-selection-con d-flex align-items-center justify-content-between">
                                                                    <div className="quiz-select">
                                                                        <label for="retake">Passing %</label>
                                                                        <input type="text" disabled value={passingPercentage} />
                                                                    </div>
                                                                </div>
                                                                <div className="quiz-selection-con m-0">
                                                                    {quizTotalQuestionError && <label className="question-title error-label"><p>Question number cannot be less than 0</p></label>}
                                                                </div>
                                                                <div className="add-question-btn fixed-btns px-4">
                                                                    <button className="primary-button sec-btn" onClick={handleEditQuiz}>{"Edit Quiz"}</button>
                                                                </div>
                                                            </> : <Spinner />
                                                    }
                                                </Box>
                                            </div>
                                        </Modal>

                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div> : <Spinner />)

                :
                <TableContainer>
                    {selectedCollection.length > 0 ?
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead >
                                <TableRow>
                                    {
                                        props.columns.map((i, index) => { return <TableCell align={i.align} className='table_cell_colour table_cell_design' style={{ width: i.width }} key={index} >{i.headerName}</TableCell> }
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedCollection.map((i) => (
                                    <TableRow
                                        key={i.Id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        className='quiz-table-row'
                                    >
                                        <TableCell className='table-text-align table_cell_design table_cell_colour' >
                                            <div
                                                className=" d-flex align-items-center"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <span onClick={(event) => openQuizQuestionList(event, i)}>{i.Title}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='table_cell_design table_cell_colour'>
                                            <div
                                                className="align-items-center"
                                                style={{ cursor: "pointer" }}
                                            >
                                                {i.TotalQuestions}
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='table_cell_design table_cell_colour'>
                                            <div
                                                className="align-items-center"
                                                style={{ cursor: "pointer" }}
                                            >
                                                {i.TotalMarks}
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='table_cell_design table_cell_colour'>
                                            <div
                                                className="align-items-center"
                                                style={{ cursor: "pointer" }}
                                            >
                                                {i.PassScore}
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='table_cell_design table_cell_colour'>
                                            <div
                                                className="align-items-center"
                                                style={{ cursor: "pointer" }}
                                            >
                                                {i.DifficultyRatio}
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='table_cell_design table_cell_colour'>{moment(i.CreatedOn).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell align='center' className='table_cell_design table_cell_colour'>
                                            <div
                                                className="align-items-center"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <svg id="del-icon" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => props.delete(i, true)}>
                                                    <rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" className='del-stroke' stroke-width="0.9" />
                                                    <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white" />
                                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white" />
                                                    <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" class="del-svg" />
                                                    <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white" />
                                                    <path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white" />
                                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white" />
                                                </svg>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> :
                        <div id='no-result'>
                            <h1>{t("Quiz_Not_Found")}</h1>
                            <p>{t("Error_Body")}</p>
                        </div>
                    }
                </TableContainer>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    pageNumber: state.quiz.QuizPagenumber,
    questionLevels: state.quiz.QuestionLevels,
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchQuizAction: fetchQuiz,
        fetchQuestionAction: fetchQuestions
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizTableLayout);

