
import React from "react";
import {
  useLocation
} from "react-router-dom";
import UserDropDown from "../../modules/User/UserDropDown";
import { Link, NavLink } from "react-router-dom";
import Notifications from "./Notifications";
import PageTitle from "./PageTitle";
import MultiLanguages from "./MultiLngs";
// import GlobalSearch from "./GlobalSearch;"
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import LogoutMenulabel from "../TranslatedComponents/LogoutMenulabel";
import { useMsal } from "@azure/msal-react";
import { useFusionAuth } from "@fusionauth/react-sdk";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { signOutUser } from "../../store/actions/userActions";

const titleMap = [
  { path: '', title: 'Home_Label' },
  { path: 'browse', title: 'Home' },
  { path: 'browsesharedbyyou', title: 'Home' },
  { path: 'browsesharedwithyou', title: 'Home' },
  { path: 'videos', title: 'Videos_Header_Label' },
  { path: 'profile', title: 'Profile_Header_Label' },
  { path: 'admindashboard', title: 'AdminDashboard_Header_Label' },
  { path: 'courses', title: 'Courses_Header_Label' },
  { path: 'edituserprofile', title: 'EditUserProfile_Header_Label' },
  { path: 'assessment', title: 'Quiz & Questions' },
  { path: 'certificates', title: 'Certificates' },
  { path: 'myAnalytics', title: 'Analytics' },
  { path: 'studio', title: 'Studio' },
  { path: 'settings', title: 'Settings' },
  { path: 'analytics', title: 'Analytics' },
  { path: 'collection', title: 'Videos & Courses' },
  { path: 'userManagement', title: 'Users & Groups' },
]
function Header(props) {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  let curLoc = useLocation();
  let { instance } = useMsal();
  const loc = curLoc.pathname.split("/");
  const curTitle = titleMap.find(item => String(item.path).startsWith(loc[1]));
  console.log(curTitle)
  const isLeftToRight = document.body.dir === "ltr";
  const { FirstName, LastName,Name, ProfileImageUrl, Role, BadgesVM } = props.props.user;
  const [dashboradIsActive, setDashboradIsActive] = React.useState(false)
  const [scorePoint, setScorePoint] = React.useState(0);
  const [badgeTitle, setBadgeTitle] = React.useState(0);
  const [browseIsActive, setBrowseIsActive] = React.useState(false)
  const [adminDashboardIsActive, setAdminDashboardIsActive] = React.useState(false)
  const { logout } = useFusionAuth();
  console.log(BadgesVM)
  const handleLogout = () => {
    logout().then(e =>
      this.props.signOutAction()
    )
      .catch(e => {
        console.error(e);
      });
  }

  const location = useLocation()
  React.useEffect(() => {
    location.pathname === '/' && handleDashboardActive()
    location.pathname === '/videos' && handleDashboardActive()
    location.pathname === '/courses' && handleDashboardActive()
    location.pathname === '/profile' && handleDashboardActive()
    location.pathname === '/browse' && handleBrowseActive()
    location.pathname === '/browsesharedwithyou' && handleBrowseActive()
    location.pathname === '/browsesharedbyyou' && handleBrowseActive()
    location.pathname.includes('/admindashboard') && handleAdminDashboardActive()
  })

  React.useEffect(() => {
    if (BadgesVM && Object.keys(BadgesVM).length > 0) {
      if (BadgesVM.ScorePoints && Object.keys(BadgesVM.ScorePoints).length > 0) {
        setScorePoint(BadgesVM.ScorePoints.ScoredPoints);
        setBadgeTitle(BadgesVM.ScorePoints.BadgesMaster.Title)
      }
    }
  }, [BadgesVM]); 

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }
  const handleMenuClose = () => {
    setMenuOpen(false)
  }
  const handleDashboardActive = () => {
    setDashboradIsActive(true)
    setBrowseIsActive(false)
    setAdminDashboardIsActive(false)
  }
  const handleBrowseActive = () => {
    setDashboradIsActive(false)
    setBrowseIsActive(true)
    setAdminDashboardIsActive(false)
  }
  const handleAdminDashboardActive = () => {
    setDashboradIsActive(false)
    setBrowseIsActive(false)
    setAdminDashboardIsActive(true)
  }
  axios.defaults.headers.common['Authorization'] = `bearer ${Cookies.get('token')}`

  React.useEffect(() => {
    window.usetifulTags = {      
      userId: props.props.user.id,
      firstName: props.props.user.FirstName,
      lastName: props.props.user.LastName
    };

    if (document.getElementById("usetifulScript")) return;

    
    const script = document.createElement("script");
    script.src = "https://www.usetiful.com/dist/usetiful.js";
    script.async = true;
    script.id = "usetifulScript";
    script.dataset.token = "ffff04b5369ef21b4cd53b18110f93a5";

    document.head.appendChild(script);

    return () => {
      document.getElementById("usetifulScript")?.remove();
    };
  }, [])

  return (
    <header id="header" className="lms-flex-between lms-flex">
      <div className="lms-menu-logo" onClick={handleMenuOpen}>
        <img src="assets/menu.png" alt="lms-logo" />
      </div>
      <div className={`menu-res ${isMenuOpen ? `${isLeftToRight ? "tran-left menu-right" : "tran-right menu-left"}` : ""}`}>
        <div className="menu-cont-1">
          <div>
            <div className="Res-menu-profile">
              <div className="menu-res-image">
                <img src={ProfileImageUrl} alt="lms-logo" />
              </div>
              <div className="res-menu-heading">
                <NavLink to={"/profile/userinfo"} onClick={() => { setMenuOpen(false) }}>{FirstName}{" "}{LastName}</NavLink>
                <p>{Role}</p>
              </div>
            </div>
            <ul className="menu-res-ul">
              <li className={`menu-res-img ${browseIsActive ? "active" : ""}`}>
                <NavLink to={"/" + "/browse"} activeClassName="is-active" onClick={() => { setMenuOpen(false) }} exact={true} className="d-flex align-items-center" >
                  <div className="menu-res-svg">
                    <svg activeClassName="active" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29.1911 13.0485L29.1891 13.0465L16.9515 0.809355C16.4298 0.287402 15.7363 0 14.9987 0C14.261 0 13.5675 0.287227 13.0456 0.809121L0.814353 13.04C0.810251 13.0441 0.80615 13.0485 0.802048 13.0526C-0.269163 14.1299 -0.267288 15.8779 0.807321 16.9525C1.29822 17.4437 1.94668 17.7282 2.63996 17.7579C2.66808 17.7607 2.6965 17.7621 2.72509 17.7621H3.21283V26.7679C3.21283 28.55 4.66279 30 6.44533 30H11.233C11.7183 30 12.1119 29.6065 12.1119 29.1211V22.0605C12.1119 21.2473 12.7735 20.5859 13.5867 20.5859H16.4106C17.2239 20.5859 17.8853 21.2474 17.8853 22.0605V29.1211C17.8853 29.6065 18.2787 30 18.7642 30H23.5519C25.3345 30 26.7844 28.55 26.7844 26.768V17.7621H27.2367C27.9742 17.7621 28.6677 17.4749 29.1898 16.953C30.2656 15.8766 30.266 14.1257 29.1911 13.0485Z" className="svg-for-menu" />
                    </svg>
                  </div>
                  <div>
                    <h1>Home</h1>
                  </div>
                </NavLink>
              </li>
              <li className={`menu-res-img ${dashboradIsActive ? "active" : ""}`}>
                <NavLink to={"/" + "/videos"} onClick={() => { setMenuOpen(false) }} activeClassName="is-active" exact={true} className={`d-flex align-items-center ${browseIsActive ? "active" : ""}`} >
                  <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1667 22.9893V24.6666C20.1667 24.9318 20.0614 25.1862 19.8739 25.3737C19.6863 25.5613 19.432 25.6666 19.1667 25.6666C18.9015 25.6666 18.6472 25.5613 18.4596 25.3737C18.2721 25.1862 18.1667 24.9318 18.1667 24.6666V23H19.8334C19.9454 23 20.0561 22.996 20.1667 22.9893ZM18.1667 23H9.16675C7.84067 23 6.5689 22.4732 5.63121 21.5355C4.69353 20.5978 4.16675 19.326 4.16675 18V13.8906L11.7854 18.1C12.6198 18.5489 13.5526 18.7839 14.5001 18.7839C15.4476 18.7839 16.3803 18.5489 17.2147 18.1L18.1667 17.5746V23ZM20.1667 16.4693L24.8334 13.8906V18C24.8335 19.2684 24.3515 20.4895 23.4851 21.4159C22.6186 22.3423 21.4324 22.9047 20.1667 22.9893V16.4693Z" className="svg-for-menu" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1667 16.0507L16.5694 16.9333C15.9335 17.276 15.2224 17.4553 14.5001 17.4553C13.7777 17.4553 13.0667 17.276 12.4307 16.9333L1.34541 10.808C0.590748 10.3907 0.166748 9.66001 0.166748 8.89601C0.166748 8.13067 0.590748 7.40001 1.34541 6.98401L12.4307 0.857341C13.0669 0.515398 13.7779 0.336426 14.5001 0.336426C15.2223 0.336426 15.9333 0.515398 16.5694 0.857341L27.6547 6.98401C28.4094 7.40001 28.8334 8.13067 28.8334 8.89601C28.8334 9.66001 28.4094 10.3907 27.6547 10.808L20.1667 14.9453V14C20.1669 13.8687 20.141 13.7387 20.0906 13.6174C20.0403 13.4961 19.9665 13.386 19.8734 13.2933L15.2067 8.62667C15.1139 8.53387 15.0038 8.46026 14.8825 8.41004C14.7613 8.35981 14.6313 8.33396 14.5001 8.33396C14.3688 8.33396 14.2389 8.35981 14.1176 8.41004C13.9964 8.46026 13.8862 8.53387 13.7934 8.62667C13.7006 8.71948 13.627 8.82965 13.5768 8.9509C13.5266 9.07215 13.5007 9.2021 13.5007 9.33334C13.5007 9.46458 13.5266 9.59454 13.5768 9.71579C13.627 9.83704 13.7006 9.94721 13.7934 10.04L18.1667 14.4147V16.0507Z" className="svg-for-menu" />
                  </svg>
                  <div>
                    <h1>Videos & Courses</h1>
                  </div>


                </NavLink>
              </li>
              <li className={`menu-res-img ${adminDashboardIsActive ? "active" : ""}`}>
                <NavLink to={"/" + "/admindashboard/videocollection"} onClick={() => { setMenuOpen(false) }} activeClassName="is-active" exact={true} className={`d-flex align-items-center ${adminDashboardIsActive ? "active" : ""}`} >
                  <div className="menu-res-svg admin-nav-res-con">
                    <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 35.9625H14.7C14.55 35.9625 0 32.5125 0 16.4625V7.4625C0 6.8625 0.3 6.2625 0.9 6.1125L14.4 0.1125C14.85 -0.0375 15.3 -0.0375 15.6 0.1125L29.1 6.1125C29.7 6.2625 30 6.8625 30 7.4625V16.4625C30 32.5125 15.45 35.9625 15.3 35.9625H15ZM15 19.4625C10.65 19.4625 7.5 21.4125 7.5 23.9625C7.5 26.5125 10.65 28.4625 15 28.4625C19.35 28.4625 22.5 26.5125 22.5 23.9625C22.5 21.4125 19.35 19.4625 15 19.4625ZM15 7.4625C12.45 7.4625 10.5 9.4125 10.5 11.9625C10.5 14.5125 12.45 16.4625 15 16.4625C17.55 16.4625 19.5 14.5125 19.5 11.9625C19.5 9.4125 17.55 7.4625 15 7.4625Z" className="svg-for-menu" />
                    </svg>
                    <svg className="admin-nav-arrow" width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0.981201L10 8.9812L0 16.9812V0.981201Z" className="svg-for-menu" />
                    </svg>
                  </div>
                  <div>
                    <h1>Admin Creation HUB</h1>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="menu-res-img res-logout " onClick={handleLogout}>
            <NavLink to="/" activeClassName="is-active" exact={true} onClick={() => { setMenuOpen(false) }} className="d-flex align-items-center" >
              <div className="menu-res-svg">
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 32 29"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Visual-Design-2"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Dashboard"
                      transform="translate(-51.000000, -986.000000)"
                      className="svg-for-menu"
                      fillRule="nonzero"
                    >
                      <g id="logout" transform="translate(51.000000, 986.000000)">
                        <path
                          d="M16.3265306,0 C17.2244898,0 17.9591837,0.815625 17.9591837,1.8125 C17.9591837,2.809375 17.2244898,3.625 16.3265306,3.625 L16.3265306,3.625 L6.04081633,3.625 C4.53061224,3.625 3.26530612,5.0296875 3.26530612,6.70625 L3.26530612,6.70625 L3.26530612,22.29375 C3.26530612,23.9703125 4.48979592,25.375 6.04081633,25.375 L6.04081633,25.375 L16.3265306,25.375 C17.2244898,25.375 17.9591837,26.190625 17.9591837,27.1875 C17.9591837,28.184375 17.2244898,29 16.3265306,29 L16.3265306,29 L6.04081633,29 C2.73469388,29 0,25.9640625 0,22.2484375 L0,22.2484375 L0,6.70625 C0,3.0359375 2.73469388,0 6.04081633,0 L6.04081633,0 Z M23.1428571,7.24352679 C23.7959184,6.5875744 24.8163265,6.5875744 25.4693878,7.24352679 L25.4693878,7.24352679 L31.5102041,13.3520833 C31.8367347,13.6390625 32,14.0490327 32,14.5 C32,14.9509673 31.8367347,15.3609375 31.5102041,15.6889137 L31.5102041,15.6889137 L25.4693878,21.7564732 C25.1428571,22.0844494 24.7346939,22.2484375 24.3265306,22.2484375 C23.9183673,22.2484375 23.5102041,22.0844494 23.1836735,21.7564732 C22.5306122,21.1005208 22.5306122,20.0755952 23.1836735,19.4196429 L23.1836735,19.4196429 L26.4489796,16.139881 L10.2040816,16.139881 C9.30612245,16.139881 8.57142857,15.4019345 8.57142857,14.5 C8.57142857,13.5980655 9.30612245,12.860119 10.2040816,12.860119 L10.2040816,12.860119 L26.4081633,12.860119 L23.1428571,9.58035714 C22.4897959,8.92440476 22.4897959,7.89947917 23.1428571,7.24352679 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div>
                <h1><LogoutMenulabel /></h1>
              </div>


            </NavLink>
          </div>

        </div>
        <div className="menu-cont-2">
          <div className="menu-close-btn">
            <svg onClick={handleMenuClose} width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22.5" cy="22.5" r="22.5" fill="white" />
              <path d="M24.2174 23L30.7101 16.4783C31.058 16.1304 31.058 15.5797 30.7101 15.2609C30.3623 14.913 29.8116 14.913 29.4928 15.2609L23 21.7826L16.4783 15.2609C16.1304 14.913 15.5797 14.913 15.2609 15.2609C14.913 15.6087 14.913 16.1594 15.2609 16.4783L21.7536 23L15.2609 29.5217C14.913 29.8696 14.913 30.4203 15.2609 30.7391C15.4348 30.913 15.6667 31 15.8696 31C16.0725 31 16.3043 30.913 16.4783 30.7391L23 24.2174L29.5217 30.7391C29.6957 30.913 29.9275 31 30.1304 31C30.3333 31 30.5652 30.913 30.7391 30.7391C31.087 30.3913 31.087 29.8406 30.7391 29.5217L24.2174 23Z" fill="black" fill-opacity="0.64" />
            </svg>
          </div>
        </div>
      </div>
      <div className="lms-res-dashboard">
        <NavLink to="/" onClick={() => { setMenuOpen(false) }}>
          <img src="../assets/inovar-logo89x29.png" alt="lms-logo" className="reishauer" />
        </NavLink>
        <PageTitle props={t(curTitle.title)} />
      </div>
      {/* <GlobalSearch/> */}
      <div className="lms-res-icon">
        <div className="right-content lms-flex-between">
          {/* <Notifications /> */}
          <MultiLanguages />
          <UserDropDown name={Name} profileImg={ProfileImageUrl} badgeTitle = {badgeTitle} scorePoint={scorePoint} />
        </div>
      </div>
    </header>
  );
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signOutAction: signOutUser
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Header);
