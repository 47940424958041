import React, { useRef } from 'react';
import Datagriddata from './Datagriddata';
import SearchFilter from './SearchFilter';
import API from '../utils/API';

export default function VideoDatagriddata(props) {
    const [selections, setSelections] = React.useState([]);
    const [selectedVideos, setSelectedVideos] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCourseToAdd, setSearchCourseToAdd] = React.useState([]);
    const [searchCourseToAddValue, setSearchCourseToAddValue] = React.useState("");
    const [searchCourseToAddLocalLoader, setSearchCourseToAddLocalLoader] = React.useState(false);
    const deleteFunctionRef = useRef(null);
    const editFunctionRef = useRef(null);
    const linkQuizFunctionRef = useRef(null);

    const [filterData, setFilterData] = React.useState({
        Count: 0,
        Data: [],
        StatusCode: 200,
    });

    React.useEffect(() => {
        setSelectedVideos([]);
        setSelections([]);
        setFilterData({
            Count: 0,
            Data: [],
            StatusCode: 200,
        });
    }, [window.location.pathname.split('/').pop()]);

    const handleSelection = (event) => {
        setSelections(event);
    };

    const handleFilterCollection = (event) => {
        setFilterData({
            Count: event.Count,
            Data: event.Data,
            StatusCode: event.StatusCode
        });
    };

    const handleSelectedVideos = (event) => {
        setSelectedVideos(event);
    };

    const hanldeSearchValue = (event) => {
        setSearchValue(event);
    };

    const handleSearchCourseToAdd = (event) => {
        setSearchCourseToAddLocalLoader(true);
        setSearchCourseToAddValue(event);
        const timer = setTimeout(() => {
            API.fetchAdminCourseFilter({
                filterField: "Title",
                filterText: event.trim()
            }).then(res => res.status === 200 && setSearchCourseToAdd(res.data.Playlist)).finally(() => setSearchCourseToAddLocalLoader(false));
            setSearchValue(event);
        }, 500);
        return () => clearTimeout(timer);
    };

    const handleDelete = () => {
        if (deleteFunctionRef.current) {
            deleteFunctionRef.current(selections, true);
            setSelections([]);
            setSelectedVideos([]);
        }
    };

    const registerEditFunction = (editFunction) => {
        editFunctionRef.current = editFunction;
    };

    const registerLinkQuizFunction = (handleLinkQuiz) => {
        linkQuizFunctionRef.current = handleLinkQuiz;
    };

    const handleLinkQuiz = () => {
        if (linkQuizFunctionRef.current) {
            linkQuizFunctionRef.current(selections[0].Id);
        }
    }
    
    const handleEdit = () => {
        if (editFunctionRef.current) {
            editFunctionRef.current(selections[0].Id, selections[0].CourseType);
        }
    };

    return (
        <>
            <SearchFilter
                searchCourseToAddValue={searchCourseToAddValue}
                handleDelete={handleDelete}
                handleEdit = {handleEdit}
                handleLinkQuiz={handleLinkQuiz}
                searchCourseToAddData={searchCourseToAdd}
                searchCourseToAddLocalLoader={searchCourseToAddLocalLoader}
                selection={selections}
                filterData={handleFilterCollection}
                searchValue={hanldeSearchValue}
                searchCourseToAdd={handleSearchCourseToAdd}
                resetSelection={() => setSelections([])}
            />
            <Datagriddata
                selection={handleSelection}
                selectedVideos={handleSelectedVideos}
                registerDeleteClickHandler={(clickFunction) => { deleteFunctionRef.current = clickFunction; }}
                registerEditClickHandler={registerEditFunction}
                registerLinkQuizClickHandler = {registerLinkQuizFunction}
                filterData={filterData}
                searchValue={searchValue}
            />
        </>
    );
}
