import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import ProgressBar from "../Common/ProgressBar";
import { Avatar, Stack } from "@mui/material";
import GenerateProfilePic from "../Common/GenerateProfilePictureIntitals";
import ErrorTab from "../Common/ErrorTab";
import VideoExpireTime from "../Common/VideoExpireTime";
import VideoUploadedDate from "../Common/VideoUploadedDate";
import { connect } from "react-redux";

export const SavedTutorialList = (props) => {

  const [borderColors, setBorderColors] = React.useState(
    Array(props.props.length).fill('#3ADDC8')
  );

  if (props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((tutorial, idx) => {
          const detailId = tutorial.VideoId;
          const borderStyle = tutorial.WatchExpirationDateTime ? { border: `2px solid ${borderColors[idx]}` } : {};
          
          const handleBorderColorChange = (color) => {
            setBorderColors((prev) => {
              const newColors = [...prev];
              newColors[idx] = color;
              return newColors;
            });
          };

          return (
            <li className="card-space" key={idx} style={borderStyle}>
              <Link to={"/videos/" + detailId} id={"listcard" + idx}>
                <div className="card-img">
                  <img
                    src={tutorial.ThumbnailUrl}
                    alt="lms-logo" />
                </div>
                <ProgressBar value={tutorial.Percentage}></ProgressBar>
                <div className="card-info lms-flex-between">
                  <p>{tutorial.CategoryName}</p>
                  <div className="card-date">
                    {!tutorial.WatchExpirationDateTime || tutorial.WatchExpirationDateTime === null?
                      <VideoUploadedDate createdDate={tutorial.CreatedDate} />
                      :
                      <VideoExpireTime
                        expirationTime={tutorial.WatchExpirationDateTime}
                        onBorderColorChange={handleBorderColorChange}
                      />}
                  </div>
                </div>
                <div className="card-heading">{tutorial.Title}</div>
                <div className="card-para">{tutorial.Description}</div>
                {props.type !== 'save' && <div style={{ marginTop: 10 }}>
                  <Stack direction="row" className= "d-flex align-items-center gap-1">
                    {
                      props.type === 'sharebyyou' ?
                        <Avatar
                          alt="Remy Sharp"
                          src={tutorial.SharedWithProfilePictureUrl === '' || tutorial.SharedWithProfilePictureUrl === null ? GenerateProfilePic(tutorial.SharedWithName) : process.env.REACT_APP_AZURE_BLOB_BASE_URL + '/' + props.tenant.tenant.organisationUniqueName + '/cdn' + tutorial.SharedWithProfilePictureUrl}
                          sx={{ width: 25, height: 25 }}
                        />
                        :
                        <Avatar
                          alt="Remy Sharp"
                          src={tutorial.SharedByProfilePictureUrl === '' || tutorial.SharedByProfilePictureUrl === null ? GenerateProfilePic(tutorial.SharedByName) : process.env.REACT_APP_AZURE_BLOB_BASE_URL + '/' + props.tenant.tenant.organisationUniqueName + '/cdn' + tutorial.SharedByProfilePictureUrl}
                          sx={{ width: 25, height: 25 }}
                        />
                    }

                    <div className="identify">{props.type === 'sharebyyou' ? tutorial.SharedWithName : tutorial.SharedByName}</div>
                  </Stack>
                </div>}
              </Link>
            </li>
          );
        })}
      </React.Fragment>
    );
  } else {
    return (
      <div id="no-result-saved-item">
        <h1>No Videos are available</h1>
        <p>Please search with alternative keyword</p>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  tenant: state.tenant
});

export default connect(mapStateToProps, null)(SavedTutorialList);
