import GenerateProfilePic from "../../components/Common/GenerateProfilePictureIntitals";
import actions from "../actions/actionTypes";

let initialState = {
  EasyQuestions: [],
  MediumQuestions: [],
  ComplexQuestions: [],
  EasyCount: 0,
  MediumCount: 0,
  ComplexCount: 0,
  TotalCount: 0,
  QuestionPageNumber: 2,
  loaded: false,
  questionLazyLoader: true
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_QUESTION_SUCCEEDED: {
      console.log(action);
      console.log(state);
      return {
        ...state,
        EasyQuestions: action.questions === "" ? [] : action.questions.EasyQuestions,
        MediumQuestions: action.questions === "" ? [] : action.questions.MediumQuestions,
        ComplexQuestions: action.questions === "" ? [] : action.questions.ComplexQuestions,
        EasyCount: action.questions === "" ? [] : action.questions.EasyCount,
        MediumCount: action.questions === "" ? [] : action.questions.MediumCount,
        ComplexCount: action.questions === "" ? [] : action.questions.ComplexCount,
        TotalCount: action.questions === "" ? [] : action.questions.TotalCount,
        loaded: true,
      };
    }
    case actions.FETCH_QUESTION_LOADMORE_SUCCEEDED:
      return {
        ...state,
        ComplexQuestions: [ ...state.ComplexQuestions, ...action.questions.ComplexQuestions ],
        EasyQuestions: [ ...state.EasyQuestions, ...action.questions.EasyQuestions ],
        MediumQuestions: [...state.MediumQuestions, ...action.questions.MediumQuestions],
        EasyCount: action.questions.EasyCount,
        MediumCount: action.questions.MediumCount,
        ComplexCount: action.questions.ComplexCount,
        TotalCount: action.questions.TotalCount,

        questionLazyLoader: true,
        loaded: true,
        QuestionPageNumber: state.QuestionPageNumber + 1,
      };

    case actions.FETCH_QUESTION_LOADMORE_FINISHED:
      return {
        ...state,
        questionLazyLoader: false,
      };
    default:
      return state;
  }
}

export default questionReducer;