import * as React from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
// import EditIcon from "@material-ui/icons/Edit";
//import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import { fetchAdminCourse, fetchAdminCourseLoadmore, fetchAdminTutorialsLoadmore, fetchAdminVideo } from "../store/actions/admin";
import API from "../utils/API";
import { Alert, Backdrop, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Modal, SwipeableDrawer } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TableLayout from "../Common/CollectionTableLayout";
import { useLocation } from "react-router";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import ErrorTab from "../../../components/Common/ErrorTab";
import Spinner from "../../../components/Common/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { courseCollectionPath, videoCollectionPath } from "../Common/Constants";
import NoCourseAndVideos from "../Common/NoCourseAndVideos";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function Datagriddata({ registerEditClickHandler,registerLinkQuizClickHandler, registerDeleteClickHandler, VideoCollectionData, VideoCollectionCount, CourseCollectionCount, searchValue, CourseCollectionData, fetchAdminVideoAction, fetchAdminCourseAction, selection, collectionType, search, filterData, videoLazyloader, courseLazyloader, videoCollectionPageNumber, loadMoreAdminTutorialAction, loadMoreAdminCourseAction, courseCollectionPageNumber, categories, playlistCategories, getIndex }) {
  const [alert, setAlert] = React.useState({
    active: false,
    data: '',
  });
  const [openEdit, setOpenEdit] = React.useState(false);
  const [updateData, setUpdateData] = React.useState({
    id: '',
    fileId: '',
    name: '',
    desc: '',
    category: ''
  });
  const [anchor, setAnchor] = React.useState({ right: false });
  const [data, setData] = React.useState([])
  const [modalState, setModalState] = React.useState(false)

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({ items: [] });
  const [scrollAtBottom, setScrollAtBottom] = React.useState(false);
  const [hasMoreVideos, setHasMoreVideos] = React.useState(true);
  const [hasMoreCourse, setHasMoreCourse] = React.useState(true);
  const [isInDetailPage, setIsInDetailPage] = React.useState(false);
  const [IsLoaderActive,setLoaderActive] = React.useState()


  const location = useLocation();

  const { t } = useTranslation();

  const Course_Updated = t("Course_Updated")
  const Video_Updated = t("Video_Updated")
  const Course_Deleted = t("Course_Deleted")
  const Video_Deleted = t("Video_Deleted")
  const Are_You_Sure = t("Are_You_Sure")
  const Cancel = t("Cancel")
  const Delete = t("Delete")
  const You_want_to_delete = t("You_want_to_delete")
  const ques_mark = t("?")

  const handleClose = () => {
    setDialogOpen(false);
    setDeleteData({ items: [] })
  }

  const handleBackDropClose = () => {
    setAlert({
      active: false,
      data: ''
    })
  }

  const loadMore = () => {
    if (videoLazyloader === false) {
      setHasMoreVideos(false)
    }
    if (courseLazyloader === false) {
      setHasMoreCourse(false)
    }

    window.location.pathname.split('/').pop() === videoCollectionPath &&
      loadMoreAdminTutorialAction({
        pageNumber: videoCollectionPageNumber
      });


    window.location.pathname.split('/').pop() === courseCollectionPath &&
      loadMoreAdminCourseAction(courseCollectionPageNumber)
  }

  const onScroll = async (event) => {
    event.preventDefault()
    const element = event.target;
    if (parseInt(element.scrollHeight - element.scrollTop) <= element.clientHeight && !modalState) {
      setScrollAtBottom(true)
      window.location.pathname.split('/').pop() === 'videocollection' &&
        loadMoreAdminTutorialAction({
          pageNumber: videoCollectionPageNumber
        })

      window.location.pathname.split('/').pop() === 'coursecollection' &&
        loadMoreAdminCourseAction(courseCollectionPageNumber)
    }
  }

  const handleModalState = (e) => {
    setModalState(e)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setAnchor({ ...anchor, [anchor]: open });
  };

  const handleOpenEdit = (data) => {
    setUpdateData({
      id: data.Id,
      fileId: data.ThumbnailUrl.split('/').pop().split('.')[0],
      name: data.Name,
      desc: data.Description,
      category: data.CategoryName
    })
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setUpdateData({
      id: '',
      fileId: '',
      name: '',
      desc: '',
      category: ''
    })
  };

  const handleUpdate = () => {
    collectionType === 'video' || collectionType === '' ?
      API.updateTutorialData({
        Id: updateData.id,
        Name: updateData.name.trim(),
        Title: updateData.name.trim(),
        FileId: updateData.fileId.trim(),
        Description: updateData.desc.trim(),
        CategoryId: parseInt(updateData.category),
        FileName: updateData.name.trim(),
      }).then(res => {
        if (res.status === 201) {
          handleCloseEdit()
          setAlert({
            active: true,
            data: Video_Updated
          })
          fetchAdminVideoAction({
            pageNumber: 1
          });
        }
      }
      )
      :
      API.updateCourseData({
        Id: updateData.id,
        Title: updateData.name.trim(),
        Description: updateData.desc.trim(),
        CategoryId: parseInt(updateData.category),
      }).then(res => {
        if (res.status === 201) {
          handleCloseEdit()
          setAlert({
            active: true,
            data: Course_Updated
          })
          fetchAdminCourseAction(1);
        }
      }
      )
  }

  const handleSelection = (event) => {
    selection(event)
  }

  const handleDeleteDialog = (data, isTrue) => {
    if (Array.isArray(data) && data.length > 0) {
      const uniqueIds = new Set();
      
      const models = data.filter(item => {
        if (!uniqueIds.has(item.Id)) {
          uniqueIds.add(item.Id);
          return true;
        }
        return false;
      }).map(item => ({
        content: `${You_want_to_delete} ${item.Title} ${location.pathname.split('/').pop() === 'videocollection' ? 'video' : 'course'} ${ques_mark}`,
        Id: item.Id,
        Name:item.Name
      }));
  
      const consolidatedModel = {
        items: models
      };
  
      setDeleteData(consolidatedModel);
      setDialogOpen(isTrue);
    } else {
      console.warn('No data available to delete.');
    }
  };
  

  useEffect(() => {
    if (registerDeleteClickHandler) {
      registerDeleteClickHandler(handleDeleteDialog);
    }
  }, [registerDeleteClickHandler]);


  const handleDelete = () => {
    setLoaderActive(true)
    if (deleteData && Array.isArray(deleteData.items) && deleteData.items.length > 0) {
      const deleteType = location.pathname.split('/').pop() === 'videocollection' ? 'video' : 'course';
      const deleteActions = deleteData.items.map(item => {
        if (deleteType === 'video') {
          return API.deleteVideo(item.Id)
            .then(res => {
              if (res.status === 204) {
                setAlert({ active: true, data: `${item.Name} ${Video_Deleted}` });
              }
              return res;
            })
            .catch(err => {
              console.error(`Error deleting video ${item.Id}:`, err);
            });
        } else {
          return API.deleteCourse(item.Id)
            .then(res => {
              if (res.status === 204) {
                setAlert({ active: true, data: `${item.Name} ${Course_Deleted}` });
              }
              return res;
            })
            .catch(err => {
              console.error(`Error deleting course ${item.Id}:`, err);
            });
        }
      });
  

      Promise.all(deleteActions)
        .then(responses => {
          const allDeleted = responses.every(res => res && res.status === 204);
          if (allDeleted) {
            handleClose();
            if (deleteType === 'video') {
              fetchAdminVideoAction({ pageNumber: 1 });
            } else {
              fetchAdminCourseAction(1);
            }
            setDeleteData({ items: [] });
            setLoaderActive(false)
          }
        })
        .catch(error => {
          console.error('Error during delete operations:', error);
        });
    } else {
      console.warn('No items to delete.');
    }
  };
  

  React.useEffect(() => {
    setTimeout(() => {
      handleBackDropClose();
    }, 4000);
  }, [alert.active]);

  React.useEffect(() => {
    console.log(VideoCollectionData.Videos, location.pathname.split('/').pop() === 'videocollection')
    if (location.pathname.split('/').pop() === 'videocollection') {
      setData(VideoCollectionData.Videos)
    }
    if (location.pathname.split('/').pop() === 'coursecollection') { setData(CourseCollectionData.Playlist) }
  }, [location.pathname, VideoCollectionData, CourseCollectionData])


  const videoColumns = [
    {
      field: "Name",
      headerName: "Name",
      sortable: false,
      width: 500,
      align: 'left',
    },
    {
      field: "CategoryName", headerName: "Category", width: 270, sortable: false, align: 'center'
    },
    {
      field: "CreatedDate", headerName: "Date Uploaded", width: 150, sortable: false, align: 'center'
    },
    { field: "Uploadedby", headerName: "Uploaded by", width: 270, sortable: false },
  ];

  const courseColumns = [
    {
      field: "Name",
      headerName: "Name",
      sortable: false,
      width: 700,
      align: 'left',
      margin: 20
    },
    {
      field: "Course type", headerName: "Course type", width: 200, sortable: false, align: 'center'
    },
    {
      field: "Contents", headerName: "Videos/Sessions", width: 160, sortable: false, align: 'center'
    },
    {
      field: "Quiz", headerName: "Quiz", width: 150, sortable: false, align: 'center'
    },
    {
      field: "CategoryName", headerName: "Category", width: 200, sortable: false, align: 'center'
    },
    {
      field: "CreatedDate", headerName: "Date Uploaded", width: 200, sortable: false, align: 'center'
    },
    { field: "Uploadedby", headerName: "Uploaded by", width: 270, sortable: false },
  ];

  const handleIsInDetailpage = (event) => {
    setIsInDetailPage(event)
  }

  return (
    <div style={{ height: 1000, width: '100%' }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={alert.active}
        onClick={handleBackDropClose}
      >
        <Alert severity="success" style={{ position: "absolute", top: 20 }}>
          {alert.data}
        </Alert>
      </Backdrop>
      <Modal
        hideBackdrop
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 1000 }}>

          <form onSubmit={(e) => {
            e.preventDefault()
            handleUpdate()
          }
          }>

            <div>
              <br />
              <input type="text" id="name" name="name" placeholder="Name" value={updateData.name} onChange={(e) => setUpdateData({ ...updateData, name: e.target.value })} />
              <br />
            </div>
            <div>
              <br />
              <textarea name="desc" id="desc" rows="3" value={updateData.desc} onChange={(e) => setUpdateData({ ...updateData, desc: e.target.value })} />
              <br />
            </div>
            <div>
              <br />
              <select class="form-select" id="category" name="category" aria-label="Example select with button addon" onChange={(e) => setUpdateData({ ...updateData, category: e.target.value })}>
                {
                  collectionType === 'video' ?
                    categories.map((i) => (
                      <option key={i.Id} value={i.Id} selected={updateData.category === i.CategoryName ? true : false}>{i.CategoryName}</option>
                    ))
                    :
                    playlistCategories.map((i) => (
                      <option key={i.Id} value={i.Id}>{i.PlaylistCategoryName}</option>
                    ))
                }
              </select>
              <br />
            </div>
            <br />
            <br />
            <div className="lms-flex edit-buttons ">
              <button
                className="primary-button ter-btn"
                onClick={handleCloseEdit}
              >
                Cancel
              </button>
              <button
                className="primary-button sec-btn ad-edit-btn"
                type="submit"
              >
                Update
              </button>
            </div>

          </form>
        </Box>
      </Modal>
      <Dialog open={dialogOpen} onClose={handleClose} className="delete-confirmation">
       {
        IsLoaderActive ? 
        <Spinner />
        :
        <>
         <DialogTitle>{Are_You_Sure}</DialogTitle>
        <Divider className="confirmation-popup-divider" />
        <DialogContent>
          <ul>
            {deleteData && deleteData.items && deleteData.items.map((i,idx) => (
              <li key={i.Id}>
                <DialogContentText>
                {`${idx+1}. ${i.content}`}
                </DialogContentText>
              </li>
            ))}
          </ul>
        </DialogContent>
        <Divider className="confirmation-popup-divider" />
        <DialogActions>
          <button
            className="primary-button ter-btn ad-share-btn red-button"
            style={{ backgroundColor: '#043677', color: 'white' }}
            // disabled={selectedUser.length > 0 ? false : true}
            onClick={handleDelete}
          >
            {Delete}
          </button>
          <button
            className="primary-button ter-btn"
            onClick={handleClose}
          >
            {Cancel}
          </button>
          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Delete</Button> */}
        </DialogActions>
        </>
       }
      </Dialog>



      {
        window.location.pathname.split('/').pop() === videoCollectionPath &&
        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={filterData.Count > 0 ? filterData.Data : VideoCollectionData.Videos.length}
          next={loadMore}
          hasMore={hasMoreVideos}
          loader={VideoCollectionCount > VideoCollectionData.Videos.length && filterData.StatusCode !== 204 && <Spinner />}
          endMessage
        >
          {
            window.location.pathname.split('/').pop() === videoCollectionPath &&
            (filterData.StatusCode === 200 ?
              <TableLayout 
                registerEditClickHandler= {registerEditClickHandler}
                registerLinkQuizClickHandler= {registerLinkQuizClickHandler} 
                columns={videoColumns} 
                data={filterData.Count > 0 ? filterData.Data : VideoCollectionData.Videos} 
                isInDetailPage={handleIsInDetailpage} 
                delete={handleDeleteDialog} 
                selection={handleSelection} 
                modalOpen={handleModalState} />
              :
              <NoCourseAndVideos />
            )
          }
        </InfiniteScroll>
      }

      {
        window.location.pathname.split('/').pop() === courseCollectionPath &&
        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={filterData.Count > 0 ? filterData.Data : CourseCollectionData.Playlist.length}
          next={loadMore}
          hasMore={hasMoreCourse}
          loader={(CourseCollectionCount > CourseCollectionData.Playlist.length && !isInDetailPage) && filterData.StatusCode !== 204 && <Spinner />}
          endMessage
        >
          {
            window.location.pathname.split('/').pop() === courseCollectionPath &&
            (filterData.StatusCode === 200 ?
              <TableLayout 
                registerEditClickHandler={registerEditClickHandler}
                registerLinkQuizClickHandler= {registerLinkQuizClickHandler}  
                columns={courseColumns} 
                data={filterData.Count > 0 ? filterData.Data : CourseCollectionData.Playlist} 
                isInDetailPage={handleIsInDetailpage} 
                delete={handleDeleteDialog} 
                selection={handleSelection} 
                modalOpen={handleModalState} 
                />
              :
              <NoCourseAndVideos />
            )
          }
        </InfiniteScroll>
      }
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    VideoCollectionData: state.tutorials.CollectionData,
    CourseCollectionData: state.courses.CollectionData,
    VideoCollectionCount: state.tutorials.CollectionData.Count,
    CourseCollectionCount: state.courses.CollectionData.Count,
    videoLazyloader: state.tutorials.lazyloader,
    courseLazyloader: state.courses.lazyloader,
    videoCollectionPageNumber: state.tutorials.collectionPageNumber,
    courseCollectionPageNumber: state.courses.collectionPageNumber,
    categories: state.category.categories,
    playlistCategories: state.category.playlistCategories,
  };
};

const mapDispatchToProps = (dispatch) => {

  return bindActionCreators(
    {
      fetchAdminVideoAction: fetchAdminVideo,
      fetchAdminCourseAction: fetchAdminCourse,
      loadMoreAdminTutorialAction: fetchAdminTutorialsLoadmore,
      loadMoreAdminCourseAction: fetchAdminCourseLoadmore
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Datagriddata);
